<template>
  <div>
    <div v-if="!hideNav" class="d-flex justify-content-end bg-light pb-2">
      <router-link
          :to="'/video/' + $route.params.id"
          v-if="isAnalysis()" type="button" class="btn btn-sm btn-secondary mr-auto">
        Zum Video
      </router-link>
      <router-link
          v-else
          :to="'/video/' + $route.params.id + '/analysis'"
          :class="{'mt-2 ml-2': !isAnalysis()}"
          type="button" class="btn btn-sm btn-secondary mr-auto">
        Zum Dashboard
      </router-link>
      <button type="button" class="btn btn-sm btn-secondary"
              :class="{'mt-2 mr-2': !isAnalysis()}"
              @click="resetTags">
        Zurücksetzen
      </button>
    </div>
    <ul class="list-group">
      <tag-dropdown v-for="option in categories" v-bind:key="option.id"
                    @tagSelected="updateSelectedTags"
                    @openModal="showAddTagModal($event)"
                    :category="option.title"
                    :tags="option.tags"
                    :tag-counts="tagCounts"
                    ref="tagDropdownRef"
      ></tag-dropdown>
    </ul>
    <modal name="addTagModal" height="auto" :clickToClose="false" :adaptive="true">
      <tag-adding-modal
          :video-id="$route.params.id"
          :tag="editTag"
          @tagAdded="addTag($event)"
          @tagUpdated="updateTag($event)"
      />
    </modal>
  </div>
</template>

<script>
import TagDropdown from "@/components/Scenes/TagDropdown";
import TagAddingModal from "@/components/Scenes/TagAddingModal";
import {ref} from "vue";
import router from "@/utils/router";

export default {
  name: "TagFilterContainer",
  components: {TagDropdown, TagAddingModal},
  props: ['categories', 'tagCounts', 'updateTagsInStore', 'hideNav'],
  data() {
    return {
      isOpen: true,
      selectedTags: this.$store.state.selectedTags,
      editTag: null
    }
  },
  setup() {
    const tagDropdownRef = ref()
    return {
      tagDropdownRef
    }
  },
  methods: {
    updateSelectedTags(tag) {
      if (this.updateTagsInStore) {
        const oldTag = this.$store.state.selectedTags.find((item) => item.id === tag.id)
        if (oldTag) {
          this.$store.commit('removeTag', oldTag)
          this.$emit('selectedTagsUpdated', this.selectedTags)
        } else {
          const index = this.$store.state.selectedTags.indexOf(tag);
          if (index > -1) { // only splice array when item is found
            this.$store.commit('removeTag', tag)
          } else {
            this.$store.commit('addTag', tag)
          }
          this.$emit('selectedTagsUpdated', this.selectedTags)
        }
      } else {
        this.$emit('selectedTagsUpdated', tag)
      }
    },
    resetTags() {
      this.$store.commit('resetTags')
      this.$emit('selectedTagsUpdated', [])
      this.selectedTags.length = 0
      this.tagDropdownRef.forEach((dropdown) => {
        dropdown.isOpen = false
      })
    },
    showAddTagModal(tag) {
      this.editTag = tag
      this.$modal.show('addTagModal')
    },
    addTag(tag) {
      const category = this.categories.find((category) => {
        return category.id === tag.category_id
      })
      category.tags.push(tag)
    },
    updateTag(tag) {
      const category = this.categories.find((category) => {
        return category.id === tag.category_id
      })
      let tagIndex = category.tags.findIndex((item) => {
        return item.id === tag.id
      })
      this.$set(category.tags, tagIndex, tag)
    },
    isAnalysis() {
      return router.currentRoute.fullPath.includes('analysis')
    }
  },
}
</script>

<style scoped>

</style>
