<template>
  <div class="px-3 bg-light" :class="hideIfBadminton(category) ? 'hidden' : ''">
    <span @click="toggleCollapse(category)" style="cursor: pointer" class="text-success">
    {{ checkCustom(category) ? 'Individuelle Wiedergabelisten' : category }}
      <span v-if="tags.length">
        <i v-if="isOpen" class="fas fa-caret-down ml-2"></i><i v-else class="fas fa-caret-right ml-2"></i>
      </span>
    </span>
    <span v-if="totalTagCount && category !== 'Custom'" class="float-right">{{ totalTagCount }}</span>
    <span v-if="category === 'Custom'"
          @click="showAddTagModal(null)"
          class="float-right mr-3 text-success"
          style="cursor: pointer"
    >
        <i v-if="isOpen" class="fas fa-plus ml-2"></i>
      </span>
    <span v-if="isOpen">
          <div
              v-for="tag in sortedTags"
              v-bind:key="tag.id"
              class="form-group my-0 ml-4"
          >
              <input :id="'tagCheckbox' + tag.id"
                     class="mr-2"
                     type="checkbox"
                     @click="handleClick(tag)"
                     :value="tag"
                     v-model="activeTags"
              >
              <label :for="'tagCheckbox' + tag.id" class="mr-5 mb-0 small">{{ tag.title }}</label>
            <span v-if="tagCounts && category !== 'Custom'" class="small float-right d-flex">
              <div class="mr-1">{{ getTagCount(tag) }}</div>
              <div :style="'width:' + tagPortion(tag) + 'px; height: 5px'"
                   class="bg-success my-auto rounded"/>
            </span>
            <span v-if="checkCustom(category)" class="small float-right mr-3">
              <div style="cursor: pointer" @click="deleteTag(tag)">
                <i class="fas fa-trash-alt small"></i>
              </div>
            </span>
            <span v-if="checkCustom(category)" class="small float-right mr-3">
              <div style="cursor: pointer" @click="showAddTagModal(tag)">
                <i class="fas fa-edit small"></i>
              </div>
            </span>
          </div>
    </span>
  </div>
</template>

<script>
import toast from "@/utils/toast";

export default {
  name: "TagDropdown",
  props: ['category', 'tags', 'tagCounts'],
  data() {
    return {
      isOpen: !!this.checkCustom(this.category),
      activeTags: this.$store.state.selectedTags,
      sortedTags: this.tags
    }
  },
  computed: {
    totalTagCount() {
      let tagTotal = 0
      if (this.tagCounts) {
        this.tags.forEach((tag) => {
          let tagCount = this.getTagCount(tag)
          if (tagCount) {
            tagTotal += tagCount
          }
        })
      }
      return tagTotal
    }
  },
  mounted() {
    if(this.category === 'Field Position') {
      const tagOrder = ['1', '1.5', '2', '3', '4', 'Flick']
      // eslint-disable-next-line vue/no-mutating-props
      this.sortedTags = [...this.tags].sort((a, b) => {
        return tagOrder.indexOf(a.title) - tagOrder.indexOf(b.title);
      })
    }
    if (this.category === 'Rally Description') {
      const tagOrder = ['1-4', '1-4+indo', '1 Attacker', '2 Attackers']
      // eslint-disable-next-line vue/no-mutating-props
      this.sortedTags = [...this.tags].sort((a, b) => {
        return tagOrder.indexOf(a.title) - tagOrder.indexOf(b.title);
      })
    }
    if(this.category === 'First Initiative Player') {
      // eslint-disable-next-line vue/no-mutating-props
      this.sortedTags = [...this.tags].sort((a, b) => {
        return a.title.localeCompare(b.title);
      })
    }
  },
  methods: {
    handleClick(value) {
      this.$emit('tagSelected', value)
    },
    showAddTagModal(tag) {
      this.$emit('openModal', tag)
    },
    getTagCount(tag) {
      return this.tagCounts[tag.id]
    },
    tagPortion(tag) {
      let portion = (this.getTagCount(tag) / this.totalTagCount) * 75
      return portion ? portion : 0
    },
    checkCustom(category) {
      return category === 'Custom'
    },
    deleteTag(tag) {
      const result = confirm("Möchten Sie die Wiedergabeliste wirklich löschen?");
      if (result) {
        this.$api.deleteTag({
          segments: {id: tag.id}
        }).then(() => {
          toast.success('Wiedergabeliste wurde erfolgreich entfernt')
          let tagIndex = this.tags.findIndex((item) => {
            return item.id === tag.id
          })
          // eslint-disable-next-line vue/no-mutating-props
          this.tags.splice(tagIndex, 1)
          this.handleClick(tag)
        })
      }
    },
    hideIfBadminton(category) {
      return this.$store.state.game === 'badminton' && ['Aufschlag', 'Punkt', 'Qualität'].includes(category)
    },
    toggleCollapse() {
      if (!this.checkCustom(this.category)) {
        this.isOpen = !this.isOpen;
      }
    }
  },
}
</script>

<style scoped>

</style>
