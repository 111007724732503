<template>
    <div id="annotation-item">
        <h6 v-on:click="jumpToTimestamp()" style="cursor: pointer">
            {{annotation.user_name}}
          <time class="timeago" v-if="annotation.timestamp !== null">bei {{convertTimestamp(annotation.timestamp.toFixed(1))}}</time>
          <time v-else class="timeago" :datetime="annotation.created_at"></time>
        </h6>
        <div class="annotation-text" v-on:click="jumpToTimestamp()" style="white-space: pre-wrap;">
          <template v-if="annotationType==='text'">{{annotation.text}}</template>
          <a class="download" v-else v-on:click="download()">{{annotation.text}}</a>
        </div>

        <div class="file-preview">
            <img v-if="annotationType==='pic'" v-bind:src="fileURL" alt="img alt" width="130px" height="auto"/>
            <i v-if="annotationType==='pdf'" class="fas fa-file-pdf "></i>
            <i v-if="annotationType==='doc'" class="fas fa-file-word"></i>
            <i v-if="annotationType==='ppt'" class="fas fa-file-powerpoint"></i>
            <i v-if="annotationType==='vid'" class="fas fa-file-video"></i>
            <i v-if="annotationType==='default'" class="fas fa-file"></i>
        </div>

        <div>
            <template v-if="typeof selectable !== 'undefined'">
                <template  v-if="($store.state.user.role_name === 'admin' || $store.state.user.user_id === annotation.user_id)">
                    <span class="delete" @click="deleteAnnotation">
                        löschen
                    <i class="fas fa-trash-alt"></i>
                    </span>
                    <span class="edit-btn" @click="openEditAnnotation">bearbeiten <i class="fas fa-edit"></i></span>
                </template>
                <span v-if="selectable" class="answer" @click="selectAnnotation">
                        antworten <i class="fas fa-reply"></i>
                 </span>
                <span v-else class="cancel-answer" @click="selectAnnotation">
                   abbrechen <i class="fas fa-comment-slash"></i>
                </span>
            </template>
            <template
                    v-if="typeof selectable === 'undefined' && ($store.state.user.role_name === 'admin' || $store.state.user.user_id === annotation.user_id)">
                <span class="delete" @click="deleteAnnotation">löschen <i class="fas fa-trash-alt"></i></span>
                <span class="edit-btn" @click="openEditAnnotation">bearbeiten <i class="fas fa-edit"></i></span>
            </template>
        </div>

        <modal :name="'edit-annotation-model-' + annotation.id" :clickToClose="false" height="auto" :adaptive="true">
            <div class="container">
                <div class="row">
                    <div class="close-btn">
                        <button @click="closeEditAnnotation">
                            ❌
                        </button>
                    </div>
                    <label class="file-label pt-3 pl-3">Kommentar Bearbeiten</label>
                    <textarea aria-label="annotation" class="annotation-input form-control" :placeholder="annotation.text" type="text"
                              v-model="newAnnotationText" maxlength="1000"></textarea>
                </div>
                <div class="row">
                    <button class="btn btn-primary upload-btn" v-on:click="updateAnnotation()">Ändern</button>
                </div>
            </div>
        </modal>

    </div>
</template>

<script>

    import serverURL from '../../utils/serverURL'
    import toast from '../../utils/toast';

    // eslint-disable-next-line
    let timeago = require('timeago');
    // eslint-disable-next-line
    let de = require('timeago/locales/jquery.timeago.de');
    let $ = require('jquery');

    import {cordovaUtils} from '../../utils/cordovaUtils.jsx';
    import AnnotationDataAccessor from "../../storage/annotation-accessor.jsx";

    export default {
        name: "AnnotationItem",
        props: ['annotation', 'selectable'],
        data: function () {
            return {
                annotationType: null,
                fileURL: null,
                newAnnotationText: this.annotation.text,
                timestamp: this.annotation.timestamp,
                serverURL: serverURL,
            }
        },
        mounted() {
            let self = this;
            $("time.timeago").timeago();
            if (this.annotation.file_path) {
                if (cordovaUtils.isCordova() && this.$store.state.downloaded_annotations.includes(this.annotation.id)) {
                    let ada = new AnnotationDataAccessor(this.annotation.id);
                    ada.getAnnotationFile().then(function (file_url) {
                        self.fileURL = file_url;
                    });

                } else {
                    this.fileURL = serverURL + this.annotation.file_path.replace('/storage', 'storage');
                    this.downloadURL = serverURL + 'api/annotation/download/' + this.annotation.id;
                }


            }

            if (this.annotation.type === 'file') {
                switch (this.annotation.file_path.split('.').pop()) {
                    case 'jpg' :
                    case 'jpeg' :
                    case 'png' :
                        this.annotationType = 'pic';
                        break;
                    case 'pdf':
                        this.annotationType = 'pdf';
                        break;
                    case 'doc':
                    case 'dot':
                    case 'docx':
                        this.annotationType = 'doc';
                        break;
                    case 'ppt':
                    case 'pptx':
                    case 'ppz':
                    case 'pps':
                    case 'pot':
                        this.annotationType = 'ppt';
                        break;
                    case 'mp4':
                    case 'wmv':
                    case 'flv':
                    case 'avi':
                    case 'ogg':
                        this.annotationType = 'vid';
                        break;
                    default:
                        this.annotationType = 'default'
                }
            } else {
                this.annotationType = 'text'
            }


        },
        methods: {
            selectAnnotation: function () {
                this.$emit('selectAnnotation');
            },
            openEditAnnotation: function () {
                if (cordovaUtils.isOfflineCancel()) {
                    return 0;
                }
                let self = this;
                setTimeout(function () {
                    self.$modal.show('edit-annotation-model-' + self.annotation.id);
                }, 10);
            },
            closeEditAnnotation() {
                this.$modal.hide('edit-annotation-model-' + this.annotation.id);
            },
            updateAnnotation() {
                if (cordovaUtils.isOfflineCancel()) {
                    return 0;
                }
                let self = this;
                this.$api.updateAnnotation({
                    segments: {
                        id: self.annotation.id
                    },
                    data: {
                        _method: 'patch',
                        text: self.newAnnotationText,
                    }
                }).then(response => {
                        if (response.status === 200) {
                            self.$emit('updateParent', response.data)
                            self.$modal.hide('edit-annotation-model-' + this.annotation.id);
                        } else {
                            toast.error("Requestfehler: " + response.status)
                        }

                    },
                );
            },
            deleteAnnotation: function () {
                const result = confirm("Möchten sie wirklich den Kommentar aus der Datenbank löschen?");
                if (result) {
                    if (cordovaUtils.isOfflineCancel()) {
                        return 0;
                    }
                    this.$api.deleteAnnotation({
                        segments: {aId: this.annotation.id}
                    }).then(
                        this.$emit('deleteAnnotation'),
                        toast.success('Kommentar gelöscht'));
                }
            },
            jumpToTimestamp: function () {
                if(this.timestamp !== null) {
                    this.$emit('jumpToTimestamp', this.timestamp);
                }
            },
            download() {
                let self = this;

                if (cordovaUtils.isCordova() && cordovaUtils.isOnline() && !this.$store.state.downloaded_annotations.includes(this.annotation.id)) {
                    this.$store.commit("addActiveNetworkTask", 1);
                    let ada = new AnnotationDataAccessor(self.annotation.id);
                    ada.saveAnnotation(self.annotation).then(function () {
                        self.$store.commit("addActiveNetworkTask", -1);
                        toast.success(self.annotation.text + " heruntergeladen");
                    })
                } else if (cordovaUtils.isCordova() && this.$store.state.downloaded_annotations.includes(this.annotation.id)) {
                    cordovaUtils.viewDocument(self.annotation.text + '.' + self.annotation.file_path.split('.').pop(), 'application/pdf')
                } else if (cordovaUtils.isOnline()) {
                    $.ajax({
                        type: 'GET',
                        url: serverURL + 'api/annotation/download/' + this.annotation.id,
                        headers: {
                            "Authorization": "Bearer " + this.$store.state.token,
                        },
                        xhrFields: {
                            responseType: 'blob'
                        },
                        success: function (blob) {
                            let link = document.createElement('a');
                            link.setAttribute("type", "hidden");
                            document.body.appendChild(link);
                            link.href = window.URL.createObjectURL(blob);
                            link.download = self.annotation.text;
                            link.click();
                        },
                        error: function () {
                        }
                    });
                }
            },
          convertTimestamp(timestamp) {
              return Math.floor(timestamp / 60) + "min " + Math.floor(timestamp % 60) + "s";
          }
        }
    }
</script>

<style scoped>

</style>