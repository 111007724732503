<template>
  <div id="video-container" class="d-flex flex-column flex-lg-row overflow-hidden">
    <div id="video-element" class="fixed w-100 vh-100 m-auto">
          <span @click="switchMobileControls">
              <!-- Video HTML5 Tag -->
            <template v-if="is_corodva">
                <video controls playsinline v-if="getDevicePlatform() === 'iOS'" webkit-playsinline
                       style="width:100%;height: 100%; object-fit: fill;">
                    <source :src="videoData ? videoData.absolute_path : ''" type="video/mp4">
                </video>
                <video class="ios-video" :src="videoData ? videoData.absolute_path : ''" id="video"
                       v-else-if="getDevicePlatform() === 'Android'"
                       style="width:100%;height: 100%; object-fit: fill;"></video>
            </template>
            <video :src="videoData ? videoData.absolute_path : ''" id="video" playsinline
                   style="width:100%;height: 100%; object-fit: fill" v-else></video>

            <!-- Video Canvas for showing overlays -->
            <video-overlay :overlay-data="videoData.video_overlays"
                           :playback-time="currentVideoTime"
                           :isPlaying="this.$refs.videoControls.videoIsPlaying"
                           @activeAnnotations="activeAnnotations = $event"
                           @activeOverlays="activateOverlays($event)"
                           @canvasMounted="$refs.drawingControls.canvasMounted($event)"
                           @pauseVideo="$refs.videoControls.pauseVideo($event)"
                           ref="videoOverlay"
                           v-if="videoData.id"
            >
            </video-overlay>
          </span>
      <!-- Video controls for playing video -->
      <video-controls :overlay-data="videoData ? videoData.video_overlays : []"
                      :scenes=$route.params.scenes
                      :active-overlay="currentOverlay ? currentOverlay : null"
                      :style="{display: showDrawingTools ? 'none' : ''}"
                      :video="videoData"
                      :active-scene="activeScene"
                      :tagging-start="activeRecordingStart"
                      :tagging-stop="activeRecordingEnd"
                      @addOverlay="addOverlayToVideo"
                      @editOverlay="editOverlay"
                      @controlsSwitched="switchControls"
                      @videoTimeChanged="videoTimeChanged"
                      ref="videoControls"
                      v-show="showControls"
                      v-model="currentSceneIndex"
      ></video-controls>

      <!-- Controls for drawing shapes -->
      <drawing-controls :currentOverlay="currentOverlay"
                        :show-drawing-tools="showDrawingTools"
                        :style="{display: showDrawingTools ? '' : 'none'}"
                        @controlsSwitched="switchControls"
                        @canceledEditing="deleteNewMarkers($event)"
                        @overlayDeleted="overlayDeleted"
                        @overlayUpdated="overlayUpdated"
                        ref="drawingControls"
      >
      </drawing-controls>
    </div>
    <scene-table
        v-model="currentSceneIndex"
        v-if="videoData.scenes && videoData.scenes.length"
        :scenes="filteredScenes"
        :categories="tagOptions"
        :video-time="currentVideoTime"
        :file="videoData.files ? videoData.files[0] : null"
        :video-title="videoData.label"
        :remove-tags="selectedCustomTags"
        @sceneChanged="updateVideoTime($event)"
        @pauseVideo="$refs.videoControls.pauseVideo($event)"
        style="height: 0; overflow: auto; visibility: hidden;
}"
    ></scene-table>
  </div>
</template>

<script>
import 'bootstrap/js/dist/dropdown';
import VideoOverlay from "./VideoOverlay";
import VideoControls from "./VideoControls";
import DrawingControls from "./DrawingControls";
import VideoDataAccessor from "./../../storage/video-accessor.jsx";
import toast from './../../utils/toast';
import {cordovaUtils} from "@/utils/cordovaUtils";
import SceneTable from "@/components/Scenes/SceneTable.vue";

export default {
  name: 'VideoElement',
  components: {
    SceneTable,
    VideoOverlay,
    VideoControls,
    DrawingControls,
  },
  data: function () {
    return {
      videoData: {
        absolute_path: null,
        created_at: null,
        deleted_at: null,
        id: null,
        label: null,
        path: null,
        sport: null,
        updated_at: null,
        video_overlays: null,
        videoable: null,
        videoable_id: null,
        videoable_type: null,
      },
      currentSceneIndex: 0,
      currentVideoTime: 0,
      showDrawingTools: false,
      is_corodva: !!window.cordova,
      isPersistent: this.$store.state.downloaded_videos.indexOf(parseInt(this.$route.params.id)) >= 0,
      activeAnnotations: [],
      currentOverlay: null,
      videoPlayerType: null,
      cordovaIsUpdating: false,
      cordovaUpdates: false,
      showControls: true,
      activeContainer: 'annotations',
      tagOptions: [],
      activeScene: null,
      activeRecordingStart: null,
      activeRecordingEnd: null,
    }
  },
  computed: {
    filteredScenes() {
      if (this.$store.state.selectedTags.length) {
        let foundScenes = []
        // Group selected tags by category to get the some filter (as in A or B)
        let groupedTags = this.groupBy(this.$store.state.selectedTags, 'category_id')
        Object.values(groupedTags).forEach((tags) => {
          let filter = this.videoData.scenes.filter((scene) => {
            const sceneTags = scene.tags.map((tag) => tag.id)
            const selectedTagIds = tags.map((tag) => tag.id)
            return selectedTagIds.some((tag) => sceneTags.includes(tag))
          })
          foundScenes.push(filter)
        })
        // Reduce all found scenes to only those that exist in all the some filters ((A or B) and +)
        return foundScenes.reduce((a, b) => a.filter(c => b.includes(c)))
      }
      return this.videoData.scenes
    },
    existingTagsCount() {
      let tags = []
      this.filteredScenes.forEach((scene) => {
        tags.push(...scene.tags)
      })
      let groupedTags = this.groupBy(tags, 'id')
      Object.keys(groupedTags).forEach(key => groupedTags[key] = groupedTags[key].length)
      return groupedTags
    },
    currentGame() {
      return this.$store.getters.getCurrentGame;
    },
    selectedCustomTags() {
      return this.$store.getters.getSelectedTags.filter((tag) => this.isCustomTag(tag));
    }
  },
  mounted: function () {
    let video = document.getElementById("video");
    video.controls = false;

    this.$refs.videoControls.videoIsEmbedded(video);
    video.addEventListener('loadeddata', function () {
      this.$refs.videoOverlay.videoLoaded();
    }.bind(this));

    this.getVideo();

    this.getTags();

  },
  created() {
    let self = this;
    this.$vueEventBus.$on('custom-update', function () {
      self.getVideo();
    });
    this.$emit('activeAnnotations', self.annotations);
  },
  beforeDestroy() {
    this.$vueEventBus.$off('custom-update');
    this.$store.state.selectedTags = []
    this.$emit('activeAnnotations', null);
  },
  methods: {
    getVideo: function () {
      let self = this;
      if (this.is_corodva && this.isPersistent) {
        let vid = new VideoDataAccessor(parseInt(self.$route.params.id));
        vid.getVideo().then(function (video) {

          self.videoData = video;
          vid.getVideoFile().then(function (file_url) {
            self.videoData.absolute_path = file_url;
          });
        });

      } else {
        this.$api.getVideoDetail({
          segments: {id: parseInt(this.$route.params.id)}
        }).then(response => {
          if (response.status === 200) {
            self.videoData = response.data;
          } else {
            toast.error('Bitte verbinde dich mit dem Internet')
          }
        });
      }
    },
    getTags() {
      this.$api.fetchVideoTags({segments: {videoId: this.$route.params.id}}).then(res => this.tagOptions = this.customSortCategories(res.data))
    },
    isSafariIos: function () {
      const userAgent = navigator.userAgent;
      return /iPhone|iPad|iPod/.test(userAgent) && /Safari/.test(userAgent) && !/Chrome|CriOS|OPiOS|mercury/.test(userAgent);
    },
    getDevicePlatform: function () {
      return cordovaUtils.getDevicePlatform();
    },
    videoTimeChanged(currentVideoTime) {
      this.currentVideoTime = currentVideoTime;
    },
    switchControls() {
      this.showDrawingTools = !this.showDrawingTools;
    },
    addOverlayToVideo(overlay) {
      let newVideo_overlays = this.videoData.video_overlays;
      newVideo_overlays.push(overlay);
      this.$set(this.videoData, 'video_overlays', newVideo_overlays);
      this.editOverlay(overlay);
    },
    editOverlay(overlay) {
      this.currentOverlay = overlay;
    },
    overlayUpdated(overlay) {
      let self = this;
      this.videoData.video_overlays.forEach(
          function (currentValue, currentIndex, listObj) {
            if (currentValue.id === overlay.id) {
              self.$set(listObj, currentIndex, overlay);
            }
          }
      );
      //TODO:Save video if offline
    },
    overlayDeleted() {
      this.videoData.video_overlays.pop();
    },
    activateOverlays(overlays) {
      if (overlays) {
        this.currentOverlay = overlays.sort((a, b) => (a.start < b.start) ? 1 : -1)[0];
      } else {
        this.currentOverlay = null;
      }
    },
    deleteNewMarkers(data) {
      let updatedOverlay = this.videoData.video_overlays.find(function (overlay) {
        return overlay.id === data.overlay.id
      });
      for (let i = 1; i <= data.newMarkers; i++) {
        updatedOverlay.video_markers.pop();
      }
      this.$refs.videoOverlay.videoLoaded();
      this.$refs.videoOverlay.redraw();
    },
    switchMobileControls() {
      if (window.innerWidth <= 855) {
        this.showControls = !this.showControls;
      }
    },
    addScene(scene) {
      this.videoData.scenes.push(scene)
      this.videoData.scenes.sort((a, b) => a.start > b.start)
    },
    updateVideoTime(payload) {
      this.$refs.videoControls.pauseVideo(payload.videoTime)
      this.activeScene = payload.scene
      this.$refs.videoControls.toggleVideoIsPlaying()
    },
    groupBy(array, key) {
      const result = {}
      array.forEach(item => {
        if (!result[item[key]]) {
          result[item[key]] = []
        }
        result[item[key]].push(item)
      })
      return result
    },
    addImportedScenes(scenes) {
      this.getTags()
      this.videoData.scenes.push(...scenes)
    },
    customSortCategories(categories) {
      let sorted = categories
      let ordering = {}, // map for efficient lookup of sortIndex
          sortOrder = [
            "Aufschlag",
            "Punkt",
            "Qualität",
            "Rally",
            "Game",
            "Rally Timeline",
            "Indo",
            "Point",
            "Win with",
            "Rally Description",
            "Rally Finish",
            "First Initiative Player",
            "First Initiative Team",
            "Point Shots",
            "Server_Position",
            "Field Position",
            "#3",
            "Team 1 Server",
            "Team 1 Return",
            "Team 2 Server",
            "Team 2 Return",
            "Return_Placement",
            "NOTE!",
            "Practise",
            "XD_Player",
            "XD_Position",
            "XD_Racquet_Frontcourt",
            "XD_Situation",
            "XD_Shot",
            "Koordinaten",
            "Backcourt_Player",
            "Backcourt_Position",
            "Backcourt_Placement",
            "Backcourt_Shot",
            "TEAM 1 Players",
            "TEAM 2 Players",
            "DATE",
            "Location",
            "Analyst",
            "Custom"
          ]
      for (let i = 0; i < sortOrder.length; i++)
        ordering[sortOrder[i]] = i;

      sorted.sort(function (a, b) {
        return ordering[a.title] - ordering[b.title];
      });

      return sorted
    },
    isCustomTag(tag) {
      return this.tagOptions.filter(item => item.title === 'Custom').find((category) => {
        return category.id === tag.category_id
      })
    }
  },
  watch: {
    currentOverlay() {
      this.$nextTick()
          .then(function () {
            this.$refs.videoOverlay.videoLoaded();
            this.$refs.videoOverlay.redraw();
          }.bind(this));
    },
    videoData: {
      handler(newVideoData) {
        if (this.isPersistent) {
          let self = this;
          self.cordovaMakeUpdate = true;

          // eslint-disable-next-line no-inner-declarations
          function Sleep(milliseconds) {
            return new Promise(resolve => setTimeout(resolve, milliseconds));
          }

          // eslint-disable-next-line no-inner-declarations
          async function update() {
            if (self.cordovaMakeUpdate) {
              if (!self.cordovaUpdates) {
                self.cordovaUpdates = true;
                self.cordovaMakeUpdate = false;
                await Sleep(1000);
                let vid = new VideoDataAccessor(parseInt(self.$route.params.id));
                vid.updateVideo(newVideoData).then(function () {
                  self.cordovaUpdates = false;
                  if (self.cordovaMakeUpdate) {
                    update()
                  }
                });
              }
            }
          }

          update()
        }
      }
      ,
      deep: true
    },
  },
}
</script>

<style>
.ios-video {
  position: fixed;
  max-width: 100vw;
  width: 100%;
}
</style>
