<template>
    <div class="card">
        <div class="card-body">
            <div class="container">
                <div class="form-group">
                    <label class="col-form-label">
                        <button class="btn btn-primary" type="button" @click="createTeams(player.id)">Speichern</button>
                    </label>
                </div>
                <div>
                    <div class="form-group">
                        <label for="singleCheck">Einzel</label>
                        <input class="ml-2" v-if="!isSingle" type="checkbox" name="singleCheck"
                               id="singleCheck" v-model="createSingle"
                        >
                        <div v-else class="ml-2 d-inline">
                            <button class="btn team-btn" @click="deleteTeam(null)">
                                <i class="fas fa-trash-alt"></i>
                            </button>
                        </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label">Partner</label>
                        <div class="input-group col-lg-9">
                            <div :class="(currentFilter === 'name') ? 'input-group-text search-filter active' : 'input-group-text search-filter'" @click="switchFilter('name')">
                                <i class="fas fa-user"></i>
                            </div>
                            <div :class="(currentFilter === 'nation') ? 'input-group-text search-filter active' : 'input-group-text search-filter'" @click="switchFilter('nation')">
                                <i class="fas fa-flag"></i>
                            </div>
                            <input v-if="currentFilter === 'name'" class="form-control" placeholder="Suche..." v-model="filterName" autocomplete="off">
                            <input v-else class="form-control" placeholder="Suche..." v-model="filterNation" autocomplete="off">
                        </div>
                    </div>
                    <table class="table bg-white mt-2 col-lg-12 team-table" v-if="players.length">
                        <tr>
                            <th>Nationalität</th>
                            <th>Name</th>
                            <th class="text-center">Als Partner festlegen</th>
                        </tr>
                        <tr :key="partner.id" v-for="partner in players">
                            <td v-if="partner.id !== player.id">{{ partner.nation }}</td>
                            <td v-if="partner.id !== player.id">{{ playerName(partner) }}</td>
                            <td v-if="partner.id !== player.id">
                                <fieldset class="form-group mb-0">
                                    <div class="row">
                                            <div class="form-check m-auto" v-if="!currentPartners.includes(partner.id)">
                                                <input class="form-check-input" type="checkbox" name="gridChecks"
                                                       :id="'gridCheck' + partner.id" :value="[partner.id, partner.gender]" v-model="newPartners"
                                                >
                                                <label class="form-check-label" :for="'gridCheck' + partner.id">
                                                </label>
                                            </div>
                                            <div v-else class="form-check m-auto">
                                                <button class="btn team-btn" @click="deleteTeam(partner.id)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </div>
                                    </div>
                                </fieldset>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
</template>

<script>
    import toast from '../../utils/toast';
    import {cordovaUtils} from "../../utils/cordovaUtils";

    export default {
        name: "PlayerTeam",
        data: function () {
            return {
                createSingle: null,
                teamType: null,
                newPartners: [],
                filterName: '',
                filterNation: '',
                currentFilter: 'name',
            }
        },
        props: ['player'],
        methods: {
            playerName(player) {
                return player.surname + ', ' + player.forename;
            },
            createTeams(playerID) {
                if(cordovaUtils.isOfflineCancel()){
                    return 0;
                }
                let self = this;
                if(self.createSingle) {
                    this.$api.createTeam({
                        segments: {id: playerID},
                        data: {
                            type: 'single',
                            player2_id: null
                        }
                    }).then(response => {
                            toast.success('Partner hinzugefügt!');
                            self.$emit('teamAdded', response.data);
                            self.addTeamType(response.data.player2_id, response.data.type);
                    }
                    );
                    this.createSingle = false;
                }
                if(self.newPartners.length) {
                    self.newPartners.forEach(function (teamPartner) {
                        self.$api.createTeam({
                            segments: {id: playerID},
                            data: {
                                type: teamPartner[1] === self.player.gender ? 'doubles' : 'mixed',
                                player2_id: teamPartner[0]
                            }
                        }).then(response => {
                            toast.success('Partner hinzugefügt!');
                            self.$emit('teamAdded', response.data);
                            self.addTeamType(response.data.player2_id, response.data.type);
                        })

                    })
                }
                this.newPartners = [];
            },
            deleteTeam(player2ID) {
                if(cordovaUtils.isOfflineCancel()){
                    return 0;
                }
                let teamID = null;
                let self = this;
                this.player.teams.forEach(function (team) {
                    if (player2ID === team.player2_id || player2ID === team.player1_id) {
                        teamID = team.id;
                    }
                });
                this.$api.deleteTeam({
                    segments: {pid: self.player.id, tid: teamID}
                }).then(
                    toast.success('Team gelöscht'),
                    self.$emit('teamDeleted', player2ID),
                )
            },
            addTeamType(player2_id, type) {
                let self = this;
                let player1 = this.players.find(function (player) {
                    return player.id === self.player.id;
                });
                if (!player1['teamTypes'].includes(type)){
                    player1['teamTypes'].push(type);
                }
                if (player2_id) {
                    let player2 = this.players.find(function (player) {
                        return player.id === player2_id;
                    });
                    if (!player2['teamTypes'].includes(type)){
                        player2['teamTypes'].push(type);
                    }
                }
            },
            switchFilter(filter) {
                this.filterNation = '';
                this.filterName = '';
                this.currentFilter = filter;
            }
        },
        computed: {
            players() {
                let players = this.$store.state.players;
                if (this.filterName) {
                    players = players.filter(player =>
                        this.playerName(player).toLowerCase().indexOf(this.filterName.toLowerCase().trim()) > -1
                    );
                } else if (this.filterNation) {
                    players = players.filter(player =>
                        player.nation.toLowerCase().indexOf(this.filterNation.toLowerCase().trim()) > -1
                    );
                }
                return players;
            },
            currentPartners(){
                let currentPartners = [];
                let self = this;
                this.player.teams.forEach(function (team) {
                    if(team.player1_id === self.player.id){
                        currentPartners.push(team.player2_id);
                    } else {
                        currentPartners.push(team.player1_id);
                    }

                });
                return currentPartners;
            },
            isSingle() {
                let isSingle = false;
                this.player.teams.forEach(function (team) {
                    if(team.player2_id === null) {
                        isSingle = true;
                    }
                });
                return isSingle;
            }
        },
    }

</script>

<style scoped>

</style>
