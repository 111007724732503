<template>
  <div v-if="winData.length">
    <div class="table-header">
      <div class="table-first-col">{{ title }}</div>
      <div class="table-col" v-for="team in teams" :key="team"><span class="table-cell">{{ team }}</span></div>
    </div>
    <div class="table-row table-inside-row" v-for="win in winData" :key="win.label">
      <div class="table-first-col">{{ win.label }}</div>
      <div class="table-col" style="cursor: pointer" v-for="team in teams" :key="team"
           @click="emitFilter($event, team, win.label, win.values[team])">
        <span class="table-cell">{{ win.values[team] }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {ref} from "vue";

export default {
  name: 'WinWithTable',
  props: {
    title: {
      type: String,
      required: true
    },
    winData: {
      type: Array,
      required: true
    }
  },
  setup() {
    const teams = ref([])
    return {teams}
  },
  methods: {
    emitFilter(event, team, game, value) {
      let filter = {
        team: team,
        game: game,
        value: value,
        ctrlKey: event.ctrlKey
      }
      this.$emit('updateFilter', filter)
    }
  },
  mounted() {
    if(this.winData.length > 0)
      this.teams = Object.keys(this.winData[0].values)
  }
}
</script>

<style scoped>
.table-header, .table-row {
  display: flex;
  margin-bottom: 1px;
}

.table-inside-row {
  border-bottom: #0f0f0f solid 1px;
}

.table-inside-row:last-child {
  border-bottom: 0;
}

.table-header {
  font-weight: bold;
  border-bottom: #0f0f0f solid 1px;
}

.table-first-col {
  text-overflow: ellipsis;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
}

.table-col {
  flex: 1;
}

.table-cell {
  display: table;
  margin: auto
}
</style>
