<template>
  <div class="p-5">
    <div class="close-btn">
      <button @click="hide()">
        ❌
      </button>
    </div>
    <div class="form-group">
      <label>Szene bearbeiten</label>
    </div>
    <form>
      <div class="form-group row">
        <label class="col-lg-3 col-form-label" for="name">Name</label>
        <div class="col-lg-9 mb-2">
          <input class="form-control" id="name" placeholder="Name" required="required"
                 type="text" v-model="title">
        </div>
        <label class="col-lg-3 col-form-label" for="position">Position</label>
        <div class="col-lg-9 mb-2">
          <input class="form-control" id="position" required="required"
                 type="time" v-model="position" step="1">
        </div>
        <label class="col-lg-3 col-form-label" for="length">Dauer</label>
        <div class="col-lg-9 input-group">
          <input class="form-control" id="length" placeholder="Dauer" required="required"
                 type="number" v-model="length" step="1">
          <div class="input-group-append">
            <span class="input-group-text" id="length">Sek</span>
          </div>
        </div>
        <div v-for="category in filteredOptions" v-bind:key="category.id" class="col-lg-4 mb-2">
          <label class="col-lg-3 col-form-label" :for="category.title">{{ category.title }}</label>
          <select class="form-control col-lg-9" :id="category.title" required="required" v-model="tags[category.title].id">
            <option :value="null"></option>
            <option v-for="tag in category.tags" v-bind:key="tag.id" :value="tag.id">
              {{ tag.title }}
            </option>
          </select>
        </div>
      </div>
    </form>
    <button class="btn btn-primary float-right" @click="processForm" :disabled="isDisabled">
      Hinzufügen
    </button>
  </div>
</template>

<script>
export default {
  name: "SceneTableEditModal",
  props: ['scene', 'options'],
  data() {
    return {
      title: this.scene.title,
      position: this.convertMillisecondsToTime(this.scene.start),
      length: (this.scene.end - this.scene.start) / 1000,
      isDisabled: false,
      possibleTags: ['Aufschlag', 'Punkt', 'Qualität'],
      tags: {
        Aufschlag: {id: this.getTagId(this.scene, 'Aufschlag')},
        Punkt: {id: this.getTagId(this.scene, 'Punkt')},
        Qualität: {id: this.getTagId(this.scene, 'Qualität')}
      }
    }
  },
  computed: {
    filteredOptions() {
      return this.options.filter(tag => this.possibleTags.includes(tag.title))
    },
    customTags() {
      return this.scene.tags.filter(tag => !this.possibleTags.includes(tag.category.title))
    }
  },
  methods: {
    processForm() {
      this.isDisabled = true
      let start = this.convertTimeToMilliseconds(this.position)
      let end = start + (this.length * 1000)
      let sceneTags = this.customTags.map((item) => {
        return {id: item.id}
      })
      let formData = {
        title: this.title,
        start: start,
        end: end,
        tags: Object.values(this.tags).filter(n => n.id).concat(sceneTags)
      }
      this.$api.updateScene({segments: {id: this.scene.id}, data: formData}).then((res) => {
        this.$emit('sceneUpdated', res.data)
        this.hide()
      })
    },
    convertMillisecondsToTime(milliseconds) {
      return new Date(milliseconds).toISOString().substring(11, 19)
    },
    convertTimeToMilliseconds(time) {
      let hms = time.split(':');
      let sec = (+hms[0]) * 60 * 60 + (+hms[1]) * 60 + (+hms[2] || 0);
      return sec * 1000
    },
    hide() {
      this.$modal.hide('editScene');
    },
    getTagId(scene, category) {
      let foundTag = scene.tags.find(tag => tag.category.title === category)
      if (foundTag) {
        return foundTag.id
      }
      return null
    }
  }
}
</script>

<style scoped>

</style>