<template>
  <div class="container">
    <div class="row justify-content-between">
      <div class="col-lg-12 mt-2">
        <div class="input-group">
          <div class="input-group-prepend">
            <div
                :class="(currentFilter === 'name') ? 'input-group-text search-filter active' : 'input-group-text search-filter'"
                @click="switchFilter('name')">
              <i class="fas fa-user"></i>
            </div>
            <div
                :class="(currentFilter === 'nation') ? 'input-group-text search-filter active' : 'input-group-text search-filter'"
                @click="switchFilter('nation')">
              <i class="fas fa-flag"></i>
            </div>
          </div>
          <input v-if="currentFilter === 'name'" class="form-control filter-input" placeholder="Suche nach Sportler..."
                 :value="filterName"
                 @input="e => filterName = e.target.value" autocomplete="off">
          <input v-else class="form-control filter-input" placeholder="Suche nach Nation..." :value="filterNation"
                 @input="e => filterNation = e.target.value" autocomplete="off">
        </div>
      </div>
    </div>

    <table class="table bg-white mt-2 player-list-table" v-if="players.length">
      <tr>
        <th>Nation</th>
        <th>Name</th>
        <th v-if="$store.state.user.role_name !== 'player'">Aktion</th>
        <th v-if="is_corodva">
          <button id="sync-btn" type="button" class="btn btn-light"
                  v-on:click="syncOfflineData"
          ><i class="fas fa-sync"></i></button>
        </th>
      </tr>
      <tr :key="player.id" v-for="player in players">
        <td>{{ player.nation }}</td>
        <td>
          <router-link :to="'/player/detail/' + player.id" class="player-link">
            {{ playerName(player) }}
          </router-link>
        </td>
        <td v-if="$store.state.user.role_name !== 'player'">
          <router-link :to="'/player/detail/' + player.id + '/PlayerEdit/0'"
                       v-if="$store.state.user.role_name !== 'player'" class="btn btn-player-list" tag="button">
            <i class="fas fa-user-edit"></i>
          </router-link>
          <button v-if="$store.state.user.role_name === 'admin'" @click="destroyPlayer(player)"
                  class="ml-1 btn btn-player-list" type="button">
            <i class="fas fa-trash-alt"></i>
          </button>
        </td>
        <td>
          <button @click="deleteOfflinePlayerData(player)" v-if="isPersistent(player.id)" class="btn btn-player-list"
                  type="button">
            <i class="fas fa-star"></i>
          </button>
          <button @click="saveOfflinePlayerData(player)" class="btn btn-player-list" type="button"
                  v-if="!isPersistent(player.id) && is_corodva">
            <img src="../../assets/img/star-empty.svg" alt="Kiwi standing on oval" width="14.4" height="12.8">
          </button>
        </td>
      </tr>
    </table>
    <div class="card bg-warning mt-3" v-else>
      <div class="card-body">
        Leider konnten keine Ergebnisse zu Ihrer Suche <b>"{{ filterName }}"</b> gefunden werden.
      </div>
    </div>
    <modal name="download-player-modal" height="auto" :clickToClose="false">
      <div class="close-btn">
        <button @click="hide()">
          ❌
        </button>
      </div>
      <div class="m-3">
        Spieler wird heruntergeladen
      </div>
      <div style="text-align: center" class="mb-3">
        <i class="fa fa-hourglass-half fa-3x"></i>
      </div>
    </modal>
  </div>
</template>

<script>
import PlayerDataAccessor from "./../../storage/player-accessor.jsx";
import toast from './../../utils/toast';
import {cordovaUtils} from "../../utils/cordovaUtils";

export default {
  name: 'PlayerList',
  data: function () {
    return {
      filterName: '',
      filterNation: '',
      currentFilter: 'name',
      is_corodva: !!window.cordova,
    };
  },
  mounted() {
    this.$store.state.selectedGame = null;
  },
  methods: {
    playerName(player) {
      return player.surname + ", " + player.forename;
    },
    forenameSurname(player) {
      return player.forename + " " + player.surname;
    },
    isPersistent(player_id) {
      return this.$store.state.downloaded_players.indexOf(parseInt(player_id)) >= 0;
    },
    saveOfflinePlayerData(player) {
      if (cordovaUtils.isOfflineCancel()) {
        return 0;
      }
      toast.success('Start downloading player: ' + player.forename + ' ' + player.surname);
      //this.showDownloadPlayerModal();
      this.$api.getPlayerDetail({
        segments: {id: parseInt(player.id)}
      }).then(playerResponse => {
        this.$store.dispatch('saveOfflinePlayerData', playerResponse.data)
      });
    },
    deleteOfflinePlayerData(player) {
      let self = this;
      let pfa = new PlayerDataAccessor(player.id);
      pfa.getPlayer().then(function (data) {
        self.$store.dispatch('deleteOfflinePlayerData', data);
      });
    },
    switchFilter(filter) {
      this.filterNation = '';
      this.filterName = '';
      this.currentFilter = filter;
    },
    destroyPlayer(player) {
      if (cordovaUtils.isOfflineCancel()) {
        return 0;
      }
      const result = confirm("Möchten sie wirklich \"" + player.surname + ", " + player.forename + "\" aus der Datenbank löschen?");
      if (result) {
        this.$api.destroyPlayer({
          segments: {id: player.id}
        }).then(function (response) {
          if (response['data']['success']) {
            this.$store.commit('destroyPlayer', player);
            window.location.href = '#/player/list';
          }
        }.bind(this))
      }
    },
    syncOfflineData: function () {
      if (cordovaUtils.isOfflineCancel()) {
        return 0;
      }
      //this.showDownloadPlayerModal();
      let self = this;
      this.$store.state.downloaded_players.forEach(function (playerid) {
        let player = self.$store.state.players.find(function (player) {
          return playerid === player.id;
        });
        self.$api.getPlayerDetail({
          segments: {id: parseInt(player.id)}
        }).then(playerResponse => {
          self.$store.dispatch('updateOfflinePlayerData', playerResponse.data);
        });
      });
      self.$emit('syncOfflineData');
    },
    showDownloadPlayerModal() {
      if (cordovaUtils.isOfflineCancel()) {
        return 0;
      }
      this.$modal.show('download-player-modal', {});
    },
    hide() {
      this.$modal.hide('download-player-modal');
    }
  },
  computed: {
    players() {
      let players = this.$store.state.players;
      let self = this;
      //If user is a player filter only non german and themselves
      if (this.$store.state.user.role_name === 'player') {
        players = players.filter(player => player.nation !== 'GER' ||
            (player.forename.toUpperCase() + ' ' + player.surname.toUpperCase() === self.$store.state.user.firstname.toUpperCase()));
      }

      if (this.$route.params.gender) {
        players = players.filter(player => player.gender === this.$route.params.gender);
      }
      if (this.$route.params.type) {
        players = players.filter(player => player.teamTypes.includes(this.$route.params.type));
      }

      if (this.filterName) {
        players = players.filter(player => {
              let nameWithoutComma = this.playerName(player).replace(',', '').toLowerCase().trim();
              return nameWithoutComma.toLowerCase().indexOf(this.filterName.toLowerCase().trim()) > -1 ||
                  this.forenameSurname(player).toLowerCase().indexOf(this.filterName.toLowerCase().trim()) > -1
            }
        );
      } else if (this.filterNation) {
        players = players.filter(player =>
            player.nation.toLowerCase().indexOf(this.filterNation.toLowerCase().trim()) > -1
        );
      }
      return players;
    }
  },
}
</script>
