<template>
    <div id="game">
        <iframe :src="statisticsSrc"
                id="myIframe"
                v-if="gameData">
        </iframe>
    </div>
</template>

<script>
    import router from './../../utils/router'
    import GameDataAccessor from "./../../storage/game-accessor.jsx";
    import serverURL from './../../utils/serverURL'

    export default {
      // eslint-disable-next-line vue/multi-word-component-names
        name: "Game",
        data: function () {
            return {
                gameData: null,
                statisticsSrc: null,
                is_corodva: !!window.cordova,
            }
        },
        mounted: function () {
            window.addEventListener("message", this.playVideoScenes, false);
            this.getGame();
            if (!window.cordova) {
                screen.orientation.lock('landscape');
            }
        },
        created() {
            let self = this;
            this.$vueEventBus.$on('custom-update', function () {
                self.getGame();
            })
        },
        beforeDestroy() {
            this.$vueEventBus.$off('custom-update')
        },
        destroyed: function () {
            if (!window.cordova) {
                screen.orientation.unlock();
            }
        },
        watch: {
            gameData: {
                handler(newGameData) {
                    // isPersistent
                    if (this.$store.state.downloaded_games.indexOf(parseInt(newGameData.id)) >= 0) {
                        let pfa = new GameDataAccessor(newGameData.id);
                        return pfa.updateGame(newGameData);
                    }
                },
                deep: true
            },
        },
        methods: {
            getGame: function () {
                let self = this;
                this.$api.getGameDetail({
                    segments: {id: parseInt(self.$route.params.id)}
                }).then(response => {
                    if (response.status === 200) {
                        self.gameData = response.data;
                        self.statisticsSrc = serverURL + "storage/game-imported/badminton/" + self.$route.params.id + "/statistics.htm";
                    } else if (self.is_corodva && !self.gameData) {
                        let gfa = new GameDataAccessor(self.$route.params.id);
                        self.statisticsSrc = gfa.getStatisticsSrc();
                        gfa.getGame().then(function (data) {
                            self.gameData = data;
                        });
                    }
                });
            },
            playVideoScenes: function (event) {
                if (event.data.type === "scenes") {
                    // router.replace("/game/video/" + event.data.data)
                    // This fixes back-button bug for firefox
                    router.push("/game/video/" + event.data.data)
                }
            },
        }
    }
</script>

<style lang="scss">
    @import '../../assets/sass/game';
</style>