<template>
  <div class="dashboard">
    <div class="grid-container">
      <div class="grid-item">
        <FilterTable v-if="loaded" :title="'Result'" :game-data="gameTableData"
                     @updateFilter="handleTableClick($event,'Game')"
                     @updateMultipleFilters="handleTableClickMultiple($event,'Game')"
        ></FilterTable>
      </div>
      <div class="grid-item">
        <FilterTable v-if="loaded" :title="'Filtered'" :game-data="gameChartData"></FilterTable>
      </div>
      <div v-if="loaded && winChartData.length" class="grid-item">
        <WinWithTable :title="'Win with'" :win-data="winChartData"
                      @updateFilter="handleTableClick($event,'Win with')"></WinWithTable>
      </div>
      <div v-if="loaded && rallyTimelineChartData.length"
           class="grid-item wide">
        <h2>Rally Timeline</h2>
        <div class="chart-card">
          <VChartBar :type="'grouped'"
                     :data="rallyTimelineChartData"
                     :y-min="chartOptions.yMin"
                     :bar-width="chartOptions.barWidth"
                     :bar-padding-outer="chartOptions.barPaddingOuter"
                     :bar-padding-inner="chartOptions.barPaddingInner"
                     :bar-colors="chartOptions.barColors"
                     v-on:on-bar-click="handleBarClick($event,'Rally Timeline')"></VChartBar>
        </div>
      </div>
      <div v-if="loaded && pointShotsChartData.length"
           class="grid-item">
        <h2>Point Shots</h2>
        <div class="chart-card">
          <VChartBar :type="'grouped'"
                     :data="pointShotsChartData"
                     :y-min="chartOptions.yMin"
                     :bar-width="[28,28]"
                     :bar-padding-outer="chartOptions.barPaddingOuter"
                     :bar-padding-inner="chartOptions.barPaddingInner"
                     :bar-colors="chartOptions.barColors"
                     v-on:on-bar-click="handleBarClick($event,'Point Shots')"></VChartBar>
        </div>
      </div>
      <div v-if="loaded && rallyFinishData.length"
           v-show="dashboardStyle === 'detail'"
           class="grid-item wide">
        <h2>Rally Finish</h2>
        <div class="chart-card">
          <VChartBar :type="'grouped'"
                     :data="rallyFinishData"
                     :y-min="chartOptions.yMin"
                     :bar-width="[28,28]"
                     :bar-padding-outer="chartOptions.barPaddingOuter"
                     :bar-padding-inner="chartOptions.barPaddingInner"
                     :bar-colors="chartOptions.barColors"
                     v-on:on-bar-click="handleBarClick($event,'Rally Finish')"></VChartBar>
        </div>
      </div>
      <div v-if="loaded && firstInitiativeChartData.length"
           v-show="dashboardStyle === 'detail'"
           class="grid-item">
        <h2>First Initiative</h2>
        <div class="chart-card">
          <VChartBar :type="'stacked'"
                     :data="firstInitiativeChartData"
                     :y-min="chartOptions.yMin"
                     :tooltip-color="'#12239E'"
                     :bar-width="chartOptions.barWidth"
                     :bar-padding-outer="chartOptions.barPaddingOuter"
                     :bar-padding-inner="chartOptions.barPaddingInner"
                     :bar-colors="chartOptions.barColors"
                     v-on:on-bar-click="handleBarClick($event,'First Initiative Player')"></VChartBar>
        </div>
      </div>
      <div v-if="loaded && rallyDescChartData.length"
           v-show="dashboardStyle === 'detail'"
           class="grid-item">
        <h2>Rally Description</h2>
        <div class="chart-card">
          <VChartBar :type="'grouped'"
                     :data="rallyDescChartData"
                     :y-min="chartOptions.yMin"
                     :bar-width="[28,28]"
                     :bar-padding-outer="chartOptions.barPaddingOuter"
                     :bar-padding-inner="chartOptions.barPaddingInner"
                     :bar-colors="chartOptions.barColors"
                     v-on:on-bar-click="handleBarClick($event,'Rally Description')"></VChartBar>
        </div>
      </div>
      <div v-if="loaded && fieldPositionChartData.length"
           class="grid-item wide">
        <h2>Field Position (Service Placement)</h2>
        <div class="chart-card">
          <VChartBar :type="'grouped'"
                     :data="fieldPositionChartData"
                     :y-min="chartOptions.yMin"
                     :bar-width="chartOptions.barWidth"
                     :bar-padding-outer="chartOptions.barPaddingOuter"
                     :bar-padding-inner="chartOptions.barPaddingInner"
                     :bar-colors="chartOptions.barColors"
                     v-on:on-bar-click="handleBarClick($event,'Field Position')"></VChartBar>
        </div>
      </div>
      <div v-if="loaded && cutOutsChartData.length"
           class="grid-item">
        <h2>Cut Outs</h2>
        <div class="chart-card">
          <VChartBar :type="'stacked'"
                     :data="cutOutsChartData"
                     :y-min="chartOptions.yMin"
                     :tooltip-color="'#12239E'"
                     :bar-width="chartOptions.barWidth"
                     :bar-padding-outer="chartOptions.barPaddingOuter"
                     :bar-padding-inner="chartOptions.barPaddingInner"
                     :bar-colors="chartOptions.barColors"
                     v-on:on-bar-click="handleCutOutClick($event)"></VChartBar>
        </div>
      </div>
      <div class="grid-item tall">
        <tag-filter-container
            :categories="tagOptions.filter(item => item.title !== 'Custom')"
            :update-tags-in-store="true"
            :tag-counts="existingTagsCount"
            @selectedTagsUpdated="updateTags"
        ></tag-filter-container>
      </div>
      <!--      For now this is removed-->
      <!--      <div class="grid-item court">-->
      <!--        <h2>Court</h2>-->
      <!--        <Court></Court>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import TagFilterContainer from "@/components/Scenes/TagFilterContainer.vue";
import {VChartBar} from "@singularit/vue2-charts";
import FilterTable from "@/components/Analysis/FilterTable.vue";
import WinWithTable from "@/components/Analysis/WinWithTable.vue";

export default {
  name: "DashboardDouble",
  components: {TagFilterContainer, VChartBar, FilterTable, WinWithTable},
  props: {
    videoData: {
      absolute_path: null,
      created_at: null,
      deleted_at: null,
      id: null,
      label: null,
      path: null,
      sport: null,
      updated_at: null,
      video_overlays: null,
      videoable: null,
      videoable_id: null,
      videoable_type: null,
    },
    tagOptions: {
      type: Array,
      default: () => []
    },
    dashboardStyle: {
      type: String,
      default: 'basic'
    }
  },
  data: function () {
    return {
      selectedTags: [],
      loaded: false,
      gameTableData: null,
      gameChartData: null,
      winChartData: null,
      firstInitiativeChartData: null,
      rallyDescChartData: null,
      rallyFinishData: null,
      pointShotsChartData: null,
      rallyTimelineChartData: null,
      fieldPositionChartData: null,
      cutOutsChartData: null,
      chartOptions: {
        barWidth: [32, 32],
        barPaddingOuter: 10,
        barPaddingInner: 3,
        barColors: ['#118DFF', '#12239E'],
        yMin: 4
      },
    }
  },
  mounted() {
    this.setData()
  },
  computed: {
    filteredScenes() {
      if (this.$store.state.selectedTags.length) {
        let foundScenes = []
        // Group selected tags by category to get the some filter (as in A or B)
        let groupedTags = this.groupBy(this.$store.state.selectedTags, 'category_id')
        Object.values(groupedTags).forEach((tags) => {
          let filter = this.videoData.scenes.filter((scene) => {
            const sceneTags = scene.tags.map((tag) => tag.id)
            const selectedTagIds = tags.map((tag) => tag.id)
            return selectedTagIds.some((tag) => sceneTags.includes(tag))
          })
          foundScenes.push(filter)
        })
        // Reduce all found scenes to only those that exist in all the some filters ((A or B) and +)
        return foundScenes.reduce((a, b) => a.filter(c => b.includes(c)))
      }
      return this.videoData.scenes
    },
    existingTagsCount() {
      if (!this.filteredScenes)
        return []
      let tags = []
      this.filteredScenes.forEach((scene) => {
        tags.push(...scene.tags)
      })
      let groupedTags = this.groupBy(tags, 'id')
      Object.keys(groupedTags).forEach(key => groupedTags[key] = groupedTags[key].length)
      return groupedTags
    },
  },
  methods: {
    updateTags: function (tag) {
      const index = this.selectedTags.indexOf(tag);
      if (index > -1) { // only splice array when item is found
        while (this.selectedTags.indexOf(tag) > -1) {
          this.selectedTags.splice(this.selectedTags.indexOf(tag), 1);
        }
      } else {
        this.selectedTags.push(tag)
      }
      this.setData()
    },
    groupBy(array, key) {
      const result = {}
      array.forEach(item => {
        if (!result[item[key]]) {
          result[item[key]] = []
        }
        result[item[key]].push(item)
      })
      return result
    },
    setData() {
      this.gameChartData = this.createBarChartData('Game')
      this.gameTableData = this.createBarChartData('Game')
      this.winChartData = this.createBarChartData('Win with')
      this.firstInitiativeChartData = this.createStackedBarChartData('First Initiative Player')
      this.rallyDescChartData = this.createBarChartData('Rally Description')
      this.rallyFinishData = this.createBarChartData('Rally Finish')
      this.pointShotsChartData = this.createBarChartData('Point Shots')
      this.rallyTimelineChartData = this.createBarChartData('Rally Timeline')
      this.fieldPositionChartData = this.createBarChartData('Field Position')
      this.cutOutsChartData = this.createCutOutData()
      this.loaded = true
    },
    handleTableClick(cellValue, filterId) {
      let tagCategory = this.tagOptions.filter(item => item.title !== 'Custom').find(item => item.title === filterId)
      let tag = tagCategory.tags.find(item => item.title === cellValue.game)
      if (!cellValue.ctrlKey) {
        const index = this.$store.state.selectedTags.indexOf(tag);
        if (index > -1) { // only splice array when item is found
          this.$store.commit('removeTag', tag)
          if(cellValue.team) this.removeFilterByTeamTag(cellValue.team)
        } else {
          this.$store.commit('resetTags')
          this.$store.commit('addTag', tag)
          this.filterByTeam(cellValue.team)
        }
      } else {
        const index = this.$store.state.selectedTags.indexOf(tag);
        if (index > -1) { // tag is found
          if (!this.filterByTeam(cellValue.team)) {
            this.$store.commit('removeTag', tag)
          }
        } else {
          this.$store.commit('addTag', tag)
          this.filterByTeam(cellValue.team)
        }
      }
      this.updateTags(tag)
    },
    handleTableClickMultiple(cellValue) {
      let tagCategory = null
      let tag = null
      let sumTags = []
      if(cellValue.value === 'teamSum') {
        tagCategory = this.tagOptions.filter(item => item.title !== 'Custom').find(item => item.title === 'Point')
        tag = tagCategory.tags.find(item => item.title === cellValue.team)
        sumTags = this.tagOptions.filter(item => item.title !== 'Custom').find(item => item.title === 'Game').tags
      } else {
        tagCategory = this.tagOptions.filter(item => item.title !== 'Custom').find(item => item.title === 'Game')
        tag = tagCategory.tags.find(item => item.title === cellValue.game)
        sumTags = this.tagOptions.filter(item => item.title !== 'Custom').find(item => item.title === 'Point').tags
      }
      if (!cellValue.ctrlKey) {
        const index = this.$store.state.selectedTags.indexOf(tag);
        if (index > -1) { // only splice array when item is found
          this.$store.commit('resetTags')
        } else {
          this.$store.commit('resetTags')
          this.$store.commit('addTag', tag)
        }
        sumTags.forEach((tag) => {
          this.$store.commit('addTag', tag)
        })
      }
      this.updateTags(tag)
    },
    handleBarClick(barValue, chartTitle) {
      let tagCategory = this.tagOptions.filter(item => item.title !== 'Custom').find(item => item.title === chartTitle)
      let tag = tagCategory.tags.find(item => item.title === barValue.group)
      // select single tags or multiple tags with ctrlKey
      if (!barValue.ctrlKey) {
        const index = this.$store.state.selectedTags.indexOf(tag);
        if (index > -1) { // only splice array when item is found
          this.$store.commit('removeTag', tag)
          if(barValue.key) this.removeFilterByTeamTag(barValue.key)
        } else {
          this.$store.commit('resetTags')
          this.$store.commit('addTag', tag)
          if(barValue.key) this.filterByTeam(barValue.key)
        }
      } else {
        const index = this.$store.state.selectedTags.indexOf(tag);
        if (index > -1) { // only splice array when item is found
          this.$store.commit('removeTag', tag)
        } else {
          this.$store.commit('addTag', tag)
        }
        if(barValue.key) this.filterByTeam(barValue.key)
      }
      this.updateTags(tag)
    },
    handleCutOutClick(barValue) {
      let server1 = this.tagOptions.filter(item => item.title !== 'Custom').find(item => item.title === 'Team 1 Server')
      let server2 = this.tagOptions.filter(item => item.title !== 'Custom').find(item => item.title === 'Team 2 Server')
      let cutOut = this.tagOptions.filter(item => item.title !== 'Custom').find(item => item.title === '#3')

      let tag = server1.tags.find(item => item.title === barValue.group) || server2.tags.find(item => item.title === barValue.group)
      let cutOutTag = cutOut.tags.find(item => item.title === 'Cut out')

      if (!barValue.ctrlKey) {
        const index = this.$store.state.selectedTags.indexOf(tag);
        if (index > -1) { // only splice array when item is found
          this.$store.commit('removeTag', tag)
          this.$store.commit('removeTag', cutOutTag)
          if(barValue.key) this.removeFilterByTeamTag(barValue.key)
        } else {
          this.$store.commit('resetTags')
          this.$store.commit('addTag', tag)
          this.$store.commit('addTag', cutOutTag)
          if(barValue.key) this.filterByTeam(barValue.key)
        }
      } else {
        const index = this.$store.state.selectedTags.indexOf(tag);
        if (index > -1) { // only splice array when item is found
          this.$store.commit('removeTag', tag)
          this.$store.commit('removeTag', cutOutTag)
        } else {
          this.$store.commit('addTag', tag)
          this.$store.commit('addTag', cutOutTag)
        }
        if(barValue.key) this.filterByTeam(barValue.key)
      }
      this.updateTags(tag)
    },
    getTeamTag(team) {
      let tagCategory = this.tagOptions.filter(item => item.title !== 'Custom').find(item => item.title === 'Point')
      return tagCategory.tags.find(item => item.title === team)
    },
    filterByTeam(team) {
      const tag = this.getTeamTag(team)
      const index = this.$store.state.selectedTags.indexOf(tag);
      if (index === -1) { // only add tag not existing tags
        this.$store.commit('addTag', tag)
        this.updateTags(tag)
        return true
      }
      return false
    },
    removeFilterByTeamTag(team) {
      const tag = this.getTeamTag(team)
      const index = this.$store.state.selectedTags.indexOf(tag);
      if (index > -1) { // only add tag not existing tags
        this.$store.commit('removeTag', tag)
        this.updateTags(tag)
        return true
      }
      return false
    },
    createBarChartData(filterOption) {
      if (!this.tagOptions.find(tagOption => tagOption.title === filterOption))
        return []
      let gameTags = this.tagOptions.find(tagOption => tagOption.title === filterOption).tags;
      let pointTags = this.tagOptions.find(tagOption => tagOption.title === 'Point').tags;

      if(filterOption === 'Field Position') {
        const tagOrder = ['1', '1.5', '2', '3', '4', 'Flick']
        // eslint-disable-next-line vue/no-mutating-props
        gameTags.sort((a, b) => {
          return tagOrder.indexOf(a.title) - tagOrder.indexOf(b.title);
        })
      }

      if (filterOption === 'Rally Description') {
        const tagOrder = ['1-4', '1-4+indo', '1 Attacker', '2 Attackers']
        // eslint-disable-next-line vue/no-mutating-props
        gameTags.sort((a, b) => {
          return tagOrder.indexOf(a.title) - tagOrder.indexOf(b.title);
        })
      }
      // Initialize an array to store the results
      let results = [];

      // Loop over each point
      for (let pointTag of pointTags) {
        // Initialize an array to store the counts of scenes per game
        let counts = [];

        // Loop over each game
        for (let gameTag of gameTags) {
          // Filter the scenes by the current game
          let gameScenes = this.filterScenes(gameTag, this.filteredScenes);

          // Filter the game scenes by the current point
          let pointScenes = this.filterScenes(pointTag, gameScenes);

          // Push the count of scenes to the counts array
          counts.push(pointScenes.length);
        }

        // Push an object to the results array containing the title of the point and the counts of scenes
        results.push({
          data: counts,
          label: pointTag.title,
        });
      }

      let chartData = []
      gameTags.forEach((tag, idx) => {
        chartData.push({
          label: tag.title,
          values: {
            [results[0].label]: results[0].data[idx],
            [results[1].label]: results[1].data[idx],
          },
        })
      })

      return chartData
    },
    createStackedBarChartData(filterOption) {
      if (!this.tagOptions.find(tagOption => tagOption.title === filterOption))
        return []
      let gameTags = this.tagOptions.find(tagOption => tagOption.title === filterOption).tags;
      let pointTags = this.tagOptions.find(tagOption => tagOption.title === 'Point').tags;

      // Initialize an array to store the results
      let results = [];

      // Loop over each point
      for (let pointTag of pointTags) {
        // Initialize an array to store the counts of scenes per game
        let counts = [];

        // Loop over each game
        for (let gameTag of gameTags) {
          // Filter the scenes by the current game
          let gameScenes = this.filterScenes(gameTag, this.filteredScenes);

          if(filterOption === 'First Initiative Player') {
            // Sort game scenes by the first initiative team
            gameScenes = gameScenes.sort((a, b) => {
              return a.tags.find(tag => tag.category.title === 'First Initiative Team').title.localeCompare(b.tags.find(tag => tag.category.title === 'First Initiative Team').title)
            })
          }

          // Filter the game scenes by the current point
          let pointScenes = this.filterScenes(pointTag, gameScenes);

          // Push the count of scenes to the counts array
          counts.push(pointScenes.length);
        }

        // Push an object to the results array containing the title of the point and the counts of scenes
        results.push({
          data: counts,
          label: pointTag.title,
        });
      }

      let chartData = []
      gameTags.forEach((tag, idx) => {
        chartData.push({
          label: tag.title,
          tooltip: results[0].data[idx] + results[1].data[idx],
          values: {
            [results[0].label]: results[0].data[idx],
            [results[1].label]: results[1].data[idx],
          },
        })
      })

      return chartData
    },
    createCutOutData() {
      let gameTags = this.tagOptions.filter((tagOption) => {
        return tagOption.title === 'Team 1 Server' || tagOption.title === 'Team 2 Server'
      }).map(tagOption => tagOption.tags).flat();
      let pointTags = this.tagOptions.find(tagOption => tagOption.title === 'Point').tags;

      // Initialize an array to store the results
      let results = [];

      // Loop over each point
      for (let pointTag of pointTags) {
        // Initialize an array to store the counts of scenes per game
        let counts = [];

        // Loop over each game
        for (let gameTag of gameTags) {
          // Filter the scenes by the current game
          let gameScenes = this.filterScenes(gameTag, this.filteredScenes);

          // Filter the game scenes by the current point
          let pointScenes = this.filterScenes(pointTag, gameScenes);

          pointScenes = pointScenes.filter(scene => {
            return scene.tags.find(tag => tag.category.title === '#3')
          })

          // Push the count of scenes to the counts array
          counts.push(pointScenes.length);
        }

        // Push an object to the results array containing the title of the point and the counts of scenes
        results.push({
          data: counts,
          label: pointTag.title,
        });
      }

      let chartData = []
      gameTags.forEach((tag, idx) => {
        chartData.push({
          label: tag.title,
          tooltip: results[0].data[idx] + results[1].data[idx],
          values: {
            [results[0].label]: results[0].data[idx],
            [results[1].label]: results[1].data[idx],
          },
        })
      })

      return chartData
    },
    filterScenes(tag, scenes) {
      // Reduce all found scenes to only those that exist in all the some filters ((A or B) and +)
      return scenes.filter((scene) => {
        const sceneTags = scene.tags.map((tag) => tag.id)
        return sceneTags.includes(tag.id)
      })
    },
  }
}
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, minmax(300px, 1fr));
  grid-gap: 20px;
  padding: 0 20px 20px 20px;
}

.grid-item {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #f8f9fa;
}

.chart-card {
  height: 190px;
  margin-bottom: 24px;
}

.tall {
  grid-row: 1 / 4;
  grid-column: 4 / 4;
}

.wide {
  grid-column: span 2;
}

.court {
  grid-column: span 4;
  grid-row: span 4;
}
</style>
