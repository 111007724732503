<template>
    <div class="card">
        <div class="card-header">Impressum</div>
        <div class="card-body">
            <div class="row">
                <img src="../assets/img/IAT_Logo_RGB.png" alt="iat_logo" class="img-responsive w-25 ml-auto mr-5">
            </div>
            <h5>Herausgeber:</h5>
            <p>
                Institut für Angewandte Trainingswissenschaft (IAT) <br>
                Ein Institut im Verein IAT/FES e. V. <br>
                <br>
                Marschnerstr. 29 <br>
                04109 Leipzig <br>
                <br>
                Tel.: 0341 4945 300 <br>
                Fax: 0341 4945 400 <br>
                E-Mail: iat@iat.uni-leipzig.de <br>
            </p>
            <h5>Gerichtsort:</h5>
            <p>Amtsgericht Leipzig</p>
            <h5>Vereinsregisternummer:</h5>
            <p>VR 1227</p>
            <h5>Umsatzsteueridentifikations-Nummer:</h5>
            <p>DE 141510029</p>
            <h5>Vertretungsberechtigte:</h5>
            <p>
                Prof. Dr. Martin Engelhardt <br>
                Vorsitzender des Vereins IAT/FES e. V. <br>
                Dr. Marc-Oliver Löw <br>
                IAT-Direktor und Mitglied des Vorstands des Vereins IAT/FES e. V. <br>
            </p>
            <h5>Inhaltlich Verantwortliche:</h5>
            <p>
                Fachbereich Technik-Taktik <br>
                Fachbereichsleitung: Dr. Francisco José Vizcaya
            </p>
            <h5>Technische Umsetzung:</h5>
            <p>singularIT GmbH</p>
            <h5>Design</h5>
            <p>Laura Rarek</p>
        </div>
    </div>
</template>

<script>
    export default {
      // eslint-disable-next-line vue/multi-word-component-names
        name: "Impressum"
    }
</script>

<style scoped>

</style>
