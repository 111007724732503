<template>
    <div class="card">
        <div class="card-body">
            <annotations-container
                    :annotatable-id="player.id ? player.id : null"
                    :annotations="player.annotations"
                    :annotatable-object="player"
                    :annotation-type="'App\\Player'"
            ></annotations-container>
        </div>
    </div>
</template>

<script>
    import AnnotationsContainer from "../Annotation/AnnotationsContainer"

    export default {
        name: 'PlayerNotes',
        components: {AnnotationsContainer},
        props: ['player']
    }
</script>