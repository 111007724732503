<template>
    <div class="card">
        <div class="card-header">Nutzer verwalten</div>

        <vue-bootstrap-table
                :columns="columns"
                :values="values"
                :show-filter="true"
                :show-column-picker="true"
                :sortable="true"
                :paginated="true"
                :multi-column-sortable=true
                :filter-case-sensitive=false
                class="m-3"
        >
            <template v-slot:name="slotProps">
                {{slotProps.value.name}}
            </template>
            <template v-slot:description="slotProps">
                {{slotProps.value.description}}
            </template>

        </vue-bootstrap-table>

        <button v-if="selectedUser.length" @click="deleteUsers()" class="btn btn-danger float-right" type="button">
            Löschen
        </button>
    </div>
</template>

<script>
    import toast from '../utils/toast';
    import VueBootstrapTable from "vue2-bootstrap-table2";

    export default {
        name: 'UserRegistration',
        props: ['player', 'playerProposal'],
        components: {
            VueBootstrapTable: VueBootstrapTable,
        },
        data: function () {
            return {
                newUserData: null,
                selectedUser: [],
                selectedUserCount: 0,
                renewUserValues: false,
                userList: [],
                columns: [
                    {
                        title: "id",
                    },
                    {
                        title: "name",
                        visible: true,
                        editable: true,
                    },
                    {
                        title: "email",
                        visible: true,
                        editable: true,
                    },
                    {
                        title: "passwort",
                        visible: true,
                        editable: true,
                        sortable: false
                    },
                    {
                        title: "role",
                        visible: true,
                        editable: true,
                        sortable: true
                    },
                    {
                        title: "sport",
                        visible: true,
                        editable: true,
                        sortable: true
                    }
                ],
                values: [],
                rowClickHandler: {
                    type: Function,
                    required: true,
                },
            };
        },
        watch: {
            values: {

                handler: function (newValues) {

                    let self = this;
                    this.selectedUser = newValues.filter(newValues => newValues.selected === true);

                    if (newValues.length === this.userList.length &&
                        this.selectedUser.length === this.selectedUserCount &&
                        this.$store.state.networkActiveTasks === 0 &&
                        !this.renewUserValues
                    ) {
                        for (let i = 0; i < this.userList.length; i++) {
                            let changedValue = newValues.find(valNew =>
                                valNew.id === this.userList[i].id &&
                                (
                                    valNew.email !== this.userList[i].email ||
                                    valNew.name !== this.userList[i].name ||
                                    valNew.passwort !== this.userList[i].passwort ||
                                    valNew.sport !== this.userList[i].sport ||
                                    valNew.role !== this.userList[i].role
                                )
                            );
                            if (changedValue !== undefined) {
                                self.updateUser(changedValue, this.userList[i]);
                                return true;
                            }
                        }
                    }
                    this.setNewValues(newValues)
                },
                deep: true
            }
        },
        beforeMount() {
            this.setUserDataEmpty()
        },
        mounted: function () {
            this.getUserList();

        },
        methods: {
            getUserList: function () {
                this.$api.getUserList({}).then(response => {
                    response.data.forEach(function (user, index) {
                        user['passwort'] = "****";

                        response.data[index] = user;
                    });
                    this.values = response.data;
                });
                this.renewUserValues = false;
            },
            setNewValues: function (newValues) {
                let self = this;
                this.userList = [];
                newValues.forEach(function (user) {
                    self.userList.push({
                        'id': user.id,
                        'name': user.name,
                        'email': user.email,
                        'sport': user.sport,
                        'passwort': '****',
                        'role': user.role,
                    })
                });
                this.selectedUserCount = this.selectedUser.length;
            },
            setUserDataEmpty: function () {
                this.newUserData = {
                    id: null,
                    name: null,
                    email: null,
                    role: null,
                    sport: null,
                };
            },
            createUser: function () {
                for (let i = 0; i < this.values.length; i++) {
                    if (this.values[i].email === this.newUserData.email) {
                        toast.error("Email wird bereits verwendet. Bitte andere Email verwenden.");
                        document.getElementById("inputEmail").focus();
                        return false;
                    }
                }

                this.$api.createUser({
                    data: {
                        name: this.newUserData.name,
                        email: this.newUserData.email,
                        password: this.newUserData.password,
                        role: this.newUserData.role,
                        sport: this.newUserData.sport,
                    }
                }).then(response => {
                        response.data['passwort'] = '****';
                        this.values.push(response.data);
                        this.setUserDataEmpty();
                    },
                );
            },
            deleteUsers: function () {
                this.$api.deleteMultipleUser({
                    data: {
                        users: this.selectedUser,
                    }
                }).then(response => {
                        this.values = this.values.filter(value => !response.data.includes(value.id));
                    },
                );
            },
            updateUser: function (changedValue, valueOld) {
                let self = this;

                if (this.$store.state.networkActiveTasks > 0) {
                    changedValue = valueOld;
                    toast.error('Bitte laufenden Request abwarten');
                    return false;
                }

                let data = {
                    _method: 'patch',
                    name: changedValue.name,
                    email: changedValue.email,
                };

                if (changedValue.sport !== valueOld.sport) {
                    switch (changedValue.sport.charAt(0)) {
                        case 'B':
                        case 'b':
                            data['sport'] = 'badminton';
                            break;
                        case 'T':
                        case 't':
                            data['sport'] = 'table tennis';
                            break;
                        default:
                            changedValue.sport = valueOld.sport;
                            toast.error('Bitte Nutzer-Sport korrekt eingeben (badminton, table tennis)')
                            return false;
                    }
                }

                if (changedValue.role !== valueOld.role) {
                    switch (changedValue.role.charAt(0)) {

                        case 'P':
                        case 'p':
                            data['role'] = 'player';
                            break;
                        case 'T':
                        case 't':
                            data['role'] = 'trainer';
                            break;
                        case 'A':
                        case 'a':
                            data['role'] = 'admin';
                            break;
                        default:
                            changedValue.role = valueOld.role;
                            toast.error('Bitte Nutzer-Rolle korrekt eingeben (player, trainer, admin)');
                            return false;
                    }
                }

                if (changedValue.passwort !== valueOld.passwort) {
                    data['passwort'] = changedValue.passwort;
                    changedValue.passwort = '****'
                }

                this.$api.updateUser({
                    segments: {user: changedValue.id},
                    data: data
                }).then(function () {
                    self.selectedUserCount++;
                    self.getUserList()

                });

            }
        }
    }
</script>
