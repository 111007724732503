import FileAccessor from "./file-accessor";
import {cordovaUtils} from "../utils/cordovaUtils";

export default class AnnotationDataAccessor {

    constructor(annotation_id) {
        this.dataAccessor = new FileAccessor(annotation_id, 'AnnotationData');
        this.fileAccessor = new FileAccessor(annotation_id, 'AnnotationFiles');
    }

    saveAnnotation(Annotation) {
        let self = this;
        return this.dataAccessor.saveJSONData(Annotation).then(function () {
            return self.fileAccessor.saveLargeFile(Annotation.absolute_path).then( function () {
                    return cordovaUtils.downloadFile(Annotation.absolute_path, Annotation.text + '.' + Annotation.file_path.split('.').pop(), Annotation)
            });
        });
    }

    getAnnotation() {
        return this.dataAccessor.readJSONData();
    }

    getAnnotationFile() {
        return this.fileAccessor.getFileURL();
    }

    removeAnnotation(Annotation) {
        let self = this;
        return this.fileAccessor.removeFile().then(function () {
            return self.dataAccessor.removeFile().then(function () {
                return cordovaUtils.rmDownloadedFile(Annotation.absolute_path, Annotation.text + '.' + Annotation.file_path.split('.').pop())
            })
        })
    }

}