<template>
    <div class="card">
        <div class="card-header">Startseite</div>
        <div class="card-body">
            Bitte wählen Sie eine Aktion im Menü aus.
        </div>
    </div>
</template>

<script>
    export default {
      // eslint-disable-next-line vue/multi-word-component-names
        name: 'Home',
    }
</script>