<template>
  <div id="video-upload">
    <div class="container">
      <div class="row">
        <div class="close-btn">
          <button @click="close">
            ❌
          </button>
        </div>
        <label class="file-label col-8">Video {{ (objectTitle ? 'für ' + objectTitle : null) }} hinzufügen</label>
        <div class="col-12 mb-4" @drop.prevent="addDroppedFile" @dragover.prevent>
          <input type="file" multiple v-if="!files.length" class="form-control mt-2" id="file" v-on:change="addFile"
                 accept="video/*"/>
          <div class="form-control py-5">
            <label for="file"><strong>Drag & Drop</strong></label>
          </div>
          <ul class="list-group">
            <li v-for="file in files" v-bind:key="file.name" class="list-group-item">
              {{ file.name }}
              <button @click="removeFile(file)" class="btn skill-btn float-right ml-1">
                <i class="fas fa-trash-alt"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <label class="file-label col-8">Dartclip Datei {{ (objectTitle ? 'für ' + objectTitle : null) }} importieren</label>
        <div class="col-12 mb-4" @drop.prevent="addDroppedFile" @dragover.prevent>
          <input v-if="!dartclip"
                 v-on:change="addDartClipFile"
                 class="form-control"
                 id="dartclip-upload" type="file" ref="dartclip" accept=".dartclip"/>
          <div v-if="!dartclip" class="form-control py-5">
            <label for="file"><strong>Drag & Drop</strong></label>
          </div>
          <ul class="list-group" v-if="dartclip">
            <li class="list-group-item">
              {{ dartclip.name }}
              <button @click="dartclip = null" class="btn skill-btn float-right ml-1">
                <i class="fas fa-trash-alt"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <button class="btn btn-primary upload-btn" v-on:click="submitFile()">Upload</button>
        <progress v-if="($store.state.networkActiveTasks > 0)" style="width: 80%" max="100"
                  :value.prop="$store.state.uploadPercentage"></progress>
      </div>
    </div>
  </div>
</template>

<script>
import toast from '../../utils/toast';

export default {
  name: "VideoUpload",
  props: ['objectType', 'objectID', 'objectTitle'],
  data: function () {
    return {
      isUploading: false,
      closeAfterUpload: false,
      videoTitle: '',
      files: [],
      dartclip: null
    }
  },
  methods: {
    submitFile() {

      if (!this.files.length) {
        toast.error('Bitte Video auswählen', 'Unvollständig')
        return 0;
      }

      if (this.isUploading) {
        toast.error("Bitte warten ...")
        return 0;
      }

      let self = this;
      this.isUploading = true;
      let i = 0;
      this.files.forEach((file) => {
        let formData = new FormData();
        formData.append('file', file)
        this.$api.uploadVideo({
          data: formData
        }).then(response => {
          if (response.data) {
            this.$api.storeVideo({
              data: {
                videoable_id: this.objectID,
                videoable_type: this.objectType,
                label: file.name.replace(/\.[^/.]+$/, ""),
                path: response.data
              }
            }).then(response => {
              if(this.dartclip) {
                this.importScenes(response.data.id)
              } else {
                i += 1;
                if (i === self.files.length) {
                  self.isUploading = false;
                  self.close();
                  this.updateParent(response.data);
                }
              }
            });
          } else {
            toast.error('Failed to upload Video')
          }
        });
      });
    },
    importScenes(videoId) {
      let formData = new FormData()
      formData.append('file', this.dartclip)

      this.$api.uploadScenes({
        segments: {
          vId: videoId,
        },
        data: formData,
      }).then(() => {
        this.$api.uploadFile({
          data: formData
        }).then(response => {
          this.$api.storeFile({
            data: {
              fileable_id: videoId,
              fileable_type: "App\\Video",
              label: this.dartclip.name.replace(/\.[^/.]+$/, ""),
              type: 'dartclip',
              path: response.data.replace("public", "storage")
            }
          }).then((res) => {
            this.isUploading = false;
            this.updateParent(res.data);
            this.close();
          })
        })
      })
    },
    updateParent(video) {
      this.$emit('updateParent', this.objectID, video);
    },
    close() {
      if (this.isUploading) {
        toast.error("Bitte warten ...");
        this.closeAfterUpload = true;
      } else {
        this.$modal.hide('add-video' + this.objectID);
        this.$modal.hide('add-video');
      }

    },
    addDroppedFile(e) {
      let droppedFiles = e.dataTransfer.files;
      ([...droppedFiles]).forEach(f => {
        if (f['type'].includes('video')) {
          this.files.push(f);
        } else if (f['name'].includes('.dartclip')) {
          this.dartclip = f
        }
      });
    },
    addFile(e) {
      let files = e.target.files;
      if (!files) return;
      ([...files]).forEach(f => {
        this.files.push(f);
      })
    },
    addDartClipFile(e) {
      let files = e.target.files;
      if (!files) return;
      this.dartclip = files[0]
    },
    removeFile(file) {
      this.files = this.files.filter(f => {
        return f !== file;
      });
    },
  }
}
</script>

<style>
#video-upload {
  padding: 1rem;
}

#video-upload .container .row {
  padding: 0.5rem;
}

.file-label {
  /*margin-top: 1rem;*/
}

.upload-btn {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.file-input {
  /*position: absolute;*/
  /*bottom: 1rem;*/
  /*left: 1rem;*/
}
</style>