<template>
  <div class="col-12">
    <div class="col-12 mt-5">
      <div class="row mb-minus-1">
        <div class="col-6">
          <div class="list-group-item no-bottom-radius bg-light text-success h-100">Notizen
            <button v-if="$store.state.user.role_name === 'admin' || $store.state.user.role_name === 'trainer'"
                    class="add-skill-btn btn" v-on:click='showAddTraining()'>
              <i class="fas fa-plus px-1"></i>
            </button>
          </div>
        </div>

        <div class="col-6">
          <div class="list-group-item no-bottom-radius bg-light text-orange h-100">Videos</div>
        </div>
      </div>

      <div class="row mb-minus-1" v-for="skill in playerSkills" v-bind:key="skill.id">
        <div class="col-6">
          <div class="list-group-item no-top-radius no-bottom-radius h-100">
            <span style="white-space: pre-wrap">{{ skill.text }}</span>
            <div class="float-right mb-2">
              <template
                  v-if="$store.state.user.role_name === 'admin' || $store.state.user.role_name === 'trainer'">
                <button class="skill-btn btn float-right ml-1"
                        v-on:click='deleteTraining(skill)'>
                  <i class="fas fa-trash-alt"></i>
                </button>
                <button class="skill-btn btn float-right ml-1"
                        v-on:click='openEditAnnotation(skill)'>
                  <i class="fas fa-edit"></i>
                </button>
              </template>

              <a v-for="file in skill.files" v-bind:key="file.id">
                <button class="btn skill-btn float-right ml-2 mb-12" v-on:click="download(file)">
                  <i class="fas fa-file-pdf"></i>
                </button>
              </a>
            </div>

          </div>

        </div>

        <div class="col-6">

          <div class="list-group-item no-bottom-radius text-orange h-100">

            <div class="row d-inline">
              <button class="skill-btn btn float-right ml-1"
                      v-on:click='showAddTrainingVideo(skill)'>
                <i class="fas fa-upload"></i>
              </button>
              <a v-if="skill.videos.length" class="btn skill-btn float-right ml-1"
                 data-toggle="collapse" :href="'#videoCollapse' + skill.id" role="button"
                 aria-expanded="false" :aria-controls="'videoCollapse' + skill.id">
                <i class="fas fa-file-video"> {{ skill.videos.length }}</i><i
                  class="fas fa-caret-down ml-2"></i>
              </a>

            </div>

            <div class="collapse mt-4 " :id="'videoCollapse' + skill.id">

              <div v-for="video in skill.videos" v-bind:key="video.id"
                   class="list-group-item no-bottom-radius text-orange h-100"
                   style="overflow: hidden;">
                {{ video.label }}
                <button v-if="$store.state.user.role_name === 'admin' || $store.state.user.role_name === 'trainer'"
                        class="skill-btn btn float-right ml-1"
                        v-on:click='deleteVideo(video, skill)'>
                  <i class="fas fa-trash-alt"></i>
                </button>
                <button v-if="$store.state.user.role_name === 'admin' || $store.state.user.role_name === 'trainer'"
                        class="btn skill-btn float-right ml-1" v-on:click='showEditVideo(video, skill)'>
                  <i class="fas fa-edit"></i>
                </button>
                <router-link :to="'/video/' + video.id"
                             class="btn skill-btn float-right">
                  <i class="fas fa-file-video"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <modal name="add-training" :width="500" height="auto" :clickToClose="false">
        <div style="padding: 1rem">
          <div class="close-btn">
            <button @click="hide()">
              ❌
            </button>
          </div>
          <div class="form-group">
            <label for="training-input">Notiz hinzufügen</label>
            <textarea id="training-input" class="form-control mb-2" type="text" ref="propTraining"
                      placeholder="Notiz eintragen (max. 500 Zeichen)" maxlength="500"></textarea>
            <label for="training-pdf-input" class="mt-3 col-12">PDF hinterlegen</label>
            <input id="training-pdf-input" type="file" ref="file" accept="application/pdf"/>
          </div>
          <button class="btn btn-primary add-skill-submit-btn" v-on:click="addTraining()">
            Hinzufügen
          </button>
        </div>
      </modal>

      <modal name="add-video" :width="600" height="auto" :clickToClose="false" :adaptive="true">
        <video-upload objectType='App\Skill'
                      :objectID="skillToAddVideoID"
                      :objectTitle="skillToAddVideoTitle"
                      @updateParent="addVideoToSkill"
                      @close="closeAddVideo"
        ></video-upload>
      </modal>

      <modal :name="'edit-skill'" height="auto" :clickToClose="false" :adaptive="true">
        <skill-edit
            :skill="skillToEdit"
            :player="player"
            @updateParent="updateSkill"
        ></skill-edit>
      </modal>

      <modal :name="'edit-video'" height="auto" :clickToClose="false" :adaptive="true">
        <video-edit
            :video="videoEdit"
            @updateParent="updateVideo"
            @close="hide">
        </video-edit>
      </modal>
    </div>
  </div>
</template>

<script>
import VideoUpload from './../Video/VideoUpload';
import serverURL from '../../utils/serverURL';
import toast from '../../utils/toast';
import SkillEdit from "./SkillEdit";
import {cordovaUtils} from "../../utils/cordovaUtils";
import VideoEdit from "@/components/Video/VideoEdit";

let $ = require('jquery');

export default {
  name: 'PlayerTraining',
  components: {VideoEdit, VideoUpload, SkillEdit},
  props: ['player'],
  data: function () {
    return {
      serverURL: serverURL,
      skillToAddVideoID: null,
      skillToAddVideoTitle: null,
      playerSkills: [],
      skillToEdit: null,
      videoEdit: null,
    }
  },
  created: function () {
    this.playerSkills = this.player.skills.filter(skill => skill.type === 'training');
  },
  methods: {
    showAddTraining() {
      if (cordovaUtils.isOfflineCancel()) {
        return 0;
      }
      this.$modal.show('add-training', {});
    },
    showAddTrainingVideo(skill) {
      if (cordovaUtils.isOfflineCancel()) {
        return 0;
      }
      this.skillToAddVideoID = skill.id;
      this.skillToAddVideoTitle = skill.text;
      this.$modal.show('add-video', {});
    },
    addVideoToSkill(skillID, video) {
      let self = this;
      this.player.skills.forEach(function (skill, index) {
        if (skill.id === skillID) {
          self.player.skills[index].videos.push(video)
        }
      })
    },
    deleteVideo(video, skill) {
      const result = confirm("Möchten sie wirklich das Video \"" + video.label + "\" aus der Datenbank löschen?");
      if (result) {
        this.$api.destroyVideo({
          segments: {
            vid: video.id,
          }
        }).then(function () {
          let index = -1;
          for (let i = 0; i < skill.videos.length; i++) {
            if (skill.videos[i].id === video.id) {
              index = i;
              break;
            }
          }
          skill.videos.splice(index, 1);
        }.bind(this, video));
      }
    },
    hide() {
      this.$modal.hide('add-training');
      this.$modal.hide('add-video');
      this.$modal.hide('edit-video');
    },
    addTraining() {

      if (RegExp('^\\s*$').test(this.$refs.propTraining.value)) {
        toast.error('Bitte Notiztext hinterlegen', 'Unvollständig');
        return 0;
      }
      let self = this;
      /*                if (typeof this.$refs.file.files[0] === "undefined") {
                          toast.error('Bitte PDF hinterlegen', 'Unvollständig');
                          return 0;
                      }*/
      let formData = false;
      if (this.$refs.file.files[0]) {
        formData = new FormData();
        formData.append('file', this.$refs.file.files[0]);
      }

      this.$api.createPlayerSkill({
        segments: {
          id: this.player.id,
        },
        data: {
          type: 'training',
          text: this.$refs.propTraining.value,
          teammates: [],
        }
      }).then(createPlayerSkillResponse => {
            let trainingID = createPlayerSkillResponse.data.id;
            if (formData) {
              this.$api.uploadFile({
                data: formData
              }).then(response => {
                    this.$api.storeFile({
                      data: {
                        fileable_id: trainingID,
                        fileable_type: "App\\Skill",
                        label: this.$refs.propTraining.value,
                        type: 'pdf',
                        path: response.data.replace("public", "storage")
                      }
                    }).then((fileable_response) => {
                      fileable_response.data['videos'] = [];
                      fileable_response.data['players'] = [self.player];
                      // eslint-disable-next-line vue/no-mutating-props
                      this.player.skills.push(fileable_response.data);
                      this.playerSkills.push(fileable_response.data);
                      this.hide();
                    });
                  }
              );
            } else {
              createPlayerSkillResponse.data['videos'] = [];
              // eslint-disable-next-line vue/no-mutating-props
              this.player.skills.push(createPlayerSkillResponse.data);
              this.playerSkills.push(createPlayerSkillResponse.data);
              this.hide();

            }
          }
      );
    },
    deleteTraining(skill) {
      const result = confirm("Möchten sie wirklich die Notiz \"" + skill.text + "\" aus der Datenbank löschen?");
      if (result) {
        this.$api.destroySkill({
          segments: {
            pid: this.player.id,
            sid: skill.id
          }
        }).then(function () {
          let index = -1;
          for (let i = 0; i < this.player.skills.length; i++) {
            if (this.playerSkills[i].id === skill.id) {
              index = i;
              break;
            }
          }
          this.playerSkills.splice(index, 1);
          this.$emit('deleteSkill', skill)
        }.bind(this, skill));
      }
    },
    openEditAnnotation(skill) {
      this.skillToEdit = skill;
      this.$modal.show('edit-skill');
    },
    updateSkill(updatedSkill) {
      for (let i in this.player.skills) {
        if (this.player.skills[i].id === updatedSkill.id) {
          // eslint-disable-next-line vue/no-mutating-props
          this.player.skills[i].text = updatedSkill.text;
          return 0;
        }
      }
      this.$emit('updateSkill', updatedSkill)
    },
    closeAddVideo() {
      this.$modal.hide('add-video')
    },
    showEditVideo(video, skill) {
      this.videoEdit = video;
      this.skillToEdit = skill;
      this.$modal.show('edit-video');
    },
    download(file) {
      if (cordovaUtils.isCordova()) {
        cordovaUtils.viewDocument(file.label + '.' + file.path.split('.').pop(), 'application/pdf', '', '', '', '', this.onError(file));
      } else if (cordovaUtils.isOnline()) {
        $.ajax({
          type: 'GET',
          url: serverURL + 'api/file/download/' + file.id,
          headers: {
            "Authorization": "Bearer " + this.$store.state.token,
          },
          xhrFields: {
            responseType: 'blob'
          },
          success: function (blob) {
            let link = document.createElement('a');
            link.setAttribute("type", "hidden");
            document.body.appendChild(link);
            link.href = window.URL.createObjectURL(blob);
            link.download = file.label + '.pdf';
            link.click();
          },
          error: function () {
          }
        });
      }
    },
    onError(file) {
      if (cordovaUtils.isOnline()) {
        let self = this;
        let fileURL = this.serverURL + file.path;
        this.$store.commit("addActiveNetworkTask", 1);
        cordovaUtils.downloadFile(fileURL, file.label + '.' + file.path.split('.').pop()).then(function () {
          self.$store.commit("addActiveNetworkTask", -1);
          toast.success(file.label + " heruntergeladen");
        });
      }
    },
    updateVideo(updatedVideo) {
      this.skillToEdit.videos.find(video => video.id === updatedVideo.id).label = updatedVideo.label;
      this.skillToEdit = null;
    },
  }
}
</script>

<style>
.mb-minus-1 {
  margin-bottom: -1px;
}

.mb-12 {
  margin-bottom: 12px;
}

.no-top-radius {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.no-bottom-radius {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.list-group-item {
  padding-bottom: 1.25rem;
}
</style>
