<template>
    <div>
        <div class="container">
            <div class="row m-0 p-2" id="overlay-adding-vals">
                <div class="close-btn" style="top: 7px; left: 14rem">
                    <button @click="$modal.hide('add-overlay');">
                        ❌
                    </button>
                </div>
                <div class="large-12 medium-12 small-12 cell">
                    <label class="file-label">Markierung hinzufügen</label>
                  <textarea class="form-control" style="width: 100%" v-model="newOverlayName" placeholder="Titel"></textarea>
                </div>
            </div>
        </div>
        <button class="upload-btn btn btn-primary" v-on:click="addOverlay()">Weiter</button>
    </div>
</template>

<script>
    import toast from '../../utils/toast';

    export default {
        name: "OverlayAddingModal",
        props:['start'],
        data: function(){
          return{
              newOverlayName:null,
              markerDuration: 0,
              pauseVideo: 1,
          }
        },
        methods:{
            addOverlay: function () {
                let self = this;
                this.$api.createNewOverlay({
                    segments: {id: this.$route.params.id},
                    data: {
                        video_id: self.$route.params.id,
                        label: self.newOverlayName,
                        start: self.start?self.start:0,
                        end: (self.start?self.start:0) + self.markerDuration,
                        does_pause: self.pauseVideo,
                    }
                }).then(response => {
                    response.data['video_markers']=[];
                    response.data['annotations']=[];
                    this.$emit('updateOverlays', response.data);
                    toast.success('Overlay hinzugefügt')
                });
            },
        }
    }
</script>

<style>
#overlay-adding-vals{
    position: absolute;
}
#overlay-adding-vals > *{
    width: 100%;
}
</style>