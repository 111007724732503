<template>
  <div>
    <div class="table-header">
      <div class="table-first-col">{{ title }}</div>
      <div class="table-col" v-for="game in gameData" :key="game.label"><span
          class="table-cell">{{ game.label }}</span></div>
      <div class="table-col table-sum"><span class="table-last-cell">&Sigma;</span></div>
    </div>
    <div class="table-row table-inside-row" v-for="team in teams" :key="team">
      <div class="table-first-col">{{ team }}</div>
      <div class="table-col" style="cursor: pointer" v-for="game in gameData" :key="game.label"
           @click="emitFilter($event, team, game.label, game.values[team])">
        <span class="px-1" style="display: table; margin: auto"
              :class="winnerTeam(game) === team && title === 'Result' ? 'bg-success' : ''">{{
            game.values[team]
          }}</span>
      </div>
      <div class="table-col table-sum" style="cursor: pointer">
        <span class="table-last-cell"
              @click="emitMultipleFilters($event, team, null, 'teamSum')"
        >{{ teamSum(team) }}</span>
      </div>
    </div>
    <div class="table-row table-sum" style="cursor: pointer">
      <div class="table-first-col"><span>&Sigma;</span></div>
      <div class="table-col" v-for="game in gameData" :key="game.label">
        <span class="table-cell"
              @click="emitMultipleFilters($event, null, game.label, 'gameSum')"
        >{{ gameSum(game.values) }}</span>
      </div>
      <div class="table-col">
        <span class="table-last-cell">{{ totalSum }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {ref} from "vue";

export default {
  name: 'FilterTable',
  props: {
    title: {
      type: String,
      required: true
    },
    gameData: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const teams = ref([])
    const totalSum = ref(0)
    const winnerObject = ref({})
    const gameSum = (values) => {
      let sum = 0
      Object.entries(values).forEach((points) => {
        sum += +points[1]
      })
      return sum
    }
    const teamSum = (team) => {
      let sum = 0
      props.gameData.forEach((game) => {
        sum += game.values[team]
      })
      return sum
    }
    const winnerTeam = (game) => {
      let winner = ''
      let max = 0
      Object.entries(game.values).forEach((points) => {
        if (+points[1] > max) {
          max = +points[1]
          winner = points[0]
        } else if (+points[1] === max) {
          winner = ''
        }
      })
      return winner
    }

    return {teams, gameSum, teamSum, totalSum, winnerObject, winnerTeam}
  },
  methods: {
    emitFilter(event, team, game, value) {
      let filter = {
        team: team,
        game: game,
        value: value,
        ctrlKey: event.ctrlKey
      }
      this.$emit('updateFilter', filter)
    },

    emitMultipleFilters(event, team, game, value) {
      let filter = {
        team: team,
        game: game,
        value: value,
        ctrlKey: event.ctrlKey
      }
      this.$emit('updateMultipleFilters', filter)
    }
  },
  mounted() {
    this.teams = Object.keys(this.gameData[0].values)
    this.totalSum = this.gameData.reduce((acc, game) => {
      return acc + this.gameSum(game.values)
    }, 0)
  }
}
</script>

<style scoped>
.table-header, .table-row {
  display: flex;
  margin-bottom: 1px;
}

.table-inside-row {
  border-bottom: #0f0f0f solid 1px;
  padding-bottom: 1px;
}

.table-header {
  font-weight: bold;
  border-bottom: #0f0f0f solid 1px;
}

.table-first-col {
  text-overflow: ellipsis;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  flex: 2;
}

.table-col {
  flex: 1;
}

.table-sum {
  font-weight: bold;
}

.table-cell {
  display: table;
  margin: auto
}

.table-last-cell {
  display: table;
  margin-left: auto;
}
</style>
