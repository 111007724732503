<template>
  <div class="bg-light" :class="{hidden: $store.state.isFullscreen, 'd-flex': !$store.state.isFullscreen}">
    <div style="left: 0; z-index: 1" class="sticky-top d-flex flex-column pt-1 pl-1">
      <button class="btn scene-button py-1 mb-1" :class="showServe ? 'btn-success' : 'btn-primary'"
              @click="showServe = !showServe"
              v-tooltip.right="tooltip.showServe"
      >
        <i v-if="this.$store.state.game === 'table tennis'" class="fas fa-table-tennis"></i>
        <div class="small" v-else>S&R</div>
      </button>

      <button class="btn btn-primary scene-button py-1 mb-1"
              @click="showModal" :disabled="!checkedScenes.length"
              v-tooltip.right="tooltip.favorite"
      >
        <i class="fas fa-plus"></i>
      </button>
      <button class="btn btn-primary scene-button py-1 mb-1"
              @click="editScene" :disabled="checkedScenes.length !== 1"
              v-tooltip.right="tooltip.editScene"
      >
        <i class="fas fa-edit"></i>
      </button>
      <button class="btn btn-primary scene-button py-1 mb-1"
              @click="deleteScenes" :disabled="!checkedScenes.length"
              v-tooltip.right="tooltip.deleteScene"
      >
        <i class="fas fa-trash-alt"></i>
      </button>
      <button class="btn btn-primary scene-button py-1 mb-1" @click="exportCSV" :disabled="!checkedScenes.length"
              v-tooltip.right="tooltip.exportScenes"
              v-if="$store.state.user.role_name === 'admin'"
      >
        <i class="fas fa-download"></i>
      </button>
      <button class="btn btn-primary scene-button py-1 mb-1"
              @click="removeTagsFromScene" :disabled="(checkedScenes.length !== 1) || (removeTags.length !==1)"
              v-tooltip.right="tooltip.deleteTag"
      >
        <div class="d-flex flex-row my-1">
          <i class="fas fa-trash-alt mr-2"></i>
          <i class="fas fa-tags"></i>
        </div>
      </button>
      <router-link :to="'/video/' + video.id + '/analysis'" class="btn btn-primary scene-button py-1 mb-1"
                   v-tooltip.right="tooltip.analysis"
      >
        <i class="fas fa-chart-bar"></i>
      </router-link>
    </div>
    <table class="table table-sm table-bordered" style="font-size: 60%; z-index: 0;">
      <thead class="sticky-top bg-white shadow-sm text-nowrap" style="z-index: 0">
      <tr>
        <th><input type="checkbox" v-model="checkAll" @change="checkAllScenes(checkAll)"/></th>
        <th>Szene</th>
        <th @click="sortScenes('title')" style="cursor: pointer">
          Name
        </th>
        <th @click="sortScenes('start')" style="cursor: pointer">
          Position
        </th>
        <th @click="sortByLength()" style="cursor: pointer">Dauer</th>
        <th v-for="category in filteredCategories" v-bind:key="category.id" @click="sortByCategory(category)"
            style="cursor: pointer">
          {{ category.title === 'Custom' ? 'Favoriten' : category.title }}
        </th>
      </tr>
      </thead>
      <tbody class="text-nowrap">
      <tr v-for="(scene, index) in scenes"
          v-bind:key="scene.id"
          :class="checkActive(scene) ? 'active' : ''"
          style="cursor: pointer" @click="jumpToScene(index - 1)"
      >
        <td v-on:click.stop><input type="checkbox" :id="scene.id" :value="scene" v-model="checkedScenes"/></td>
        <td>{{ ++index }}</td>
        <td>{{ scene.title }}</td>
        <td>{{ convertSecToTime(scene.start) }}</td>
        <td>{{ sceneLength(scene) }} Sek.</td>
        <td v-for="category in filteredCategories" v-bind:key="category.id">
          {{ filterTagsByCategory(scene.tags, category) }}
        </td>
      </tr>
      </tbody>
    </table>
    <modal name="addSceneToTag" height="auto" :adaptive="true">
      <add-scene-to-tag-modal
          :categories="customCategoryOnly"
          :scenes="checkedScenes"
          @sceneAddedToTags="updateScenes($event)"
      />
    </modal>
    <modal name="editScene" height="auto" :adaptive="true">
      <SceneTableEditModal
          :scene="checkedScenes[0]"
          :options="categories"
          @sceneUpdated="updateScene($event)"
      />
    </modal>
  </div>
</template>

<script>
import AddSceneToTagModal from "@/components/Scenes/AddSceneToTagModal";
import SceneTableEditModal from "@/components/Scenes/SceneTableEditModal";
import serverURL from "@/utils/serverURL";
import $ from "jquery";

export default {
  name: "SceneTable",
  components: {AddSceneToTagModal, SceneTableEditModal},
  props: ['scenes', 'categories', 'videoTime', 'file', 'videoTitle', 'value', 'removeTags', 'video'],
  data() {
    return {
      checkedScenes: [],
      currentSceneIndex: null,
      sceneData: this.scenes,
      sortingActive: 'start',
      ascending: false,
      activeScene: {},
      scenePausedAt: null,
      checkAll: false,
      pressedKeys: {},
      showServe: false,
      tooltip: {
        showServe: 'Spieleröffnung',
        nextScene: 'Nächste Szene (&#8595;)',
        prevScene: 'Vorherige Szene (&#8593;)',
        favorite: 'zu Favoriten hinzufügen',
        editScene: 'Szene bearbeiten',
        deleteScene: 'Szene löschen',
        exportScenes: 'Als CSV exportieren',
        deleteTag: 'Tag von Szene löschen',
        analysis: 'Zur Analyse'
      },
      xhr: null,
    }
  },
  computed: {
    customCategoryOnly() {
      return this.categories.filter(category => category.title === 'Custom')
    },
    filteredCategories() {
      return this.categories.filter((category) => {
        return this.checkCategoryTags(category.title)
      })
    }
  },
  watch: {
    videoTime(newValue) {
      if (newValue >= this.activeScene.end / 1000 && this.activeScene !== this.scenePausedAt) {
        this.$emit('pauseVideo', this.activeScene.end / 1000)
        this.scenePausedAt = this.activeScene
      }

      if (this.showServe && (newValue >= (this.activeScene.start / 1000) + 4.5)) {
        this.$emit('pauseVideo', this.activeScene.start / 1000)
      }
    },
    currentSceneIndex(newValue) {
      this.$emit('input', newValue)
      if (this.activeScene === this.scenes[newValue]) {
        this.activeScene = {}
        this.$emit('sceneChanged', {scene: {start: 0, end: 0}, videoTime: this.videoTime})
      } else if (newValue >= 0 && newValue < this.scenes.length) {
        const videoTime = this.scenes[newValue].start / 1000
        this.currentSceneIndex = newValue
        this.activeScene = this.scenes[newValue]
        const res = {scene: this.activeScene, videoTime: videoTime}
        this.$emit('sceneChanged', res)
      }
      this.$emit('input', this.currentSceneIndex)
    },
    value(newValue) {
      if (newValue >= 0 && newValue < this.scenes.length) {
        this.currentSceneIndex = newValue
      } else {
        this.$emit('input', this.currentSceneIndex)
      }
    }
  },
  mounted() {
    document.addEventListener("keydown", (event) => {
      this.pressedKeys[event.key] = true;

      if (!this.pressedKeys['Control']) {
        switch (event.key) {
          case "ArrowUp":
            event.preventDefault()
            this.jumpToScene(this.currentSceneIndex - 1)
            break;
          case "ArrowDown":
            event.preventDefault()
            this.jumpToScene(this.currentSceneIndex + 1)
            break;
        }
      } else {
        event.preventDefault()
      }
    })
    document.addEventListener('keyup', (event) => {
      delete this.pressedKeys[event.key];
    });
  },
  methods: {
    removeTagsFromScene() {
      this.removeTags.forEach((tag) => {
        let formData = new FormData()
        formData.append('tag', tag.id)
        this.$api.deleteTagFromScene({
          segments: {
            sceneId: this.checkedScenes.at(0).id,
          },
          data: formData,
        }).then((res) => {
          if (res.status === 200) {
            this.checkedScenes.at(0).tags
            const sceneIndex = this.scenes.indexOf(this.checkedScenes.at(0));
            if (sceneIndex > -1) {
              const tagIndex = this.scenes.at(sceneIndex).tags.indexOf(tag); // 2nd parameter means remove one item only
              if (tagIndex) {
                this.scenes.at(sceneIndex).tags.splice(tagIndex, 1)
              }
            }
          }
        })
      })
    },

    sceneLength(scene) {
      let length = scene.end / 1000 - scene.start / 1000
      return length.toFixed(2)
    },
    filterTagsByCategory(tags, category) {
      const res = tags.filter((tag) => {
        return tag.category.id === category.id
      })
      if (res) {
        return res.map(r => r.title).join(', ')
      }
      return ''
    },
    convertSecToTime(milliseconds) {
      return new Date(milliseconds).toISOString().substring(11, 19)
    },
    updateScenes(response) {
      let sceneIndex = this.sceneData.findIndex((item) => {
        return item.id === response.scene.id
      })
      this.$set(this.sceneData, sceneIndex, response.scene)
      if (response.complete) {
        this.checkedScenes = []
        this.$modal.hide('addSceneToTag')
      }
    },
    updateScene(scene) {
      let sceneIndex = this.scenes.findIndex((item) => {
        return item.id === scene.id
      })
      this.removeCheckedScene(scene)
      this.$set(this.scenes, sceneIndex, scene)
    },
    removeCheckedScene(scene) {
      let sceneIndex = this.checkedScenes.findIndex((item) => {
        return item.id === scene.id
      })
      this.checkedScenes.splice(sceneIndex, 1)
    },
    showModal() {
      this.$modal.show('addSceneToTag')
    },
    checkActive(scene) {
      return this.activeScene.id === scene.id
    },
    jumpToScene(newSceneIndex) {
      this.currentSceneIndex = newSceneIndex
    },
    sortScenes(sortBy) {
      if (sortBy !== this.sortingActive) {
        this.ascending = true
      }
      this.sortingActive = sortBy
      if (this.ascending) {
        this.ascending = !this.ascending
        if (sortBy === 'title') {
          this.sceneData.sort((a, b) => {
            return a[sortBy].localeCompare(b[sortBy], undefined, {
              numeric: true,
              sensitivity: 'base'
            });
          })
        } else {
          this.sceneData.sort((a, b) => {
            return a[sortBy] > b[sortBy] ? 1 : -1
          })
        }
      } else {
        this.sceneData.reverse()
      }
    },
    sortByLength() {
      if ('length' !== this.sortingActive) {
        this.ascending = true
      }
      this.sortingActive = 'length'
      if (this.ascending) {
        this.ascending = !this.ascending
        this.sceneData.sort((a, b) => {
          return a.end - a.start > b.end - b.start ? -1 : 1
        })
      } else {
        this.sceneData.reverse()
      }
    },
    sortByCategory(category) {
      if (category.title !== this.sortingActive) {
        this.ascending = true
      }
      this.sortingActive = category.title
      if (this.ascending) {
        this.ascending = !this.ascending
        this.sceneData.sort((a, b) => {
          const aTags = a.tags.find(tag => tag.category.title === category.title)
          const bTags = b.tags.find(tag => tag.category.title === category.title)
          if (aTags && bTags) {
            return aTags.title.localeCompare(bTags.title, undefined, {
              numeric: true,
              sensitivity: 'base'
            });
          } else if (aTags && !bTags) {
            return -1
          } else if (!aTags && bTags) {
            return 1
          }
          return -1
        })
      } else {
        this.sceneData.reverse()
      }
    },
    deleteScenes() {
      const result = confirm("Möchten Sie die ausgewählten Szenen wirklich löschen?");
      if (result) {
        const scenesToDelete = this.checkedScenes.map(scene => scene.id)
        this.$api.deleteScenes({
          data: {scenes: scenesToDelete}
        }).then((res) => {
          if (res.data === 'success') {
            scenesToDelete.forEach((scene) => {
              let sceneIndex = this.scenes.findIndex((item) => {
                return item.id === scene
              })
              // eslint-disable-next-line vue/no-mutating-props
              this.scenes.splice(sceneIndex, 1)
            })
          }
        })
        this.checkedScenes = []
      }
    },
    editScene() {
      this.$modal.show('editScene')
    },
    exportCSV() {
      const headers = ['Name', 'Position', 'Dauer', ...this.filteredCategories.map(category => category.title)]
      const sceneData = this.checkedScenes.map((scene) => {
        const data = [scene.title, this.convertSecToTime(scene.start).toString(), this.sceneLength(scene).toString()]
        this.filteredCategories.forEach((category) => {
          data.push(this.filterTagsByCategory(scene.tags, category))
        })
        return data
      })
      const formData = {
        headers: headers,
        sceneData: JSON.stringify(sceneData),
        filename: this.videoTitle
      }
      $.ajax({
        type: 'POST',
        url: serverURL + 'api/scenes/export',
        headers: {
          "Authorization": "Bearer " + this.$store.state.token,
        },
        data: formData,
        xhrFields: {
          responseType: 'blob'
        },
        success: function (blob) {
          let link = document.createElement('a');
          link.setAttribute("type", "hidden");
          document.body.appendChild(link);
          link.href = window.URL.createObjectURL(blob);
          link.download = formData.filename + '.csv';
          link.click();
        },
        error: function () {
        }
      });
    },
    checkAllScenes(newValue) {
      if (newValue) {
        this.checkedScenes = this.scenes
      } else {
        this.checkedScenes = []
      }
    },
    checkCategoryTags(category) {
      return this.scenes.some((scene) => {
        return scene.tags.some(tag => tag.category.title === category)
      })
    }
  },
}
</script>

<style scoped>
.active {
  background-color: rgba(38, 166, 91, 0.5);
}

.scene-button {
  margin: 0 1px;
}
</style>
