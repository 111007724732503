const countryCodes = {
    codes: [
        ["AFG", "Afghanistan"],
        ["AHO", "Niederländische Antillen"],
        ["ALB", "Albanien"],
        ["ALG", "Algerien"],
        ["AND", "Andorra"],
        ["ANG", "Angola"],
        ["ANT", "Antigua und Barbuda"],
        ["ARG", "Argentinien"],
        ["ARM", "Armenien"],
        ["ARU", "Aruba"],
        ["ASA", "Amerikanisch-Samoa"],
        ["AUS", "Australien"],
        ["AUT", "Österreich"],
        ["AZE", "Aserbaidschan"],
        ["BAH", "Bahamas"],
        ["BAN", "Bangladesch"],
        ["BAR", "Barbados"],
        ["BDI", "Burundi"],
        ["BEL", "Belgien"],
        ["BEN", "Benin"],
        ["BER", "Bermuda"],
        ["BHU", "Bhutan"],
        ["BIH", "Bosnien und Herzegowina"],
        ["BIZ", "Belize"],
        ["BLR", "Belarus"],
        ["BOL", "Bolivien"],
        ["BOT", "Botswana"],
        ["BRA", "Brasilien"],
        ["BRN", "Bahrain"],
        ["BRU", "Brunei"],
        ["BUL", "Bulgarien"],
        ["BUR", "Burkina Faso"],
        ["CAF", "Zentralafrikanische Republik"],
        ["CAM", "Kambodscha"],
        ["CAN", "Kanada"],
        ["CAY", "Cayman Islands"],
        ["CGO", "Republik Kongo"],
        ["CHA", "Tschad"],
        ["CHI", "Chile"],
        ["CHN", "Volksrepublik China"],
        ["CIV", "Côte d'Ivoire"],
        ["CMR", "Kamerun"],
        ["COD", "Demokratische Republik Kongo"],
        ["COK", "Cookinseln"],
        ["COL", "Kolumbien"],
        ["COM", "Komoren"],
        ["COR", "Gesamtkoreanische Mannschaft"],
        ["CPV", "Kap Verde"],
        ["CRC", "Costa Rica"],
        ["CRO", "Kroatien"],
        ["CUB", "Kuba"],
        ["CYP", "Zypern"],
        ["CZE", "Tschechien"],
        ["DEN", "Dänemark"],
        ["DJI", "Dschibuti"],
        ["DMA", "Dominica"],
        ["DOM", "Dominikanische Republik"],
        ["ECU", "Ecuador"],
        ["EGY", "Ägypten"],
        ["ENG", "England"],
        ["ERI", "Eritrea"],
        ["ESA", "El Salvador"],
        ["ESP", "Spanien"],
        ["EST", "Estland"],
        ["ETH", "Äthiopien"],
        ["FIJ", "Fidschi"],
        ["FIN", "Finnland"],
        ["FRA", "Frankreich"],
        ["FSM", "Mikronesien"],
        ["GAB", "Gabun"],
        ["GAM", "Gambia"],
        ["GBR", "Großbritannien"],
        ["GBS", "Guinea-Bissau"],
        ["GEO", "Georgien"],
        ["GEQ", "Äquatorialguinea"],
        ["GER", "Deutschland"],
        ["GHA", "Ghana"],
        ["GRE", "Griechenland"],
        ["GRN", "Grenada"],
        ["GUA", "Guatemala"],
        ["GUI", "Guinea"],
        ["GUM", "Guam"],
        ["GUY", "Guyana"],
        ["HAI", "Haiti"],
        ["HKG", "Hongkong, China"],
        ["HON", "Honduras"],
        ["HUN", "Ungarn"],
        ["INA", "Indonesien"],
        ["IND", "Indien"],
        ["IRI", "Islamische Republik Iran"],
        ["IRL", "Irland"],
        ["IRN", "Iran"],
        ["IRQ", "Irak"],
        ["ISL", "Island"],
        ["ISR", "Israel"],
        ["ISV", "Amerikanische Jungferninseln"],
        ["ITA", "Italien"],
        ["IVB", "Britische Jungferninseln"],
        ["JAM", "Jamaika"],
        ["JOR", "Jordanien"],
        ["JPN", "Japan"],
        ["KAZ", "Kasachstan"],
        ["KEN", "Kenia"],
        ["KGZ", "Kirgisistan"],
        ["KIR", "Kiribati"],
        ["KOR", "Korea, Republik"],
        ["KOS", "Kosovo"],
        ["KSA", "Saudi-Arabien"],
        ["KUW", "Kuwait"],
        ["LAO", "Laos"],
        ["LAT", "Lettland"],
        ["LBA", "Libyen"],
        ["LBR", "Liberia"],
        ["LCA", "St. Lucia"],
        ["LES", "Lesotho"],
        ["LIB", "Libanon"],
        ["LIE", "Liechtenstein"],
        ["LTU", "Litauen"],
        ["LUX", "Luxemburg"],
        ["MAD", "Madagaskar"],
        ["MAR", "Marokko"],
        ["MAS", "Malaysia"],
        ["MAW", "Malawi"],
        ["MDA", "Moldau"],
        ["MDV", "Malediven"],
        ["MEX", "Mexiko"],
        ["MGL", "Mongolei"],
        ["MHL", "Marshallinseln"],
        ["MKD", "Nordmazedonien"],
        ["MLI", "Mali"],
        ["MLT", "Malta"],
        ["MNE", "Montenegro"],
        ["MON", "Monaco"],
        ["MOZ", "Mosambik"],
        ["MRI", "Mauritius"],
        ["MTN", "Mauretanien"],
        ["MYA", "Myanmar"],
        ["NAM", "Namibia"],
        ["NCA", "Nicaragua"],
        ["NED", "Niederlande"],
        ["NEP", "Nepal"],
        ["NGR", "Nigeria"],
        ["NIG", "Niger"],
        ["NOR", "Norwegen"],
        ["NRU", "Nauru"],
        ["NZL", "Neuseeland"],
        ["OMA", "Oman"],
        ["OAR", "Olympische Athleten aus Russland"],
        ["PAK", "Pakistan"],
        ["PAN", "Panama"],
        ["PAR", "Paraguay"],
        ["PER", "Peru"],
        ["PHI", "Philippinen"],
        ["PLE", "Palästinensische Autonomiegebiete"],
        ["PLW", "Palau"],
        ["PNG", "Papua-Neuguinea"],
        ["POL", "Polen"],
        ["POR", "Portugal"],
        ["PRK", "Korea, Demokratische Volksrepublik"],
        ["PUR", "Puerto Rico"],
        ["QAT", "Katar"],
        ["ROU", "Rumänien"],
        ["RSA", "Südafrika"],
        ["RUS", "Russland"],
        ["RWA", "Ruanda"],
        ["SAM", "Samoa"],
        ["SCO", "Schottland"],
        ["SEN", "Senegal"],
        ["SEY", "Seychellen"],
        ["SGP", "Singapur"],
        ["SKN", "St. Kitts und Nevis"],
        ["SLE", "Sierra Leone"],
        ["SLO", "Slowenien"],
        ["SMR", "San Marino"],
        ["SOL", "Salomonen"],
        ["SOM", "Somalia"],
        ["SRB", "Serbien"],
        ["SRI", "Sri Lanka"],
        ["SSD", "Südsudan"],
        ["STP", "São Tomé und Príncipe"],
        ["SUD", "Sudan"],
        ["SUI", "Schweiz"],
        ["SUR", "Suriname"],
        ["SVK", "Slowakei"],
        ["SWE", "Schweden"],
        ["SWZ", "Swasiland"],
        ["SYR", "Syrien"],
        ["TAN", "Tansania"],
        ["TGA", "Tonga"],
        ["THA", "Thailand"],
        ["TJK", "Tadschikistan"],
        ["TKM", "Turkmenistan"],
        ["TLS", "Osttimor"],
        ["TOG", "Togo"],
        ["TPE", "Chinesisch Taipeh"],
        ["TTO", "Trinidad und Tobago"],
        ["TUN", "Tunesien"],
        ["TUR", "Türkei"],
        ["TUV", "Tuvalu"],
        ["UAE", "Vereinigte Arabische Emirate"],
        ["UGA", "Uganda"],
        ["UKR", "Ukraine"],
        ["URU", "Uruguay"],
        ["USA", "USA"],
        ["UZB", "Usbekistan"],
        ["VAN", "Vanuatu"],
        ["VEN", "Venezuela"],
        ["VIE", "Vietnam, Sozialistische Republik"],
        ["VIN", "St. Vincent und die Grenadinen"],
        ["WAL", "Wales"],
        ["YEM", "Jemen, Republik"],
        ["ZAM", "Sambia"],
        ["ZIM", "Simbabwe"]
    ],
    getNameByCode: function (code) {
         let entry =this.codes.find(function (element) {
            return element[0] === code;
        });
        return entry?entry[1]:'Not known (' + code +')'
    },
    getSortedCodes:function () {
      return this.codes.sort(function (a, b) {
          return a[0]>b[0]?1:-1;
      });
    }
};

export default countryCodes;
