<template>
    <div class="row">
        <component :is="activeNavigation"
                   :player="player"
                   @updateSkill="updateSkill"
        ></component>
    </div>
</template>

<script>
    import PlayerOverview from "./PlayerOverview";

    export default {
        name: 'PlayerProfile',
        components: {PlayerOverview},
        props: ['player'],
        data: function () {
            return {
                navigationEntries: {
                },
                activeNavigation: 'PlayerOverview',
            };
        },
        watch: {
            '$route.params.subTab': function () {
                this.updateTap();
            }
        },
        mounted: function () {
            this.updateTap();
        },
        methods: {
            updateTap() {
              // eslint-disable-next-line no-prototype-builtins
                if (this.navigationEntries.hasOwnProperty(this.$route.params.subTab)) {
                    this.$router.push(this.$route.params.subTab);
                    this.activeNavigation = this.$route.params.subTab;
                }
            },
            navigationClasses(entry) {
                let classes = ['btn', 'btn-primary', 'border', 'rounded-0'];
                if (entry === this.activeNavigation) {
                    classes.push('active');
                }
                return classes;
            },
            updateSkill(updatedSkill){
                this.$emit('updateSkill', updatedSkill)
            }
        },
    }
</script>
