<template>
  <div>
    <div class="form-group row pl-4 pt-2">
      <div class="col-lg-1">Ansicht</div>
      <div class="col-lg-8">
        <input type="radio" id="basic" value="basic" v-model="$store.state.dashboardStyle">
        <label for="basic" class="ml-2">Basic</label>
        <input type="radio" id="detail" class="ml-4" value="detail" v-model="$store.state.dashboardStyle">
        <label for="detail" class="ml-2">Detail</label>
      </div>
    </div>
    <div v-if="loaded">
      <DashboardSingle v-if="videoData.videoable.team1.type === 'single'" :video-data="videoData"
                       :tag-options="tagOptions" :dashboard-style="$store.state.dashboardStyle"></DashboardSingle>
      <DashboardDouble v-else :video-data="videoData"
                       :tag-options="tagOptions" :dashboard-style="$store.state.dashboardStyle"></DashboardDouble>
    </div>
  </div>
</template>

<script>
import VideoDataAccessor from "@/storage/video-accessor";
import toast from "@/utils/toast";
import customSortCategories from "@/utils/customSort";
import DashboardSingle from "@/components/Analysis/Dashboard/DashboardSingle.vue";
import DashboardDouble from "@/components/Analysis/Dashboard/DashboardDouble.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'AnalysisMainPage',
  components: {DashboardSingle, DashboardDouble},
  data: function () {
    return {
      videoData: {
        absolute_path: null,
        created_at: null,
        deleted_at: null,
        id: null,
        label: null,
        path: null,
        sport: null,
        updated_at: null,
        video_overlays: null,
        videoable: null,
        videoable_id: null,
        videoable_type: null,
      },
      tagOptions: [],
      loaded: false
    }
  },
  mounted() {
    Promise.all([
      this.getVideo(),
      this.getTags()
    ]).then(
        () => {
          this.loaded = true
        }
    )
    this.$store.state.sidebarVisible = false
  },
  methods: {
    getVideo: function () {
      let self = this;
      if (this.is_corodva && this.isPersistent) {
        let vid = new VideoDataAccessor(parseInt(self.$route.params.id));
        vid.getVideo().then(function (video) {

          self.videoData = video;
          vid.getVideoFile().then(function (file_url) {
            self.videoData.absolute_path = file_url;
          });
        });
      } else {
        return this.$api.getVideoDetail({
          segments: {id: parseInt(this.$route.params.id)}
        }).then(response => {
          if (response.status === 200) {
            self.videoData = response.data;
          } else {
            toast.error('Bitte verbinde dich mit dem Internet')
          }
        });
      }
    },
    getTags() {
      return this.$api.fetchVideoTags({segments: {videoId: this.$route.params.id}}).then((res) => {
        this.tagOptions = customSortCategories(res.data)
      })
    },
  }
}
</script>

<style scoped>
</style>
