<template>
  <div class="wrapper" v-if="isLoggedIn">
    <div>
      <!-- Sidebar  -->
      <div id="header" v-if="!$store.state.isFullscreen">
            <span class="navigation" :style="is_cordova ? 'font-size: 25px;' : ''">
                <i @click="$store.commit('toggleSidebar')" class="fas fa-bars menu-toggle"></i>
                <i @click="$router.go(-1)" class="fas fa-angle-double-left"></i>
                <i @click="$router.go(1)" class="fas fa-angle-double-right ml-2"></i>
            </span>
        <span>Gegner-Taktik-App</span>
        <div class="flex">
          <span @click="signOut" class="sign-out">
          <span class="logout-username">{{ $store.state.user.firstname }}</span>
            <i class="fas fa-power-off pl-1"></i>
          </span>
               <router-link to="/"><img alt="IAT" id="logo" src="./assets/img/IAT_LOGO_Small.png"></router-link>
        </div>

      </div>

      <nav :class="{hidden: !sidebarVisible}" id="sidebar">
        <Sidebar></Sidebar>
      </nav>

      <!-- Page Content  -->
      <div :class="{active: !sidebarVisible}" :id="$store.state.isFullscreen ? 'fullscreen' : 'content'">
        <div :class="{loader: true, active: networkIsActive}"></div>
        <div :class="$store.state.isFullscreen ? '' : 'container-fluid'">
          <router-view
              @syncOfflineData="syncOfflineData">

          </router-view>
        </div>
      </div>
    </div>
  </div>
  <Login v-model="isLoggedIn" v-else></Login>
</template>

<script>
import Sidebar from './components/Sidebar'
import Login from "./components/Login";

import {cordovaUtils} from "./utils/cordovaUtils";

import PlayerDataAccessor from "./storage/player-accessor.jsx";
import VideoDataAccessor from "./storage/video-accessor.jsx";
import GameDataAccessor from "./storage/game-accessor.jsx";
import router from "@/utils/router";

export default {
  name: 'app',
  components: {
    Login,
    Sidebar,
  },
  data: function () {
    return {
      isLoggedIn: false,
      is_cordova: !!window.cordova,
    }
  },
  watch: {
    //auto-logout if unauthenticated
    '$store.state.token': function () {
      if (this.$store.state.token === false) {
        this.isLoggedIn = false;
      }
    }
  },
  beforeMount: function () {
    let self = this;
    //  event fires when Cordova is fully loaded.
    document.addEventListener("deviceready", function () {

      document.addEventListener("backbutton", function () {
        self.$router.go(-1);
      }, false);

      document.addEventListener("pause", function () {
        self.$store.commit('setLastRoute', window.location.hash);
        self.$store.dispatch('saveState');
        self.syncOfflineData();
      });

      document.addEventListener("resume", function () {
        // self.syncOfflineData();
        self.$vueEventBus.$emit('custom-update')
      });

      self.$store.dispatch('loadStateFromFS').then(function () {
        //auto-login in cordova
        if (self.$store.state.token) {
          self.isLoggedIn = true;
          self.login();
          const p = self.$store.state.lastRoute.slice(1);
          self.$router.push({path: `${p}`})
          self.syncOfflineData();
        }
      })
    });
  },
  mounted: function () {
    // close sidebar on mobile, if navigation link was triggered
    this.$router.afterEach(() => {
      if (window.innerWidth < 1000) {
        this.$store.commit('toggleSidebar', false)
      }
    }, false);
    if (window.innerWidth <= 855) {
      window.addEventListener('orientationchange', this.handleOrientationChange);
    }
  },
  methods: {
    handleOrientationChange() {
      if (!this.is_cordova) {
        if (window.matchMedia('(orientation: portrait)').matches) {
          this.$store.dispatch('updateFullscreenValue', true);
        } else if (window.matchMedia('(orientation: landscape)').matches) {
          this.$store.dispatch('updateFullscreenValue', false);
        }
      }
    },
    login: function () {
      this.isLoggedIn = true;
      this.loadPlayerList();
    },
    loadPlayerList: function () {
      this.$api.getPlayerList({
        segments: {sport: this.$store.state.game}
      }).then(response => {

        if (response.status === 200) {
          response.data.map(function (player) {
                let types = ['single', 'doubles', 'mixed'];
                let teamTypes = [];
                player['teamTypes'] = [];
                player.teams.forEach(function (team) {
                  for (let key in types) {
                    if (team.type === types[key]) {
                      teamTypes.push(team.type);
                      types.splice(key, 1);
                      break;
                    }
                  }
                  player['teamTypes'] = teamTypes;
                })
              }
          );


          this.$store.commit('setPlayers', response.data);
          if (window.cordova) {
            this.$store.dispatch('saveState');
          }
        }
      });
    },
    syncOfflineData: function () {
      if (this.$store.state.token) {
        let self = this;
        if (self.$store.state.downloaded_players.length > 0) {
          self.$api.getCustomPlayerList({
            segments: {players: self.$store.state.downloaded_players.join(';')}
          }).then(response => {
            if (response.status === 200) {
              response.data.forEach(function (player) {
                new PlayerDataAccessor(parseInt(player.id)).updatePlayer(player);
              })
            }
          });
        }

        if (self.$store.state.downloaded_videos.length > 0) {
          self.$api.getCustomVideoList({
            segments: {videos: self.$store.state.downloaded_videos.join(';')}
          }).then(response => {
            if (response.status === 200) {
              response.data.forEach(function (video) {
                new VideoDataAccessor(parseInt(video.id)).updateVideo(video);
              })
            }
          });
        }

        if (self.$store.state.downloaded_games.length > 0) {
          self.$api.getCustomGameList({
            segments: {games: self.$store.state.downloaded_games.join(';')}
          }).then(response => {
            if (response.status === 200) {
              response.data.forEach(function (game) {
                new GameDataAccessor(game).updateGame(game);
              })
            }
          });
        }
      }
    },
    signOut: function () {
      if (cordovaUtils.isCordova()) {
        this.$cookies.remove('user');
        this.$store.state.token = false;
      } else {
        this.$cookies.remove('user');
        location.reload();
      }
    },
  },
  computed: {
    sidebarVisible() {
      return this.$store.state.sidebarVisible;
    },
    networkIsActive() {
      return this.$store.state.networkActiveTasks > 0
    },
    isFullScreen() {
      return router.currentRoute.fullPath.includes('fullscreen')
    }
  },
}
</script>

<style lang="css">
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
@import '../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css';
@import '../node_modules/@fortawesome/fontawesome-free/css/solid.min.css';
@import '~izitoast/dist/css/iziToast.min.css';
</style>
<style lang="scss">
@import "./assets/sass/variables";
@import '../node_modules/bootstrap/scss/bootstrap';
@import './assets/sass/main';
</style>
