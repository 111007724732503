<template>
  <div class="dashboard">
    <div class="grid-container">
      <div class="grid-item wide flex" v-show="dashboardStyle === 'detail'">
        <div class="zone-card mr-4" v-if="loaded && player1impulseZoneData.length">
          <div class="font-weight-bold">Spieler*in 1</div>
          <div class="mb-2">{{ player1 }}</div>
          <impulse-chart
              :impulse-zone-data="player1impulseZoneData"
              :impulse-placement-data="player1impulsePlacementData"
              @updateFilter="handleImpulseChartClick($event, player1)"
          />
        </div>
        <div class="zone-card" v-if="loaded && player2impulseZoneData.length">
          <div class="font-weight-bold">Spieler*in 2</div>
          <div class="mb-2">{{ player2 }}</div>
          <impulse-chart
              :impulse-zone-data="player2impulseZoneData"
              :impulse-placement-data="player2impulsePlacementData"
              @updateFilter="handleImpulseChartClick($event, player2)"
          />
        </div>
      </div>
      <div v-if="loaded && winnerByHitNumber.length"
           class="grid-item wide">
        <h3>Punktgewinner*in nach Anzahl Schlägen im Ballwechsel</h3>
        <div class="chart-card">
          <VChartBar :type="'grouped'"
                     :data="winnerByHitNumber"
                     :y-min="chartOptions.yMin"
                     :tooltip-color="'#12239E'"
                     :bar-width="chartOptions.barWidth"
                     :bar-padding-outer="chartOptions.barPaddingOuter"
                     :bar-padding-inner="chartOptions.barPaddingInner"
                     :bar-colors="chartOptions.barColors"
                     v-on:on-bar-click="handleImpactNumberBarClick($event)"></VChartBar>
        </div>
      </div>
      <div class="grid-item wide" v-if="loaded && winnerByZone">
        <h3>Punkt/Fehler nach Zone</h3>
        <div class="flex gap-5 chart-card">
          <div
              v-for="(data, playerName) in winnerByZone"
              :key="playerName"
              class="w-50 mb-3"
          >
            <div class="mb-1">{{ playerName }}</div>
            <VChartBar :type="'stacked'"
                       :data="data"
                       :tooltip-color="'#12239E'"
                       :bar-width="zoneChartOptions.barWidth"
                       :bar-padding-outer="zoneChartOptions.barPaddingOuter"
                       :bar-padding-inner="zoneChartOptions.barPaddingInner"
                       :bar-colors="zoneChartOptions.barColors"
                       :y-min="maxZoneCount"
                       v-on:on-bar-click="handleBarZoneClick($event)"></VChartBar>
          </div>
        </div>
      </div>
      <div class="grid-item tall">
        <tag-filter-container
            :categories="tagOptions.filter(item => item.title !== 'Custom')"
            :update-tags-in-store="true"
            :tag-counts="existingTagsCount"
            @selectedTagsUpdated="updateTags"
        ></tag-filter-container>
      </div>
    </div>
  </div>
</template>

<script>
import TagFilterContainer from "@/components/Scenes/TagFilterContainer.vue";
import ImpulseChart from "@/components/Analysis/ImpulseChart.vue";
import {VChartBar} from "@singularit/vue2-charts";

export default {
  name: 'DashboardSingle',
  components: {VChartBar, TagFilterContainer, ImpulseChart},
  props: {
    videoData: {
      absolute_path: null,
      created_at: null,
      deleted_at: null,
      id: null,
      label: null,
      path: null,
      sport: null,
      updated_at: null,
      video_overlays: null,
      videoable: null,
      videoable_id: null,
      videoable_type: null,
    },
    tagOptions: {
      type: Array,
      default: () => []
    },
    dashboardStyle: {
      type: String,
      default: 'basic'
    }
  },
  data() {
    return {
      selectedTags: [],
      player1: null,
      player2: null,
      player1Scenes: [],
      player2Scenes: [],
      player1impulseZoneData: [],
      player1impulsePlacementData: [],
      player2impulseZoneData: [],
      player2impulsePlacementData: [],
      loaded: false,
      winnerByHitNumber: null,
      winnerByZone: null,
      maxZoneCount: 0,
      chartOptions: {
        barWidth: [32, 32],
        barPaddingOuter: 10,
        barPaddingInner: 3,
        barColors: ['#118DFF', '#12239E'],
        yMin: 4
      },
      zoneChartOptions: {
        barWidth: [32, 32],
        barPaddingOuter: 10,
        barPaddingInner: 3,
        barColors: ['#70AD47', '#FFC000', '#FF0000'],
        yMin: 4
      },
    }
  },
  mounted() {
    this.setPlayerNames()
    this.setPlayerScenes()

    this.setData()
  },
  watch: {
    filteredScenes: function () {
      this.setData()
    }
  },
  computed: {
    filteredScenes() {
      if (this.$store.state.selectedTags.length) {
        let foundScenes = []
        // Group selected tags by category to get the some filter (as in A or B)
        let groupedTags = this.groupBy(this.$store.state.selectedTags, 'category_id')
        Object.values(groupedTags).forEach((tags) => {
          let filter = this.videoData.scenes.filter((scene) => {
            const sceneTags = scene.tags.map((tag) => tag.id)
            const selectedTagIds = tags.map((tag) => tag.id)
            return selectedTagIds.some((tag) => sceneTags.includes(tag))
          })
          foundScenes.push(filter)
        })
        // Reduce all found scenes to only those that exist in all the some filters ((A or B) and +)
        return foundScenes.reduce((a, b) => a.filter(c => b.includes(c)))
      }
      return this.videoData.scenes
    },
    existingTagsCount() {
      if (!this.filteredScenes)
        return []
      let tags = []
      this.filteredScenes.forEach((scene) => {
        tags.push(...scene.tags)
      })
      let groupedTags = this.groupBy(tags, 'id')
      Object.keys(groupedTags).forEach(key => groupedTags[key] = groupedTags[key].length)
      return groupedTags
    },
  },
  methods: {
    setData: function () {
      this.winnerByHitNumber = this.createWinnerByHitNumberData('Punkt/Fehler')
      this.winnerByZone = this.createWinnerByZoneData('Punkt/Fehler')

      this.setPlayerScenes()
      this.player1impulseZoneData = this.createImpulseData('Schlagzone Impuls', this.player1Scenes)
      this.player1impulsePlacementData = this.createImpulseData('Schlagplatzierung Impuls', this.player1Scenes)
      this.player2impulseZoneData = this.createImpulseData('Schlagzone Impuls', this.player2Scenes)
      this.player2impulsePlacementData = this.createImpulseData('Schlagplatzierung Impuls', this.player2Scenes)
      this.loaded = true
    },
    setPlayerNames() {
      let player1 = this.videoData.scenes[0].tags.filter(tag => tag.category.title === 'Spieler*in')[0].title;
      let player2 = player1
      let i = 1
      while (player2 === player1) {
        player2 = this.videoData.scenes[i].tags.filter(tag => tag.category.title === 'Spieler*in')[0].title;
        i++
      }
      this.player1 = player1
      this.player2 = player2
    },
    setPlayerScenes() {
      this.player1Scenes = this.filteredScenes.filter(scene => scene.tags.find(tag => tag.title === this.player1))
      this.player2Scenes = this.filteredScenes.filter(scene => scene.tags.find(tag => tag.title === this.player2))
    },
    filterScenes(tag, scenes) {
      // Reduce all found scenes to only those that exist in all the some filters ((A or B) and +)
      return scenes.filter((scene) => {
        const sceneTags = scene.tags.map((tag) => tag.id)
        return sceneTags.includes(tag.id)
      })
    },
    createImpulseData(filterOption, playerScenes) {
      if (!this.tagOptions.find(tagOption => tagOption.title === filterOption)) {
        return []
      }
      let zoneTags = this.tagOptions.find(tagOption => tagOption.title === filterOption).tags
      let impulseTags = this.tagOptions.find(tagOption => tagOption.title === 'Impuls').tags;

      let results = [];
      for (let impulseTag of impulseTags) {
        let counts = []
        for (let zoneTag of zoneTags) {
          let zoneScenes = this.filterScenes(zoneTag, playerScenes);
          let impulseScenes = this.filterScenes(impulseTag, zoneScenes);
          counts.push({zone: zoneTag.title, counts: impulseScenes.length});
        }
        results.push({
          data: counts,
          label: impulseTag.title,
        });
      }
      return results
    },

    groupBy(array, key) {
      const result = {}
      array.forEach(item => {
        if (!result[item[key]]) {
          result[item[key]] = []
        }
        result[item[key]].push(item)
      })
      return result
    },

    filterScenesByExchange(scenes) {
      // Reduce all found scenes to only those that exist in all the some filters ((A or B) and +)
      return scenes.filter((scene) => {
        return scene.title.includes('Ballwechsel')
      })
    },

    // Pray we never have to touch this again
    createWinnerByHitNumberData(filterOption) {
      if (!this.tagOptions.find(tagOption => tagOption.title === filterOption))
        return []
      let playerTags = this.tagOptions.find(tagOption => tagOption.title === 'Spieler*in').tags;
      let labels = ['1-5', '6-10', '11-15', '16-20', '20+']
      // Initialize an array to store the results
      // Initialize an object to store the results
      let results = {};

      // Iterate over each label
      labels.forEach(label => {
        // Initialize the label in results with an empty object
        results[label] = {};

        // Iterate over each player tag
        playerTags.forEach(player => {
          // Initialize the player tag in the label's object with 0
          results[label][player.title] = 0;
        });
      });

      // Filter the scenes by the current game
      let exchangeScenes = this.filterScenesByExchange(this.filteredScenes);

      // Loop over each point
      for (let scene of exchangeScenes) {
        // Initialize an array to store the counts of scenes per game
        let hitNumber = scene.tags.find(tag => tag.category.title === 'Cluster Schlagnummer');
        let player = scene.tags.find(tag => tag.category.title === 'Spieler*in');
        if (!hitNumber || !player) {
          continue
        }

        let otherPlayer = playerTags.find(tag => tag.title !== player.title)
        let result = scene.tags.find(tag => tag.category.title === 'Punkt/Fehler');

        // let rangeKey;
        // if (hitNumber.title <= 5) {
        //   rangeKey = '1-5';
        // } else if (hitNumber.title <= 10) {
        //   rangeKey = '6-10';
        // } else if (hitNumber.title <= 15) {
        //   rangeKey = '11-15';
        // } else if (hitNumber.title <= 20) {
        //   rangeKey = '16-20';
        // } else {
        //   rangeKey = '20+';
        // }

        // Determine the target player based on the result
        let targetPlayer = result.title === 'Winner' ? player : otherPlayer;

        // Increment the winner count for the target player
        results[hitNumber.title][targetPlayer.title] += 1;
      }

      let chartData = [];

      labels.forEach((label) => {
        let rangeData = results[label]; // Get the data for the current range
        let values = {};

        // Iterate over the keys (player titles) in the range data
        Object.keys(rangeData).forEach((playerTitle) => {
          values[playerTitle] = rangeData[playerTitle]; // Add player title and count to values
        });

        // Add the constructed object to chartData
        chartData.push({
          label: label, // This is the range
          values: values,
        });
      });

      return chartData
    },

    createWinnerByZoneData: function (filterOption) {
      if (!this.tagOptions.find(tagOption => tagOption.title === filterOption))
        return {};
      let gameTags = this.tagOptions.find(tagOption => tagOption.title === filterOption).tags;
      let zoneTags = this.tagOptions.find(tagOption => tagOption.title === 'Zone').tags;
      // Define the custom order
      const customOrder = ["Grün", "Gelb", "Rot"];

      // Sort the zoneTags array according to the custom order
      zoneTags.sort((a, b) => {
        // Find the index of the titles in the customOrder array
        let indexA = customOrder.indexOf(a.title);
        let indexB = customOrder.indexOf(b.title);

        // If one of the titles is not found in the customOrder array, it will be placed at the end
        if (indexA === -1) indexA = customOrder.length;
        if (indexB === -1) indexB = customOrder.length;

        return indexA - indexB;
      });
      let playerTags = this.tagOptions.find(tagOption => tagOption.title === 'Spieler*in').tags;

      // Initialize an object to store the results
      let results = {};

      for (let playerTag of playerTags) {
        results[playerTag.title] = [];
        // Loop over each point
        for (let zoneTag of zoneTags) {
          // Initialize an array to store the counts of scenes per game
          let counts = [];

          // Loop over each game
          for (let gameTag of gameTags) {
            let playerScenes = this.filterScenes(playerTag, this.filteredScenes);
            // Filter the scenes by the current game
            let gameScenes = this.filterScenes(gameTag, playerScenes);

            // Filter the game scenes by the current point
            let pointScenes = this.filterScenes(zoneTag, gameScenes);

            // Push the count of scenes to the counts array
            counts.push(pointScenes.length);
          }

          // Push an object to the results array containing the title of the point and the counts of scenes
          results[playerTag.title].push({
            data: counts,
            label: zoneTag.title,
          });
        }
      }

      let chartData = {};
      playerTags.forEach((playerTag) => {
        chartData[playerTag.title] = [];
        gameTags.forEach((tag, idx) => {
          const barSum = results[playerTag.title][0].data[idx] + results[playerTag.title][1].data[idx] + results[playerTag.title][2].data[idx]

          this.maxZoneCount = Math.max(this.maxZoneCount, barSum + (barSum / 10)) // This fits with the implementation of the chart library

          chartData[playerTag.title].push({
            label: tag.title,
            tooltip: barSum,
            values: {
              [results[playerTag.title][0].label]: results[playerTag.title][0].data[idx],
              [results[playerTag.title][1].label]: results[playerTag.title][1].data[idx],
              [results[playerTag.title][2].label]: results[playerTag.title][2].data[idx],
            },
          })
        })
      })

      return chartData;
    },

    updateTags: function (tag) {
      const index = this.selectedTags.indexOf(tag);
      if (index > -1) { // only splice array when item is found
        while (this.selectedTags.indexOf(tag) > -1) {
          this.selectedTags.splice(this.selectedTags.indexOf(tag), 1);
        }
      } else {
        this.selectedTags.push(tag)
      }
      this.setData()
    },
    addOrRemoveTag: function (tag) {
      const index = this.selectedTags.indexOf(tag);
      if (index > -1) { // only splice array when item is found
        this.$store.commit('removeTag', tag)
      } else {
        this.$store.commit('addTag', tag)
      }
    },
    addTag: function (tag) {
      const index = this.selectedTags.indexOf(tag);
      if (index === -1) {
        this.$store.commit('addTag', tag)
      }
    },
    handleImpulseChartClick(clickedZone, player) {
      // find all related tags for the selected zone
      let tagCategory = this.tagOptions.filter(item => item.title !== 'Custom').find(item => item.title === 'Spieler*in')
      const playerTag = tagCategory.tags.find(item => item.title === player)
      tagCategory = this.tagOptions.filter(item => item.title !== 'Custom').find(item => item.title === 'Impuls')
      const impulse = clickedZone.pitchImpulse === 'positive' ? 'Positiver Impuls' : 'Negativer Impuls'
      const impulseTag = tagCategory.tags.find(item => item.title === impulse)
      const pitchHalf = clickedZone.pitchHalf === 'placement' ? 'Schlagplatzierung Impuls' : 'Schlagzone Impuls'
      tagCategory = this.tagOptions.filter(item => item.title !== 'Custom').find(item => item.title === pitchHalf)
      const zoneTag = tagCategory.tags.find(item => item.title === clickedZone.pitchZone)
      // filter by tags
      if (zoneTag) {
        this.addTag(zoneTag)
      }
      this.addTag(playerTag)
      this.addTag(impulseTag)
    },
    handleImpactNumberBarClick(barValue) {
      const tagCategory = this.tagOptions.filter(item => item.title !== 'Custom').find(item => item.title === 'Cluster Schlagnummer')
      let tag = tagCategory.tags.find(item => item.title === barValue.group)

      this.addOrRemoveTag(tag)
      this.updateTags(tag)

    },

    handleBarZoneClick(barValue) {
      let tagCategory = this.tagOptions.filter(item => item.title !== 'Custom').find(item => item.title === 'Punkt/Fehler')
      const winOrLoseTag = tagCategory.tags.find(item => item.title === barValue.group)
      tagCategory = this.tagOptions.filter(item => item.title !== 'Custom').find(item => item.title === 'Zone')
      const zoneTag = tagCategory.tags.find(item => item.title === barValue.key)

      this.$store.commit('resetTags')
      this.addOrRemoveTag(winOrLoseTag)
      this.addOrRemoveTag(zoneTag)
      this.updateTags(winOrLoseTag)
      this.updateTags(zoneTag)
    }
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, minmax(300px, 1fr));
  grid-gap: 20px;
  padding: 0 20px 20px 20px;
}

.grid-item {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #f8f9fa;
}

.chart-card {
  height: 190px;
  margin-bottom: 24px;
}

.zone-card {
  height: 340px;
  margin-bottom: 24px;
}

.tall {
  grid-row: 1 / 4;
  grid-column: 4 / 4;
}

.wide {
  grid-column: span 2;
}

.x-wide {
  grid-column: span 3;
}

</style>
