<template>
  <div id="game-detail" class="container p-3">
    <div class="close-btn">
      <button @click="close">
        ❌
      </button>
    </div>
    <div class="form-group">
      <label>{{ game.tournament }}</label>
    </div>
    <div class="row">
      <div class="col-lg-6 mb-4">
        <ul class="list-group">
          <li class="list-group-item py-3 bg-light text-success">Videos & Analysen
            <button v-if="$store.state.user.role_name === 'admin' || $store.state.user.role_name === 'trainer'"
                    class="add-skill-btn btn" v-on:click='showAddVideo(game)'>
              <i class="fas fa-plus px-1"></i>
            </button>
          </li>
          <li v-for="video in game.videos" v-bind:key="video.id" class="list-group-item col-12">
            <div class="mb-2 word-wrap">{{ video.label }}</div>
            <div class="ml-auto">
              <span v-if="$store.state.game === 'badminton' && video.label === game.tournament">
                <router-link
                    :to="'/game/detail/' + game.id"
                    class="btn skill-btn utilius-btn mr-1" tag="button">
                  <i class="fas fa-chart-bar"></i>
                </router-link>
              </span>
              <router-link v-if="video.file_id" :to="'/video/' + video.id + '/analysis'" class="btn skill-btn" v-tooltip.top="tooltip.play">
                <img class="custom-icon" src="../../assets/img/video.svg" alt="video">
              </router-link>
              <router-link v-else :to="'/video/' + video.id" class="btn skill-btn" v-tooltip.top="tooltip.play">
                <img class="custom-icon" src="../../assets/img/video.svg" alt="video">
              </router-link>
              <button v-on:click="downloadVideo(video)" class="btn btn-secondary video-btn ml-1" v-tooltip.top="tooltip.downloadVideo">
                <img class="custom-icon" src="../../assets/img/download_video.svg" alt="video">
              </button>
              <button v-if="$store.state.user.role_name === 'admin' || $store.state.user.role_name === 'trainer'"
                      class="btn skill-btn ml-1" v-on:click='deleteVideo(video, game)'
                      v-tooltip.top="tooltip.deleteVideo"
              >
                <img class="custom-icon" src="../../assets/img/delete_video.svg" alt="video">
              </button>
              <button
                  @click="download({id: video.file_id, label: video.file_label, path: video.file_path, type: video.file_type})"
                  v-if="video.file_id" class="btn skill-btn ml-1"
                  :disabled="$store.state.user.role_name !== 'admin'"
                      v-tooltip.top="tooltip.downloadDartclip"
              >
                <img class="custom-icon" src="../../assets/img/download_datclip.svg" alt="video">
              </button>
              <button @click="showAddDartclip(video.id)" v-tooltip.top="tooltip.addDartclip"
                      v-else class="btn skill-btn ml-1">
                <i class="fas fa-plus"></i>
              </button>
              <button v-if="$store.state.user.role_name === 'admin' || $store.state.user.role_name === 'trainer'"
                      class="btn skill-btn ml-1" v-on:click='deleteVideoScenes(video, game)'
                      v-tooltip.top="tooltip.deleteDartclip"
              >
                <img class="custom-icon" src="../../assets/img/delete_dartclip.svg" alt="video">
              </button>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 mb-4">
        <ul class="list-group">
          <li class="list-group-item py-3 bg-light text-success">Anlagen (PDF, Excel, etc.)
            <button v-if="$store.state.user.role_name === 'admin' || $store.state.user.role_name === 'trainer'"
                    class="add-skill-btn btn" v-on:click='showAddPDFtoGame(game)'>
              <i class="fas fa-plus px-1"></i>
            </button>
          </li>
          <li v-for="file in game.files" v-bind:key="file.id" class="list-group-item d-flex">
            <div class="word-wrap col-9">{{ file.label }}</div>
            <div class="ml-auto">
              <a v-on:click="download(file)">
                <button class="btn skill-btn ml-2">
                  <i class="fas fa-paperclip"></i>
                </button>
              </a>
              <button class="btn skill-btn ml-1" v-on:click="deletePDF(file, game)">
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <modal name="add-video" :width="600" height="auto" :clickToClose="false">
      <video-upload objectType='App\Game'
                    :objectID="gameToAddVideoID"
                    :objectTitle="gameToAddVideoTitle"
                    @updateParent="addVideoToGame"
                    @close="hide('video')"
      ></video-upload>
    </modal>
    <modal name="uploadScenesModal" :width="600" height="auto" :clickToClose="false">
      <scene-upload-modal
          :video-id="videoToAddDartclip"
          @updateParent="addVideoToGame"
      />
    </modal>
    <modal name="add-pdf" :width="600" height="auto" :clickToClose="false">
      <div style="padding: 1rem">
        <div class="close-btn">
          <button @click="hide('pdf')">
            ❌
          </button>
        </div>
        <div class="form-group">
          <label for="pdf-input">Anlage zum Spiel hinzufügen</label>
          <div class="col-12 mb-4" @drop.prevent="addDroppedFile" @dragover.prevent>
            <input type="file" multiple v-if="!files.length" class="form-control mt-2" id="pdf-input"
                   v-on:change="addFile"
                   :accept="acceptedFiles"/>
            <div class="form-control py-5">
              <label><strong>Drag & Drop</strong></label>
            </div>
            <ul class="list-group">
              <li v-for="file in files" v-bind:key="file.name" class="list-group-item word-wrap">
                {{ file.name }}
                <button @click="removeFile(file)" class="btn skill-btn float-right ml-1">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>

        <button class="btn btn-primary add-skill-submit-btn" v-on:click="addPDFtoGame()">
          Hinzufügen
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import {cordovaUtils} from "@/utils/cordovaUtils";
import serverURL from "@/utils/serverURL";
import toast from "@/utils/toast"
import VideoUpload from "@/components/Video/VideoUpload";
import SceneUploadModal from "@/components/Scenes/SceneUploadModal";
import $ from "jquery";

export default {
  name: "GameDetail",
  components: {VideoUpload, SceneUploadModal},
  props: ['game'],
  data: function () {
    return {
      gameToAddVideoID: null,
      gameToAddVideoTitle: null,
      videoToAddDartclip: null,
      gameToAddPDF: null,
      pdfLabel: null,
      acceptedFiles: 'image/*,.pdf,.csv,.xlsx,.pptx,.docx,audio/*,text/xml',
      files: [],
      tooltip: {
        play: 'Video/Analyse öffnen',
        downloadVideo: 'Video herunterladen',
        deleteVideo: 'Video löschen',
        downloadDartclip: 'Dartclip herunterladen',
        deleteDartclip: 'Dartclip löschen',
        addDartclip: 'Dartclip hinzufügen',
      },
    }
  },
  methods: {
    close() {
      this.$emit('modalClosed');
      this.$modal.hide('game-detail');
    },
    download(file) {
      if (cordovaUtils.isCordova()) {
        cordovaUtils.viewDocument(file.label + '.' + file.path.split('.').pop(), 'application/pdf', '', '', '', '', this.onError(file));
      } else if (cordovaUtils.isOnline()) {
        $.ajax({
          type: 'GET',
          url: serverURL + 'api/file/download/' + file.id,
          headers: {
            "Authorization": "Bearer " + this.$store.state.token,
          },
          xhrFields: {
            responseType: 'blob'
          },
          success: function (blob) {
            let link = document.createElement('a');
            link.setAttribute("type", "hidden");
            document.body.appendChild(link);
            link.href = window.URL.createObjectURL(blob);
            link.download = file.label.includes(file.type) ? file.label : file.label + '.' + file.type;
            link.click();
          },
          error: function () {
          }
        });
      }
    },
    downloadVideo(video) {
      const result = confirm("Möchten Sie das Video wirklich herunterladen?");
      if (!result)
        return 0;

      toast.success('Download gestartet')
      if (this.xhr) {
        this.xhr.abort();
      } else {
        let self = this;
        if (cordovaUtils.isCordova() && (cordovaUtils.isOnline() || this.$store.state.downloaded_videos.includes(video.id))) {
          this.$store.commit("addActiveNetworkTask", 1);
          cordovaUtils.downloadFile(video.absolute_path, video.label + '.' + video.path.split('.').slice(-1)[0]).then(function () {
            self.$store.commit("addActiveNetworkTask", -1);
          })
        } else if (cordovaUtils.isOnline()) {
          this.xhr = $.ajax({
            type: 'GET',
            url: serverURL + 'api/video/download/' + video.id,
            headers: {
              "Authorization": "Bearer " + this.$store.state.token,
            },
            xhrFields: {
              responseType: 'blob'
            },
            success: function (blob) {
              if (self.xhr) {
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = video.label + ".mp4";
                link.setAttribute("type", "hidden");
                document.body.appendChild(link);
                link.click();
                self.xhr = null;
              }
            },
            error: function () {
              self.xhr = null;
            }
          });
        }

      }
    },
    onError(file) {
      if (cordovaUtils.isOnline()) {
        let self = this;
        let fileURL = this.serverURL + file.path;
        this.$store.commit("addActiveNetworkTask", 1);
        cordovaUtils.downloadFile(fileURL, file.label + '.' + file.path.split('.').pop()).then(function () {
          self.$store.commit("addActiveNetworkTask", -1);
          toast.success(file.label + " heruntergeladen");
        });
      }
    },
    showAddVideo(game) {
      if (cordovaUtils.isOfflineCancel()) {
        return 0;
      }
      this.gameToAddVideoID = game.id;
      this.gameToAddVideoTitle = game.tournament;
      this.$modal.show('add-video', {});
    },
    showAddDartclip(videoId) {
      this.videoToAddDartclip = videoId;
      this.$modal.show('uploadScenesModal', videoId);
    },
    addVideoToGame(updateGameID, video) {
      if (this.game.videos === null) {
        // eslint-disable-next-line vue/no-mutating-props
        this.game.videos = [];
      }
      if (video.files && video.files.length) {
        let file = video.files[0]
        let vidoeoIndex = this.game.videos.findIndex(gameVideo => gameVideo.id === video.id)
        if (vidoeoIndex === -1) {
          // eslint-disable-next-line vue/no-mutating-props
          this.game.videos.push({
            'id': video.id,
            'label': video.label,
            'file_id': file.id,
            'file_label': file.label,
            'file_path': file.path,
            'file_type': file.type
          });
        } else {
          // eslint-disable-next-line vue/no-mutating-props
          this.game.videos[vidoeoIndex].file_id = file.id
          // eslint-disable-next-line vue/no-mutating-props
          this.game.videos[vidoeoIndex].file_label = file.label
          // eslint-disable-next-line vue/no-mutating-props
          this.game.videos[vidoeoIndex].file_path = file.path
          // eslint-disable-next-line vue/no-mutating-props
          this.game.videos[vidoeoIndex].file_type = file.type
        }
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.game.videos.push({'id': video.id, 'label': video.label})
      }
    },
    showAddPDFtoGame(game) {
      if (cordovaUtils.isOfflineCancel()) {
        return 0;
      }
      this.$modal.show('add-pdf', {});
      this.gameToAddPDF = game.id;
    },
    addPDFtoGame() {
      let self = this;
      let i = 0;
      this.files.forEach((file) => {
        let formData = new FormData();
        formData.append('file', file);
        this.$api.uploadFile({
          data: formData
        }).then(response => {
              this.$api.storeFile({
                data: {
                  fileable_id: this.gameToAddPDF,
                  fileable_type: "App\\Game",
                  label: file.name,
                  type: file.name.split('.').pop(),
                  path: response.data.replace("public", "storage")
                }
              }).then((fileable_response) => {
                self.$set(this.game, 'files', fileable_response.data.files)
                i += 1;
                if (i === self.files.length) {
                  self.isUploading = false;
                  self.hide('pdf');
                }
              });
            }
        );
      })
    },
    hide(modal) {
      if (modal === 'pdf') {
        this.files = [];
      }
      this.$modal.hide('add-' + modal);
    },
    deleteVideo(video, game) {
      const result = confirm("Möchten sie wirklich das Video \"" + video.label + "\" aus der Datenbank löschen?");
      if (result) {
        if (cordovaUtils.isOfflineCancel()) {
          return 0;
        }
        this.$api.destroyVideo({
          segments: {
            vid: video.id,
          }
        }).then(function () {
          let index = -1;
          for (let i = 0; i < game.videos.length; i++) {
            if (game.videos[i].id === video.id) {
              index = i;
              break;
            }
          }
          toast.success('Video gelöscht!');
          game.videos.splice(index, 1);
        }.bind(this, video));
      }
    },
    deleteVideoScenes(video) {
      const result = confirm("Möchten sie wirklich die Szenen für das Video \"" + video.label + "\" aus der Datenbank löschen?");
      if (result) {
        if (cordovaUtils.isOfflineCancel()) {
          return 0;
        }
        this.$api.destroyVideoScenes({
          segments: {
            vid: video.id,
          }
        }).then(function () {
          video.file_id = null
          video.file_label = null
          video.file_path = null
          video.file_type = null

          toast.success('Szenen gelöscht!');
        }.bind(this, video));
      }
    },
    deletePDF(file, game) {
      const result = confirm("Möchten sie wirklich den Anhang \"" + file.label + "\" aus der Datenbank löschen?");
      if (result) {
        if (cordovaUtils.isOfflineCancel()) {
          return 0;
        }
        this.$api.deleteFile({
          segments: {
            file: file.id,
          }
        }).then(function () {
          let index = -1;
          for (let i = 0; i < game.files.length; i++) {
            if (game.files[i].id === file.id) {
              index = i;
              break;
            }
          }
          toast.success('Anlage gelöscht!');
          game.files.splice(index, 1);
        });
      }
    },
    addDroppedFile(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      ([...droppedFiles]).forEach(f => {
        this.files.push(f);
      });
    },
    addFile(e) {
      let files = e.target.files;
      if (!files) return;
      ([...files]).forEach(f => {
        this.files.push(f);
      })
    },
    removeFile(file) {
      this.files = this.files.filter(f => {
        return f !== file;
      });
    },
  },
}
</script>

<style scoped>
.word-wrap {
  word-break: break-word;
}

.custom-icon {
  width: 25px;
  height: 25px;
}
</style>
