<template>
  <div class="row m-0 p-2 controls" id="video-controls" :class="{ 'ios-style': isSafariIos, 'video-controls': !$store.state.isFullscreen }">
    <div class="progress col-12 p-0" id="progress-bar" style="height: 10px;">
      <div :aria-valuenow="progress" :style="{width: progress + '%'}" aria-valuemax="100"
           aria-valuemin="0" class="progress-bar" role="progressbar"></div>
      <div class="overlay-marker"
           v-for="(overlay) in visibleOverlays"
           v-bind:key="overlay.id"
           v-on:click="gotoOverlay(overlay)"
           :style="{left: setMarkerPosition(overlay.start) + '%'}"
           @mouseover="isHovering = overlay.id" @mouseleave="isHovering = false">
      </div>
      <div v-if="activeScene">
        <div class="active-scene-marker" v-if="setMarkerPosition(activeScene.start / 1000)"
             :style="{left: setMarkerPosition(activeScene.start / 1000) + '%'}"
        >
        </div>
        <div class="active-scene-marker" v-if="setMarkerPosition(activeScene.end / 1000)"
             :style="{left: setMarkerPosition(activeScene.end / 1000) + '%'}"
        >
        </div>
      </div>
      <div v-if="taggingStart">
        <div class="active-tagging-marker"
             :style="{left: setMarkerPosition(taggingStart / 1000) + '%'}"
        >
        </div>
      </div>
      <div v-if="taggingStop">
        <div class="active-tagging-marker"
             :style="{left: setMarkerPosition(taggingStop / 1000) + '%'}"
        >
        </div>
      </div>
    </div>

    <div v-bind:key="overlay.id" v-for="(overlay) in visibleOverlays">
      <div class="marker-information"
           v-if="isHovering === overlay.id"
           :style="{left: setMarkerPosition(overlay.start) + '%'}">
        {{ overlay.label }}
        <div class="marker-triangle"></div>
      </div>
    </div>

    <button @click="reverseVid()" class="btn btn-secondary video-btn col-1" type="button"
            v-tooltip.top-center="tooltip.back">
      <i class="fas fa-backward"></i>
    </button>

    <button @click="changeFrame(-1)" class="btn btn-secondary video-btn col-1" type="button"
            v-tooltip.top-center="tooltip.leftFrame">
      <i class="fas fa-step-backward"></i>
    </button>

    <button @click="toggleVideoIsPlaying()" class="btn btn-secondary video-btn col-1" type="button"
            v-tooltip.top-center="tooltip.play">
      <i :class="{'fas': true,  'fa-play-circle': !videoIsPlaying, 'fa-pause-circle': videoIsPlaying}"></i>
    </button>

    <button @click="changeFrame(1)" class="btn btn-secondary video-btn col-1" type="button"
            v-tooltip.top-center="tooltip.rightFrame">
      <i class="fas fa-step-forward"></i>
    </button>

    <div class="dropup video-btn">
      <button aria-expanded="false" aria-haspopup="true"
              class="btn dropdown-toggle btn-secondary" data-toggle="dropdown" id="videoSpeedDropdown"
              type="button" v-tooltip.top-center="tooltip.speed">
        {{ playbackRate }}x
      </button>

      <div aria-labelledby="videoSpeedDropdown" class="dropdown-menu">
        <button @click="setSpeed(2)" class="dropdown-item">2x</button>
        <button @click="setSpeed(1)" class="dropdown-item">1x</button>
        <button @click="setSpeed(0.5)" class="dropdown-item">0,5x</button>
        <button @click="setSpeed(0.25)" class="dropdown-item">0,25x</button>
      </div>
    </div>
    <div class="text-light my-auto ml-2 small">
      {{ videoTag ? calculateTime(videoTag.currentTime) : '' }} /
      {{ videoTag ? calculateTime(videoTag.duration) : '' }}
    </div>

    <button v-if="activeOverlay" @click="activateLoop = !activateLoop, activeLoopOverlay = activeOverlay"
            class="btn btn-secondary video-btn col-1"
            :class="{'btn-success': activateLoop}" type="button">
      <i class="fas fa-undo"></i>
    </button>

    <div id="sceneControls" class="dropup ml-auto d-flex" v-if="scenes">
      <button v-on:click="nextSceneGoto(-1)" v-tooltip.top-center="tooltip.preScene"
              class="btn btn-secondary video-btn">
        <i class="fas fa-chevron-circle-left"></i></button>

      <button aria-expanded="false" aria-haspopup="true"
              class="btn dropdown-toggle btn-secondary video-btn" data-toggle="dropdown" id="scenesDropdown"
              type="button">
        {{ sceneLabel }}
      </button>

      <button v-on:click="nextSceneGoto(1)" v-tooltip.top-center="tooltip.nextScene"
              class="btn btn-secondary video-btn">
        <i class="fas fa-chevron-circle-right"></i></button>

      <div aria-labelledby="scenesDropdown" class="dropdown-menu overflow-auto" style="height: 200px">
        <button @click="gotoScene(scene)"
                class="dropdown-item"
                v-bind:key="scene.id"
                v-for="scene in processedScenes()">
          Szene: {{ scene.id }}
        </button>
      </div>


    </div>

    <div id="overlayControls" class="ml-auto ">
      <div v-if="overlayData && !$store.state.isFullscreen" class="dropup ml-auto video-btn controls-mobile-none">
        <button aria-expanded="false" aria-haspopup="true" class="btn dropdown-toggle btn-secondary"
                data-offset="11" data-toggle="dropdown" id="overlayDropdown" type="button">
          <i class="fas fa-pen"></i>
        </button>

        <div aria-labelledby="overlayDropdown" class="dropdown-menu dropdown-menu-right" id="overlays">
          <div v-if="overlayData">
            <button class="dropdown-item" v-bind:key="overlay.id"
                    v-for="(overlay, index) in visibleOverlays">
              <div v-on:click="gotoOverlay(overlay)">
                {{ overlay.label ? overlay.label : '-' }}
              </div>
              <span v-if="$store.state.user.role_name === 'admin' || $store.state.user.user_id === overlay.user_id"
                    class="d-none float-right" @click="editOverlay(overlay)">
                            <i class="fas fa-edit"></i>
                        </span>
              <span v-if="$store.state.user.role_name === 'admin' || $store.state.user.user_id === overlay.user_id"
                    class="d-none float-right" @click="deleteOverlay(overlay.id); deleteButton(index)">
                            <i class="fas fa-trash-alt text-danger"></i>
                        </span>
            </button>
          </div>
          <button @click="showAddOverlayModal()" class="dropdown-item">+ Neue Markierung</button>
        </div>
      </div>
        <button class="btn btn-secondary video-btn scene-button"
                type="button"
                @click="()=>this.$emit('input', this.currentSceneIndex - 1)"
                v-tooltip.right="tooltip.prevScene"
        >
            <i class="fas fa-chevron-circle-up"></i>
        </button>
        <button class="btn btn-secondary video-btn scene-button"
                @click="()=>this.$emit('input', this.currentSceneIndex + 1)"
                v-tooltip.right="tooltip.nextScene"
        >
            <i class="fas fa-chevron-circle-down"></i>
        </button>

        <button @click="previousOverlay()" class="btn btn-secondary video-btn controls-mobile-none" type="button"
              v-tooltip.top-center="tooltip.preOverlay">
        <i class="fas fa-fast-backward"></i>
      </button>

      <button @click="nextOverlay()" class="btn btn-secondary video-btn controls-mobile-none" type="button"
              v-tooltip.top-center="tooltip.next_overlay">
        <i class="fas fa-fast-forward"></i>
      </button>
      <div class="fullscreen-btn">
        <button @click="enterFullscreen()" class="btn btn-secondary video-btn" type="button">
          <i class="fas fa-expand"></i>
        </button>
      </div>
    </div>
    <modal name="add-overlay" :height="200" :width="300" :clickToClose="false" :pivotY="is_cordova ? 0.1 : 0.5"
           :adaptive="true">
      <overlay-adding-modal
          v-if="videoTag"
          :start="videoTag.currentTime"
          @updateOverlays="addOverlayToVideo"
      >
      </overlay-adding-modal>
    </modal>


  </div>

</template>

<script>
import 'bootstrap/js/dist/dropdown';
import toast from '../../utils/toast';
import serverURL from '../../utils/serverURL';
import OverlayAddingModal from './../Video/OverlayAddingModal';
import {cordovaUtils} from './../../utils/cordovaUtils.jsx';

var $ = require('jquery')


export default {
  name: 'VideoControls',
  components: {OverlayAddingModal},
  props: ['scenes', 'overlayData', 'activeOverlay', 'video', 'activeScene', 'taggingStart', 'taggingStop', 'value'],
  data: function () {
    return {
      videoTag: null,
      videoIsPlaying: false,
      playbackRate: 1,
      progress: 0,
      intervalRewind: null,
      sceneLabel: "Szene",
      processedScenesCache: null,
      currentScene: null,
      visibleOverlays: this.scenes ? this.overlayInScene() : this.overlayData,
      isHovering: false,
      activateLoop: false,
      activeLoopOverlay: null,
      is_cordova: !!window.cordova,
      pressedKeys: {},
      currentSceneIndex: 0,
      tooltip: {
        play: '(p)',
        speed: 'Geschwindigkeit (Strg + &#8593; / Strg + &#8595;)',
        leftFrame: 'Vorheriges Bild (&#8592;)',
        rightFrame: 'Nächstes Bild (&#8594;)',
        preOverlay: 'Vorherige Markierung (j)',
        next_overlay: 'Nächste Markierung (k)',
        back: 'Zurückspulen (r)',
        preScene: 'Vorherige Szene (n)',
        nextScene: 'Nächste Szene (m)',
        download: 'Herunterladen',
      },
      xhr: null,
    }
  },
  mounted: function () {
    let progressBar = document.getElementById("progress-bar");
    let self = this;
    progressBar.addEventListener('click', function (event) {
      let progress = (event.pageX - self.getPosition(this).x) / this.offsetWidth;
      if (self.scenes) {
        self.videoTag.currentTime = self.currentScene.start + progress * self.currentScene.duration;
      } else {
        self.videoTag.currentTime = progress * document.getElementById("video").duration;
      }
    });
    // Hide controls when exiting fullscreen
    document.addEventListener("fullscreenchange", function () {
      if (document.fullscreenElement == null) {
        document.getElementById("video").controls = false;
      }
    }, false);
    document.addEventListener("keydown", function (event) {
      if (!['TEXTAREA', 'INPUT'].includes(document.activeElement.tagName)) {
        self.pressedKeys[event.key] = true;
        if (self.pressedKeys['Control'] && event.key === 'ArrowDown') {
          if (self.playbackRate > 0.25) {
            self.playbackRate /= 2;
            self.videoTag.playbackRate = self.playbackRate;
          } else {
            self.playbackRate, self.videoTag.playbackRate = 0.125;
          }
        } else if (self.pressedKeys['Control'] && event.key === 'ArrowUp') {
          if (self.playbackRate < 2) {
            self.playbackRate *= 2;
            self.videoTag.playbackRate = self.playbackRate;
          } else {
            self.playbackRate, self.videoTag.playbackRate = 16;
          }
        }
        switch (event.key) {
          case "p":
            self.toggleVideoIsPlaying();
            break;
          case "ArrowLeft":
            self.changeFrame(-1);
            break;
          case "ArrowRight":
            self.changeFrame(1);
            break;
          case "r":
            self.reverseVid();
            break;
          case "j":
            self.previousOverlay();
            break;
          case "k":
            self.nextOverlay();
            break;
          case "n":
            self.nextSceneGoto(-1);
            break;
          case "m":
            self.nextSceneGoto(1);
            break;
        }
      }
    })
    document.addEventListener('keyup', (event) => {
      delete this.pressedKeys[event.key];
    });
    this.$root.$on('jumpToTimestamp', (timestamp) => {
      this.goToSceneByTimestamp(timestamp);
    })
  },
  computed: {
    isfullScreen() {
      return (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msFullscreenElement
      );
    }
  },
  watch: {
    currentScene() {
      this.visibleOverlays = this.overlayInScene();
    },
    overlayData() {
      let self = this;
      if (this.scenes) {
        this.visibleOverlays = this.overlayInScene();
      } else {
        this.visibleOverlays = [];
        this.overlayData.forEach(function (overlay) {
          self.visibleOverlays.push(overlay)
        })
      }
      this.visibleOverlays = this.visibleOverlays.sort((a, b) => (a.start > b.start) ? 1 : -1)
    },
    value(newValue) {
      this.currentSceneIndex = newValue
    }
  },
  methods: {
    onCancel() {

    },
    download() {
      const result = confirm("Möchten Sie das Video wirklich herunterladen?");
      if (!result)
        return 0;

      toast.success('Download gestartet')
      if (this.xhr) {
        this.xhr.abort();
      } else {
        let self = this;
        if (cordovaUtils.isCordova() && (cordovaUtils.isOnline() || this.$store.state.downloaded_videos.includes(this.video.id))) {
          this.$store.commit("addActiveNetworkTask", 1);
          cordovaUtils.downloadFile(self.video.absolute_path, self.video.label + '.' + self.video.path.split('.').slice(-1)[0]).then(function () {
            self.$store.commit("addActiveNetworkTask", -1);
          })
        } else if (cordovaUtils.isOnline()) {
          this.xhr = $.ajax({
            type: 'GET',
            url: serverURL + 'api/video/download/' + this.$route.params.id,
            headers: {
              "Authorization": "Bearer " + this.$store.state.token,
            },
            xhrFields: {
              responseType: 'blob'
            },
            success: function (blob) {
              if (self.xhr) {
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = self.video.label + ".mp4";
                link.setAttribute("type", "hidden");
                document.body.appendChild(link);
                link.click();
                self.xhr = null;
              }
            },
            error: function () {
              self.xhr = null;
            }
          });
        }

      }


    },
    getPosition(el) {
      var xPos = 0;
      var yPos = 0;

      while (el) {
        if (el.tagName == "BODY") {
          // deal with browser quirks with body/window/document and page scroll
          var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
          var yScroll = el.scrollTop || document.documentElement.scrollTop;

          xPos += (el.offsetLeft - xScroll + el.clientLeft);
          yPos += (el.offsetTop - yScroll + el.clientTop);
        } else {
          // for all other non-BODY elements
          xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
          yPos += (el.offsetTop - el.scrollTop + el.clientTop);
        }

        el = el.offsetParent;
      }
      return {
        x: xPos,
        y: yPos
      };
    },
    overlayInScene() {
      let self = this;

      return (this.overlayData ? this.overlayData : []).filter(
          overlay => (overlay.start >= self.currentScene.start
              && overlay.start <= self.currentScene.start + self.currentScene.duration))

    },
    gotoOverlay: function (overlay) {
      let self = this;
      setTimeout(function () {
        self.videoTag.currentTime = overlay.start + 0.01;
      }, 50)
    },
    nextOverlay: function () {
      let self = this;
      let nextOverlay = self.visibleOverlays.find(function (overlay) {
        return overlay.start > self.videoTag.currentTime;
      });

      if (nextOverlay) {
        this.videoTag.currentTime = nextOverlay.start + 0.001;
      }

    },
    previousOverlay: function () {
      let self = this;
      let prevOverlay = self.visibleOverlays.filter(function (overlay) {
        return overlay.start < self.videoTag.currentTime - 0.001;
      });
      if (prevOverlay.length) {
        this.videoTag.currentTime = prevOverlay[prevOverlay.length - 1].start;
      }
    },
    videoIsEmbedded: function (videoTag) {
      this.videoTag = videoTag;
      this.videoTag.addEventListener('timeupdate', this.updateProgressBar, false);

      if (this.scenes) {

        this.gotoScene(this.processedScenes()[0]);

      }
    },
    toggleVideoIsPlaying: function () {
      clearInterval(this.intervalRewind);
      if (this.videoIsPlaying) {
        this.videoTag.pause();
      } else {
        this.videoTag.play();
      }
      this.videoIsPlaying = !this.videoIsPlaying;
    },
    changeFrame: function (frames) {

      clearInterval(this.intervalRewind);
      this.videoTag.pause();
      this.videoIsPlaying = false;
      this.videoTag.currentTime += 0.2 * frames;
    },
    reverseVid: function () {
      const self = this;
      clearInterval(self.intervalRewind);
      self.intervalRewind = setInterval(function () {
        self.setSpeed(1);
        self.videoIsPlaying = true;
        if (self.videoTag.currentTime === 0) {
          clearInterval(self.intervalRewind);
          self.videoTag.pause();
          self.videoIsPlaying = false;
        } else {
          self.videoTag.currentTime -= .12;
        }
      }, 240);
    },
    setSpeed: function (playbackRate) {
      this.playbackRate = playbackRate;
      this.videoTag.playbackRate = playbackRate;
    },
    updateProgressBar: function () {
      if (this.scenes) {
        if (this.videoTag.currentTime > (this.currentScene.start + this.currentScene.duration + 1)) {
          let s = this.processedScenes().find(x => x.index === this.currentScene.index + 1);
          if (s) {
            this.gotoScene(s)
          } else {
            this.videoTag.pause();
            this.videoIsPlaying = false;
          }
        } else if (this.videoTag.currentTime < (this.currentScene.start - 0.5)) {
          let s = this.processedScenes().find(x => x.index === this.currentScene.index - 1);
          if (s) {
            this.gotoScene(s, true)
          } else {
            let s = this.processedScenes().find(x => x.index === this.currentScene.index);
            this.gotoScene(s)
          }
        }
        this.progress = (100 / this.currentScene.duration) * (this.videoTag.currentTime - this.currentScene.start);
      } else {
        this.progress = (100 / this.videoTag.duration) * this.videoTag.currentTime;
        if (this.progress === 100 && this.playbackRate > 0) {
          this.videoIsPlaying = false;
        }
      }
      //TODO: Fix Video Buffer Problem
      /*    if (this.videoTag.currentTime >= this.videoTag.buffered.end(this.videoTag.buffered.length - 1) - 1) {
              this.videoTag.pause();
              this.videoIsPlaying = false;
          }*/

      this.$emit('videoTimeChanged', this.videoTag.currentTime);
      if (this.activateLoop) {
        this.loopOverlay();
      }
    },
    showAddOverlayModal: function () {
      if (cordovaUtils.isOfflineCancel()) {
        return 0;
      }
      this.$modal.show('add-overlay', {});
    },
    addOverlayToVideo: function (overlay) {
      this.$modal.hide('add-overlay', {});
      this.videoTag.currentTime = overlay.start;
      this.$emit('addOverlay', overlay);
      this.showDrawingTools();
    },
    editOverlay: function (overlay) {
      this.videoTag.currentTime = overlay.start;
      this.$emit('editOverlay', overlay);
      this.showDrawingTools();
    },
    showDrawingTools: function () {
      clearInterval(this.intervalRewind);
      this.videoTag.pause();
      this.videoIsPlaying = false;
      this.$emit('controlsSwitched');
    },
    deleteOverlay: function (id) {
      const result = confirm("Möchten sie die Markierung wirklich aus der Datenbank löschen?");
      if (result) {
        this.$api.deleteOverlay({
          segments: {
            vId: this.$route.params.id,
            oId: id.toString(),
          }
        }).then(toast.success('Markierung gelöscht'));
      }
    },
    deleteButton: function (index) {
      // eslint-disable-next-line vue/no-mutating-props
      this.overlayData.splice(index, 1)
    },
    setMarkerPosition: function (start) {
      if (this.currentScene) {
        return (start - this.currentScene.start) / this.currentScene.duration * 100
      } else {
        return (start / this.videoTag.duration) * 100
      }
    },
    loopOverlay: function () {
      if (typeof this.activeLoopOverlay !== "undefined") {
        if (this.videoTag.currentTime > this.activeLoopOverlay.end) {
          this.videoTag.currentTime = this.activeLoopOverlay.start;
          this.toggleVideoIsPlaying();
        }
      }
    },
    processedScenes: function () {

      if (this.processedScenesCache) {
        return this.processedScenesCache
      }
      let ps = [];
      this.scenes.split(';').forEach(function (scene, index) {
        let a = scene.split(':');
        let b = a[1].split(',');
        ps.push({
          index: index,
          id: parseInt(a[0]),
          start: parseInt(b[0]),
          duration: parseInt(b[1])
        })
      });
      this.processedScenesCache = ps;
      return ps;
    },
    gotoScene: function (scene, toEnd) {
      this.currentScene = scene;
      this.sceneLabel = "(" + this.currentScene.id + ")";
      if (toEnd) {
        this.videoTag.currentTime = this.currentScene.start + this.currentScene.duration;
      } else {
        this.videoTag.currentTime = this.currentScene.start + 0.001;
      }
    },
    nextSceneGoto: function (drift) {
      if (this.scenes) {
        let self = this;
        let ps = this.processedScenes();
        for (let i = 0; i < ps.length; i++) {
          if (ps[i].id === self.currentScene.id && !(typeof ps[i + drift] === "undefined")) {
            self.gotoScene(ps[i + drift]);
            return 0;
          }
        }
      }
    },
    pauseVideo: function (lastTime) {
      this.videoTag.pause();
      this.videoIsPlaying = false;
      this.videoTag.currentTime = lastTime;
    },
    goToSceneByTimestamp(timestamp) {
      let s = this.processedScenes().find(x => ((x.start <= timestamp) && (x.start + x.duration >= timestamp)));
      if (typeof (s) !== 'undefined') {
        this.gotoScene(s);
        this.videoTag.currentTime = timestamp;
      }
    },
    enterFullscreen() {
      if (this.isSafariIos()) {
        this.$store.state.isFullscreen = !this.$store.state.isFullscreen;
      } else {
        let videoContainer = document.getElementById("video-container")
        let controls = document.getElementById("video-controls")
        let videoElem = document.getElementById("video-element")
        let annotations = document.getElementById("annotations")
        if (document.fullscreenElement) {
          document.exitFullscreen();
          controls.classList.remove('fullscreen-controls')
          annotations.classList.remove('hidden')
          controls.classList.add('video-controls')
          videoElem.classList.add('video-element')
        } else if (document.webkitFullscreenElement) {
          // Need this to support Safari
          document.webkitExitFullscreen();
          controls.classList.remove('fullscreen-controls')
          annotations.classList.remove('hidden')
          controls.classList.add('video-controls')
          videoElem.classList.add('video-element')
        } else if (videoContainer.webkitRequestFullscreen) {
          // Need this to support Safari
          videoContainer.webkitRequestFullscreen();
          controls.classList.add('fullscreen-controls')
          annotations.classList.add('hidden')
          controls.classList.remove('video-controls')
          videoElem.classList.remove('video-element')
        } else {
          videoContainer.requestFullscreen();
          controls.classList.add('fullscreen-controls')
          annotations.classList.add('hidden')
          controls.classList.remove('video-controls')
          videoElem.classList.remove('video-element')
        }
      }
    },
    calculateTime(time) {
      if (time) {
        let hours = Math.floor(time / 6000)
        let minutes = Math.floor(time / 60);
        let seconds = Math.floor(time % 60);
        return hours ? (hours < 10 ? '0' + hours : hours) + ":" + (minutes < 10 ? '0' + minutes : minutes) + ":" + (seconds < 10 ? '0' + seconds : seconds) :
            (minutes < 10 ? '0' + minutes : minutes) + ":" + (seconds < 10 ? '0' + seconds : seconds)
      }
      return '00:00'
    },
    isSafariIos: function () {
      const userAgent = navigator.userAgent;
      return /iPhone|iPad|iPod/.test(userAgent) && /Safari/.test(userAgent) && !/Chrome|CriOS|OPiOS|mercury/.test(userAgent);
    },
  }
}
</script>
<style>
.ios-style {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(30, 30, 30, .7);
}
</style>
