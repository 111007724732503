import client from 'axios'
import axios from 'axios'
import Vue from 'vue'
import VueApiService from 'vue-api-service'
import toast from './toast'
import serverURL from './serverURL'
import vueStore from './store'
import VueCookies from 'vue-cookies'
import sceneEndpoints from "@/utils/api/scenes";

const api = {
    initialize: function (store) {
        axios.defaults.baseURL = serverURL;

        const endpoints = {
            // TODO: remove later
            // getFilesByIDs: {
            //     method: 'POST',
            //     url: '/api/file/get_by_ids',
            // },
            storeFile: {
                method: 'POST',
                url: '/api/file/store',
            },
            uploadFile: {
                method: 'POST',
                url: '/api/file/upload',
            },
            deleteFile: {
                method: 'DELETE',
                url: '/api/file/:file',
            },
            login: {
                method: 'POST',
                url: '/api/login'
            },
            getPlayerList: {
                method: 'GET',
                url: '/api/player?sport=:sport'
            },
            getPlayerDetail: {
                method: 'GET',
                url: 'api/player/:id'
            },
            // getPlayerGames: {
            //     method: 'GET',
            //     url: 'api/player/:id/games'
            // },
            getVideoDetail: {
                method: 'GET',
                url: 'api/video/:id'
            },
            getImportableGames: {
                method: 'GET',
                url: 'api/import/game/get_pending/:sport'
            },
            importGame: {
                method: 'POST',
                url: 'api/import/game'
            },
            getGameDetail: {
                method: 'GET',
                url: 'api/game/:id'
            },
            createNewGame: {
                method: 'POST',
                url: 'api/game/'
            },
            updateGame: {
                method: 'PUT',
                url: 'api/game/:id'
            },
            deleteGame: {
                method: 'DELETE',
                url: 'api/game/:id'
            },
            createUserGame: {
                method: 'POST',
                url: 'api/user/game'
            },
            createNewOverlay: {
                method: 'POST',
                url: 'api/video/:id/overlay'
            },
            createNewMarker: {
                method: 'POST',
                url: '/api/video/:vId/overlay/:oId/marker'
            },
            createNewAnnotation: {
                method: 'POST',
                url: '/api/annotation/'
            },
            updateAnnotation: {
                method: 'POST',
                url: '/api/annotation/:id'
            },
            deleteAnnotation: {
                method: 'DELETE',
                url: '/api/annotation/:aId'
            },
            deleteOverlay: {
                method: 'DELETE',
                url: '/api/video/:vId/overlay/:oId'
            },
            updateOverlay: {
                method: 'POST',
                url: '/api/video/:vId/overlay/:oId'
            },
            storePlayer: {
                method: 'POST',
                url: 'api/player/',
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            },
            updatePlayer: {
                method: 'POST',
                url: 'api/player/:id',
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            },
            destroyPlayer: {
                method: 'DELETE',
                url: 'api/player/:id',
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            },
            destroySkill: {
                method: 'DELETE',
                url: 'api/player/:pid/skill/:sid',
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            },
            destroyVideo: {
                method: 'DELETE',
                url: 'api/video/:vid',
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            },
            destroyVideoScenes: {
                method: 'DELETE',
                url: 'api/video/:vid/scenes',
            },
            createTeam: {
                method: 'POST',
                url: 'api/player/:id/team'
            },
            deleteTeam: {
                method: 'DELETE',
                url: 'api/player/:pid/team/:tid',
            },
            uploadVideo: {
                method: 'POST',
                url: 'api/video/upload',
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            },
            storeVideo: {
                method: 'POST',
                url: 'api/video',
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            },
            updateVideo: {
                method: 'PUT',
                url: 'api/video/:id',
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            },
            uploadAnnotationFile: {
                method: 'POST',
                url: 'api/annotation/upload',
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            },
            createPlayerSkill: {
                method: 'POST',
                url: '/api/player/:id/skill',
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            },
            updatePlayerSkill:{
                method: 'POST',
                url: '/api/player/:pid/skill/:sid',
            },
            getCustomPlayerList: {
                method: 'GET',
                url: '/api/players/:players',
            },
            getCustomVideoList: {
                method: 'GET',
                url: '/api/videos/:videos',
            },
            getCustomGameList: {
                method: 'GET',
                url: '/api/games/:games',
            },
            getUserList: {
                method: 'GET',
                url: '/api/user',
            },
            createUser: {
                method: 'POST',
                url: '/api/user',
            },
            deleteMultipleUser: {
                method: 'Delete',
                url: '/api/user',
            },
            updateUser: {
                method: 'POST',
                url: '/api/user/:user',
            },
            ...sceneEndpoints,
        };

        Vue.use(VueApiService, {
            client,
            endpoints
        });
        // Define every request as JSON
        axios.interceptors.request.use(function (config) {
            if (typeof config.headers === "undefined") {
                config.headers = {};
            }
            config.headers.accept = "application/json";

            let token = vueStore.state.token;
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }

            config.onUploadProgress = function (progressEvent) {
                let uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                store.commit('setUploadPercentage', uploadPercentage);
            }.bind(this)

            store.commit('addActiveNetworkTask', 1);
            return config;
        }, function (err) {
            return Promise.reject(err);
        });

        // Global error handling
        axios.interceptors.response.use(
            function (response) {
                store.commit('addActiveNetworkTask', -1);
                return response;
            },
            function (error) {

                store.commit('addActiveNetworkTask', -1);
                if (error.response) {
                    //auto-logout if unauthenticated
                    if (error.response.status === 401) {
                        vueStore.state.token = false;
                        error.response.data.message = 'Sie haben sich auf einem anderen Gerät angemeldet'
                    }

                    let message = "Es ist ein Netzwerkfehler aufgetreten.";
                    if (error.response.data.message) {
                        message += " (" + error.response.data.message + ")";
                    }
                    toast.error(message);
                    VueCookies.remove('user');
                }
                return error;
            }
        );
    }
};

export default api;




