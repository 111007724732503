<template>
  <div id="skillEdit">
    <div class="container">
      <div class="row">
        <div class="close-btn">
          <button @click="close">
            ❌
          </button>
        </div>
        <label class="file-label">Bearbeiten</label>
        <!--  <div class="form-group row">
  <label class="col-lg-3 col-form-label" for="file">Datei</label>
  <div class="col-lg-9">
    <input accept="video/*" class="file_multi_video" id="file" name="file[]" ref="file"
           required="required"
           type="file"
           v-on:change="handleFileUpload()">
  </div>
</div> -->
        <input id="label" class="form-control mb-2" required="required" type="text" v-model="label">
      </div>
      <div class="row float-right">
        <button class="mb-3 btn btn-primary" v-on:click="processForm">Video ändern</button>
      </div>
    </div>
  </div>
</template>

<script>
import toast from "@/utils/toast";
import 'bootstrap/js/dist/dropdown';

export default {
  name: 'VideoEdit',
  props: ['video'],
  data: function () {
    return {
      file: '',
      label: this.video.label,
    };
  },

  methods: {
    processForm: function () {
      let self = this;
      this.$api.updateVideo({
        segments: {id: this.video.id},
        data: {
          label: this.label
        },
      }).then(function (response) {
        if (response.status === 200) {
          self.$emit('updateParent', response.data);
          self.close();
        } else {
          toast.error("Requestfehler: " + response)
        }
      }.bind(this))
    },

    close: function () {
      this.$emit('close');
    },

    handleFileUpload: function () {
      this.file = this.$refs.file.files[0];
    },
  }
}
</script>
