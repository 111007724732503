<template>
  <div style="padding: 1rem">
    <div class="form-group">
      <label>Turnier</label>
      <input type="text" class="form-control" v-model="editTournament" :placeholder="game.tournament">
    </div>
    <div class="form-group">
      <label>Jahr</label>
      <input type="text" class="form-control" v-model="editYear" :placeholder="game.year">
    </div>
    <div v-if="$store.state.game === 'table tennis'" class="form-group">
      <label>Ergebnis</label>
      <select class="form-control" v-model="editResult">
        <option :value="'3:0'">3:0</option>
        <option :value="'3:1'">3:1</option>
        <option :value="'3:2'">3:2</option>
        <option :value="'4:0'">4:0</option>
        <option :value="'4:1'">4:1</option>
        <option :value="'4:2'">4:2</option>
        <option :value="'4:3'">4:3</option>
      </select>
    </div>
    <div class="form-check mb-2">
      <input class="form-check-input" type="checkbox" v-model="editPrivate" id="privatCheckbox"
             @click="removeAuthUsers">
      <label class="form-check-label" for="privatCheckbox">Privat</label>
    </div>
    <div class="form-group">
      <label>Berechtigte Nutzer</label>
      <input class="form-control" placeholder="Suche nach Nutzern..." v-model="filterName" autocomplete="off">
      <div v-if="filteredUsers.length" class="custom-checkbox custom-control opponents-box">
            <span v-for="user in filteredUsers"
                  v-bind:key="user.id" class="ml-2">
                <input :id="user.id" type="checkbox" class="custom-control-input" :disabled="!editPrivate" :value="user.id"
                       v-model="usersToAuthenticate">
                <label :for="user.id"
                       class="custom-control-label">{{ user.name }}</label>
                <br>
            </span>
      </div>
    </div>
    <button v-on:click='hide("edit-game" + game.id)' class="btn btn-primary">Abbrechen</button>
    <button class="btn btn-primary add-skill-submit-btn" v-on:click="updateGame()">Aktualisieren</button>
  </div>
</template>

<script>
import toast from "@/utils/toast";

export default {
  name: 'GameEdit',
  props: ['game', 'players'],
  data: function () {
    return {
      users: [],
      usersToAuthenticate: this.game.users,
      editTournament: null,
      editYear: null,
      editResult: null,
      editPrivate: null,
      filterName: '',
    }
  },
  mounted: function () {
    this.getUserList();
    this.editTournament = this.game.tournament;
    this.editYear = this.game.year;
    this.editResult = this.game.result;
    this.editPrivate = this.game.private;
  },
  methods: {
    hide(modal) {
      this.$modal.hide(modal);
    },
    getUserList: function () {
      let self = this;
      this.$api.getUserList({}).then(response => {
        response.data.forEach(function (user, index) {
          user['passwort'] = "****";
          response.data[index] = user;
        });
        this.users = response.data.filter(function (user) {
          return user.sport === self.$store.state.user.sport;
        });
      });
    },
    updateGame: function () {
      if(this.editPrivate && this.usersToAuthenticate.length === 0) {
        toast.error('Bitte Nutzer auswählen');
        return 0;
      }

      this.$api.updateGame({
        segments: {id: this.game.id},
        data: {
          tournament: this.editTournament,
          year: this.editYear,
          private: this.editPrivate,
          result: this.editResult
        }
      }).then(response => {
        this.hide("edit-game" + this.game.id)
        toast.success('Spiel aktualisiert');
        this.setUserRestriction();
        this.$emit('updateGame', [response.data, this.usersToAuthenticate]);
      });
    },
    setUserRestriction: function () {
      let self = this;
      self.$api.createUserGame({
        data: {
          users: self.usersToAuthenticate,
          game_id: self.game.id
        }
      })
    },
    removeAuthUsers: function () {
      if (this.editPrivate) {
        this.usersToAuthenticate = [];
      }
    }
  },
  computed: {
    filteredUsers() {
      let filtered = this.users;
      if (this.filterName) {
        filtered = this.users.filter(user =>
            user.name.toLowerCase().indexOf(this.filterName.toLowerCase().trim()) > -1
        );
      }
      return filtered;
    },
    authenticatedUsers() {
      let self = this;
      return this.users.filter(function (user) {
        return self.game.users.includes(user.id);
      });
    },
  }
}
</script>
