<template>
<div class="p-5">
  <div class="close-btn">
    <button @click="hide()">
      ❌
    </button>
  </div>
  <div class="form-group">
    <label>Wiedergabeliste hinzufügen</label>
  </div>
  <label for="tagTitle">Titel</label>
  <div class="input-group d-flex mb-3">
    <input
        class="form-control w-75 mx-auto"
        type="text"
        name="tagTitle"
        placeholder="Titel der Liste"
        v-model="sceneTitle"
    >
  </div>
  <button class="btn btn-primary float-right" @click="addTag" :disabled="isDisabled">
    Hinzufügen
  </button>
</div>
</template>

<script>
export default {
  name: "TagAddingModal",
  props: ['videoId', 'tag'],
  data() {
    return {
      sceneTitle: this.tag ? this.tag.title : '',
    }
  },
  computed: {
    isDisabled() {
      return this.sceneTitle.length === 0
    }
  },
  methods: {
    hide() {
      this.$modal.hide('addTagModal');
    },
    addTag() {
      const formData = {}
      formData.title = this.sceneTitle
      if(this.tag) {
        this.$api.updateTag({segments: {id: this.tag.id}, data: formData}).then((res) => {
          this.$emit('tagUpdated', res.data)
          this.hide()
        })
      } else {
        formData.video_id = this.videoId
        this.$api.addTag({data: formData}).then((res) => {
          this.$emit('tagAdded', res.data)
          this.hide()
        })
      }
    }
  }
}
</script>

<style scoped>

</style>