<template>
  <div class="col-12 skill-item">
    <div v-if="skill.subskills">
      <ul class="list-group mb-3">
        <li v-for="subskill in skill.subskills" :key="subskill" class="list-group-item py-2">
          <span v-if="subskill[0] === '*'"
                style="font-weight: bold;white-space: pre-wrap;">{{ subskill.substring(1) }}</span>
          <span style="white-space: pre-wrap;" v-else>{{ subskill }}</span>
        </li>
      </ul>
    </div>
    <span style="white-space: pre-wrap;" v-else>{{ skill.text }}</span>
    <span v-if="skill.players.length > 1" class="text-muted" style="font-size: 12px">
                (<i class="fas fa-users"></i> {{ getSkillPartner(skill) }})
        </span>

    <div class="float-right mb-1">
      <button v-if="$store.state.user.role_name === 'admin' || $store.state.user.role_name === 'trainer'"
              class="btn skill-btn float-right ml-1" v-on:click='destroySkill(skill)'>
        <i class="fas fa-trash-alt"></i>
      </button>

      <button v-if="$store.state.user.role_name === 'admin' || $store.state.user.role_name === 'trainer'"
              class="btn skill-btn float-right ml-1" v-on:click='openEditAnnotation(skill)'>
        <i class="fas fa-edit"></i>
      </button>

      <button v-if="$store.state.user.role_name === 'admin' || $store.state.user.role_name === 'trainer'"
              class="btn skill-btn float-right ml-1" v-on:click='showAddVideo(skill)'>
        <i class="fas fa-upload"></i>
      </button>

      <a v-if="skill.videos.length" class="btn skill-btn float-right ml-1" data-toggle="collapse"
         :href="'#videoCollapse' + skill.id" role="button" aria-expanded="false"
         :aria-controls="'videoCollapse' + skill.id">
        <i class="fas fa-file-video"> {{ skill.videos.length }}</i><i class="fas fa-caret-down ml-2"></i>
      </a>
    </div>
    <div class="collapse w-100" :id="'videoCollapse' + skill.id">
      <div v-for="video in skill.videos" v-bind:key="video.id" class="list-group-item py-2 well"
           style="overflow: hidden; width: 100%;">
        {{ video.label }}
        <div class="float-right">
          <button v-if="$store.state.user.role_name === 'admin' || $store.state.user.role_name === 'trainer'"
                  class="btn skill-btn float-right ml-1" v-on:click='deleteVideo(video, skill)'>
            <i class="fas fa-trash-alt"></i>
          </button>
          <button v-if="$store.state.user.role_name === 'admin' || $store.state.user.role_name === 'trainer'"
                  class="btn skill-btn float-right ml-1" v-on:click='showEditVideo(video)'>
            <i class="fas fa-edit"></i>
          </button>
          <router-link :to="'/video/' + video.id"
                       class="btn skill-btn float-right">
            <i class="fas fa-file-video"></i>
          </router-link>
        </div>
      </div>
    </div>

    <modal :name="'add-video' + skill.id" :width="600" height="auto" :clickToClose="false" :adaptive="true">
      <video-upload objectType='App\Skill'
                    :objectID="skill.id"
                    :objectTitle="skill.subskills ? skill.subskills[0] : skill.text"
                    @updateParent="addVideoToSkill"
      ></video-upload>
    </modal>

    <modal :name="'edit-skill' + skill.id" height="auto" :clickToClose="false" :adaptive="true">
      <skill-edit
          :skill="skill"
          :player="player"
          @updateParent="updateSkill"
      ></skill-edit>
    </modal>

    <modal :name="'edit-video'+ skill.id" height="auto" :clickToClose="false" :adaptive="true">
      <video-edit
          :video="editVideo"
          @updateParent="updateVideo"
          @close="hide">
      </video-edit>
    </modal>
  </div>
</template>

<script>
import VideoUpload from './../Video/VideoUpload';
import SkillEdit from "./SkillEdit";
import VideoEdit from "@/components/Video/VideoEdit";


export default {
  props: ['skill', 'player'],
  components: {VideoEdit, VideoUpload, SkillEdit},
  name: 'SkillItem',
  data: function () {
    return {
      label: '',
      editVideo: null,
    };
  },

  methods: {
    getSkillPartner(skill) {
      let self = this;
      let skillPartner = skill.players.find(function (player) {
        return player.id !== self.player.id;
      });
      return skillPartner.surname + ', ' + skillPartner.forename;
    },
    destroySkill(skill) {
      const result = confirm("Möchten sie wirklich den Skill \"" + skill.text + "\" aus der Datenbank löschen?");
      if (result) {
        this.$api.destroySkill({
          segments: {
            pid: this.player.id,
            sid: skill.id
          }
        }).then(function () {
          let index = -1;
          for (let i = 0; i < this.player.skills.length; i++) {
            if (this.player.skills[i].id === skill.id) {
              index = i;
              break;
            }
          }
          // eslint-disable-next-line vue/no-mutating-props
          this.player.skills.splice(index, 1);
        }.bind(this, skill));
      }
    },
    showAddVideo() {
      this.skillToAddVideo = this.skill;
      this.$modal.show('add-video' + this.skill.id);
    },
    showEditVideo(video) {
      this.editVideo = video;
      this.$modal.show('edit-video' + this.skill.id);
    },
    closeAddVideo() {
      this.$modal.hide('add-video')
    },
    addVideoToSkill(skillID, video) {
      let self = this;
      this.player.skills.forEach(function (skill, index) {
        if (skill.id === skillID) {
          self.player.skills[index].videos.push(video)
        }
      })
    },
    openEditAnnotation() {
      this.$modal.show('edit-skill' + this.skill.id);
    },
    updateSkill(updatedSkill) {
      this.$emit('updateSkill', updatedSkill)
    },
    deleteVideo(video, skill) {
      const result = confirm("Möchten sie wirklich das Video \"" + video.label + "\" aus der Datenbank löschen?");
      if (result) {
        this.$api.destroyVideo({
          segments: {
            vid: video.id,
          }
        }).then(function () {
          let index = -1;
          for (let i = 0; i < skill.videos.length; i++) {
            if (skill.videos[i].id === video.id) {
              index = i;
              break;
            }
          }
          skill.videos.splice(index, 1);
        }.bind(this, video));
      }
    },
    updateVideo(updatedVideo) {
      this.skill.videos.find(video => video.id === updatedVideo.id).label = updatedVideo.label;
    },
    hide() {
      this.$modal.hide('edit-video' + this.skill.id);
    },
  }
}
</script>
