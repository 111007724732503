<template>
    <div class="login-container" v-bind:class="{ 'badminton-login': this.$store.state.game === 'badminton', 'table-tennis-login': this.$store.state.game === 'table tennis' }">
        <div class="login-form-container card px-5 py-4">
            <img v-if="$store.state.game === 'badminton'" alt="IAT" id="logo" class="img-fluid d-block mx-auto mb-2" src="../assets/img/GTA Badminton.svg">
            <img v-else alt="IAT" id="logo" class="img-fluid d-block mx-auto mb-2" src="../assets/img/GTA Tischtennis.svg">
            <form>
                <div class="form-group m-0">
                    <input autocomplete="on" placeholder="E-mail" class="form-control mb-1" id="email" type="email" v-model="email">
                    <input autocomplete="on" placeholder="Kennwort" class="form-control mb-1" id="password" type="password" v-model="password">
                    <button id="login-button" class="btn" type="button" v-on:click="submitLoginForm">Login</button>
                </div>
            </form>
        </div>
        <div class="badminton-info" v-if="this.$store.state.game === 'badminton'">
            <img alt="IAT" id="dbv-info" class="img-fluid float-right d-inline-block px-2" src="../assets/img/DBV_IAT_Logo.png">
        </div>
    </div>
</template>

<script>
    import toast from '../utils/toast';
    import {cordovaUtils} from "../utils/cordovaUtils";

    export default {
      // eslint-disable-next-line vue/multi-word-component-names
        name: 'Login',
        data: function () {
            return {
                email: '',
                password: '',
                timestamp: '',
                //sport: '',
                hash: ''
            };
        },

        beforeMount: function () {},
        mounted: function () {

            if(this.$cookies.get('user')){
                this.login(this.$cookies.get('user'));
            }

            if (window.cordova) {
                window.screen.orientation.lock('landscape');
            }

        },
        methods: {
            submitLoginForm: function () {

                if(cordovaUtils.isOfflineCancel()){
                    return 0;
                }

                if (!this.validEmail(this.email)) {
                    toast.error('Bitte gültige Email eingeben');
                } else {
                    const formData = new FormData();
                    this.timestamp = Date.now();
                    formData.append('email', this.email);
                    formData.append('password', this.password);
                    formData.append('timestamp', this.timestamp);
                    formData.append('sport', this.$store.state.game);

                    const self = this;
                    this.$api.login({
                        data: formData
                    }).then(function (response) {
                        if(response.data.api_token !== 'false' && response.data.sport === self.$store.state.game) {
                            self.$cookies.set('user', response);
                            toast.success("Hallo " + response.data.firstname +  " !");
                            self.login(response);
                        } else {
                            toast.error('Benutzer/Passwort wurde nicht akzeptiert!');
                        }
                    });
                }
            },
            login:function (login_response) {
                this.$store.state.user = login_response.data;
                this.$store.state.token = login_response.data.api_token;
                this.$parent.login(login_response);
            },
            validEmail: function (email) {
                // eslint-disable-next-line no-useless-escape
                let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            }
        },
        computed: {},
    }
</script>

<style lang="scss">
    @import '../assets/sass/login';
</style>
