const CanvasDrawing = {
    arrow: (context, fromX, fromY, toX, toY, r) => {
        let x_center = toX;
        let y_center = toY;

        let angle;
        let x;
        let y;


        context.moveTo(fromX, fromY);
        context.lineTo(toX, toY);
        context.stroke();

        context.beginPath();

        angle = Math.atan2(toY - fromY, toX - fromX);
        x = r * Math.cos(angle) + x_center;
        y = r * Math.sin(angle) + y_center;

        context.moveTo(x, y);

        angle += (1 / 3) * (2 * Math.PI);
        x = r * Math.cos(angle) + x_center;
        y = r * Math.sin(angle) + y_center;

        context.lineTo(x, y);

        angle += (1 / 3) * (2 * Math.PI);
        x = r * Math.cos(angle) + x_center;
        y = r * Math.sin(angle) + y_center;

        context.lineTo(x, y);

        context.closePath();
        context.fill();
    },
    circle: (context, fromX, fromY, toX, toY) => {
        context.ellipse(
            fromX + toX / 2,
            fromY + toY / 2,
            Math.abs(toX / 2),
            Math.abs(toY / 2),
            0,
            0,
            2 * Math.PI
        );
    },
    square: (context, fromX, fromY, toX, toY) => {
        context.rect(fromX, fromY, toX, toY);
    }
};

export default CanvasDrawing;