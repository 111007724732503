<template>
    <div>
        <div class="input-group position-relative m-2">
            <textarea aria-label="annotation" class="form-control" placeholder="Kommentar (max. 1000 Zeichen)"
                      v-if="!objFileUpload" v-bind:value="message" v-on:input="message=$event.target.value"
                      ref="annotationInput" maxlength="1000"></textarea>
            <div class="input-group-prepend" v-if="message && !objFileUpload">
                <button @click="postAnnotation('text', message, null, $store.state.user.user_id)"
                        class="btn btn-primary" type="button">
                    Posten
                </button>
            </div>
            <div v-else-if="!message && !objFileUpload">
                <button class="btn" disabled>
                    Posten
                </button>
            </div>
        </div>

        <div class="input-group position-relative m-2">
            <div class="custom-file">
                <input id="file" class="custom-file-input" type="file" ref="file"
                       accept="image/*, application/pdf"/>
                <label class="custom-file-label">{{objFileUpload?objFileUpload.name:'Datei'}}</label>
            </div>
            <button v-if="objFileUpload" @click="submitFile()"
                    class="btn btn-primary ml-2" type="button">
                Posten
            </button>
        </div>

        <div class="m-2" v-if="($store.state.networkActiveTasks > 0)">
            <progress style="width: 100%" max="100" :value.prop="$store.state.uploadPercentage"></progress>
        </div>

    </div>
</template>

<script>
    import toast from '../../utils/toast';
    import {cordovaUtils} from "../../utils/cordovaUtils";

    export default {
        name: "AnnotationSubmission",
        props: ['annotationType', 'annotatableObject', 'currentTime'],
        data: function () {
            return {
                label: 'label',
                message: null,
                objFileUpload: null,
            }
        },
        mounted() {
            let self = this;
            document.getElementById('file').addEventListener('change', function () {
                if (self.$refs.file.files[0]) {
                    self.objFileUpload = self.$refs.file.files[0]
                }
            });
        },
        methods: {
            submitFile() {
                if (cordovaUtils.isOfflineCancel()) {
                    return 0;
                }
                if (RegExp('^\\s*$').test(this.message)) {
                    toast.error('Bitte Notiztext eingeben', 'Unvollständig');
                    return 0;
                }

                let self = this;
                let message = this.objFileUpload.name;

                let formData = new FormData();
                formData.append('file', this.$refs.file.files[0]);

                this.$api.uploadAnnotationFile({
                    data: formData
                }).then(response => {
                    if (response.data) {
                        self.postAnnotation('file', message, response.data, this.$store.state.user.user_id);
                        document.getElementById('file').value = null;
                        self.objFileUpload = null;
                    } else {
                        toast.error("uploadAnnotationFile")
                    }

                });
            },
            postAnnotation: function (annotatonType, message, path, userId) {
                if (cordovaUtils.isOfflineCancel()) {
                    return 0;
                }
                let self = this;

                if (RegExp('^\\s*$').test(this.message)) {
                    toast.error('Bitte Notiztext eingeben', 'Unvollständig');
                    return 0;
                }

                this.$api.createNewAnnotation({
                    data: {
                        annotatable_id: self.annotatableObject.id,
                        annotatable_type: self.annotationType,
                        type: annotatonType,
                        text: message,
                        file_path: path ? path.replace("public", "/storage") : null,
                        user_id: userId,
                        timestamp: typeof self.currentTime !== 'undefined' ? self.currentTime : null,
                    }
                }).then(response => {
                    if(response.data) {
                      response.data['annotations'] = [];
                      // eslint-disable-next-line vue/no-mutating-props
                      this.annotatableObject.annotations.unshift(response.data);
                      //if subannotation open answers dropdown
                      if(response.data['annotatable_type'] === "App\\Annotation") {
                        this.$emit('openAnswerDropdown', response.data['annotatable_id']);
                        let $ = require('jquery');
                        $('#subAnnotationCollapse' + response.data['annotatable_id']).collapse('show');
                      }
                      toast.success('Kommentar hinzugefügt');
                      self.message = null;
                    } else {
                      toast.error('Kommentar darf maximal 1000 Zeichen lang sein');
                    }

                });

            },

        }
    }
</script>

<style scoped>

</style>