<template>
    <canvas id="video-overlay">
    </canvas>
</template>

<script>
    import $ from 'jquery/src/jquery';
    import CanvasDrawing from '../../utils/canvas_drawing';

    export default {
        name: 'VideoOverlay',
        props: [
            'overlayData',
            'playbackTime',
            'isPlaying'
        ],
        mounted: function () {
            this.$emit('canvasMounted', document.getElementById('video-overlay'));

        },
        data: function () {
            return {
                canvas: null,
                ctx: null,
                lastTime: null,
                activeOverlayId: null
            }
        },
        watch: {
            playbackTime() {
                this.redraw();
            },
            overlayData() {
                this.redraw();
            },
            '$store.state.sidebarVisible': function () {
                let self = this;

                function Sleep(milliseconds) {
                    return new Promise(resolve => setTimeout(resolve, milliseconds));
                }

                async function recalculate() {
                    await Sleep(1000);

                    self.$emit('canvasMounted', document.getElementById('video-overlay'));
                    self.videoLoaded();
                    self.redraw();
                }

                recalculate();

            }
        },
        methods: {
            videoLoaded: function () {
                const $canvas = $('#video-overlay');
                const $video = $('#video');
                $canvas.attr('height', $video.height());
                $canvas.attr('width', $video.width());
                this.canvas = $canvas[0];
                this.ctx = this.canvas.getContext("2d");
            },
            redraw: function () {
                if (!this.canvas) {
                    return null;
                }

                this.ctx = this.canvas.getContext("2d");
                this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

                // is there an overlay? if so, pause
                const activeOverlays = this.overlayData.filter(
                    overlay => overlay.start - 0.1 <= this.playbackTime && overlay.end + 0.1 >= this.playbackTime
                );
                let doPauseVideo = false;

                if (activeOverlays.length) {
                    this.$emit('activeOverlays', activeOverlays);


                    activeOverlays.forEach(overlay => {
                        overlay.video_markers.forEach(videoMarker => {
                            this.ctx.beginPath();
                            this.ctx.strokeStyle = videoMarker.color;
                            this.ctx.fillStyle = videoMarker.color;
                            this.ctx.lineWidth = 5;
                            const x_position = videoMarker.x_position * this.canvas.width;
                            const y_position = videoMarker.y_position * this.canvas.height;
                            const width = videoMarker.width * this.canvas.width;
                            const height = videoMarker.height * this.canvas.height;


                            switch (videoMarker.shape) {
                                case 'circle':
                                    CanvasDrawing.circle(
                                        this.ctx,
                                        x_position,
                                        y_position,
                                        width,
                                        height,
                                    );
                                    break;
                                case 'square':
                                    CanvasDrawing.square(
                                        this.ctx,
                                        x_position,
                                        y_position,
                                        width,
                                        height,
                                    );
                                    break;
                                case 'arrow': {
                                    const lineToX = x_position + width;
                                    const lineToY = y_position + height;

                                    CanvasDrawing.arrow(this.ctx, x_position, y_position, lineToX, lineToY, 10);
                                    break;
                                }
                            }

                            this.ctx.stroke();
                        });
                        if (overlay.start > this.lastTime + 0.2 && overlay.does_pause) {
                            doPauseVideo = true;
                            if (doPauseVideo && this.isPlaying) {
                                this.$emit('pauseVideo', overlay.start);
                            }
                        }
                    });
                } else {
                    this.$emit('activeOverlays', null);
                }

                this.lastTime = this.playbackTime;
                return doPauseVideo;
            },
        }
    }
</script>