<template>
  <div>
    <div id="video-container" class="d-flex flex-column flex-lg-row">
      <div id="video-element" :class="store.state.isFullscreen ? 'fullscreen' : 'video-element'"
      >
          <span @click="switchMobileControls">
              <!-- Video HTML5 Tag -->
            <template v-if="is_corodva">
                <video controls id="video" playsinline v-if="getDevicePlatform() === 'iOS'" webkit-playsinline>
                    <source :src="videoData ? videoData.absolute_path : ''" type="video/mp4">
                </video>

                <video :src="videoData ? videoData.absolute_path : ''" id="video"
                       v-else-if="getDevicePlatform() === 'Android'"></video>
            </template>
            <video :src="videoData ? videoData.absolute_path : ''" id="video" playsinline v-else
                   :style="$store.state.isFullscreen ? 'height: 100vh;' : ''"
            ></video>

            <!-- Video Canvas for showing overlays -->
            <video-overlay :overlay-data="videoData.video_overlays"
                           :playback-time="currentVideoTime"
                           :isPlaying="this.$refs.videoControls.videoIsPlaying"
                           @activeAnnotations="activeAnnotations = $event"
                           @activeOverlays="activateOverlays($event)"
                           @canvasMounted="$refs.drawingControls.canvasMounted($event)"
                           @pauseVideo="$refs.videoControls.pauseVideo($event)"
                           ref="videoOverlay"
                           v-if="videoData.id && $store.state.isFullscreen"
            >
            </video-overlay>
          </span>
        <!-- Video controls for playing video -->
        <video-controls :overlay-data="videoData ? videoData.video_overlays : []"
                        :scenes=$route.params.scenes
                        :active-overlay="currentOverlay ? currentOverlay : null"
                        :style="{display: showDrawingTools ? 'none' : ''}"
                        :video="videoData"
                        :active-scene="activeScene"
                        :tagging-start="activeRecordingStart"
                        :tagging-stop="activeRecordingEnd"
                        @addOverlay="addOverlayToVideo"
                        @editOverlay="editOverlay"
                        @controlsSwitched="switchControls"
                        @videoTimeChanged="videoTimeChanged"
                        ref="videoControls"
                        v-show="showControls"
                        v-model="currentSceneIndex"
        ></video-controls>

        <!-- Controls for drawing shapes -->
        <drawing-controls :currentOverlay="currentOverlay"
                          :show-drawing-tools="showDrawingTools"
                          :style="{display: showDrawingTools ? '' : 'none'}"
                          @controlsSwitched="switchControls"
                          @canceledEditing="deleteNewMarkers($event)"
                          @overlayDeleted="overlayDeleted"
                          @overlayUpdated="overlayUpdated"
                          ref="drawingControls"
        >
        </drawing-controls>
      </div>
      <!--Container for displaying annotations-->
      <div id="annotations" v-if="!store.state.isFullscreen">
        <div class="container-selection flex">
          <div :style="currentGame !== 'badminton' ? 'order: 3' : ''"
               @click="activeContainer = 'scenes'" :class="{'active bg-light': activeContainer === 'scenes'}">
            Szenen
          </div>
          <div :style="currentGame !== 'badminton' ? 'order: 2' : ''"
               @click="activeContainer = 'favorites'"
               :class="{'active bg-light': activeContainer === 'favorites'}">Favoriten
          </div>
          <div :style="currentGame !== 'badminton' ? 'order: 1' : ''"
               @click="activeContainer = 'annotations'"
               :class="{'active bg-light ml-1': activeContainer === 'annotations'}">
            Kommentare
          </div>
          <div :style="currentGame !== 'badminton' ? 'order: 4' : ''"
               v-if="currentGame !== 'badminton'"
               @click="activeContainer = 'tagging'"
               :class="{'active bg-light': activeContainer === 'tagging'}">
            Tagging
          </div>
        </div>
        <annotations-container
            v-if="activeContainer === 'annotations'"
            :annotatable-id="videoData ? videoData.id : null"
            :annotations="videoData.annotations"
            :annotatable-object="videoData"
            :annotation-type="'App\\Video'"
            :current-time="currentVideoTime"
            @commentSelected="$refs.videoControls.pauseVideo($event)"
            ref="annotationContainer"
            class="side-container">
        </annotations-container>
        <tag-filter-container
            v-if="activeContainer === 'scenes'"
            :categories="tagOptions.filter(item => item.title !== 'Custom')"
            :tag-counts="existingTagsCount"
            :hide-nav="$store.state.game === 'table tennis'"
            :update-tags-in-store="true"
            class="side-container"
        ></tag-filter-container>
        <tag-filter-container
            v-if="activeContainer === 'favorites'"
            :categories="tagOptions.filter(item => item.title === 'Custom')"
            :tag-counts="existingTagsCount"
            :hide-nav="$store.state.game === 'table tennis'"
            :update-tags-in-store="true"
            class="side-container"
        ></tag-filter-container>
        <tagging-container
            v-if="activeContainer === 'tagging'"
            class="side-container"
            :video-time="currentVideoTime"
            :video-id="videoData.id"
            :options="tagOptions"
            @sceneAdded="addScene($event)"
            @scenesImported="addImportedScenes($event)"
            @taggingStarted="activeRecordingStart = $event"
            @taggingStopped="activeRecordingEnd = $event"
        ></tagging-container>
      </div>
    </div>
    <scene-table
        v-model="currentSceneIndex"
        v-if="videoData.scenes && videoData.scenes.length"
        :scenes="filteredScenes"
        :categories="tagOptions"
        :video="videoData"
        :video-time="currentVideoTime"
        :file="videoData.files ? videoData.files[0] : null"
        :video-title="videoData.label"
        :remove-tags="selectedCustomTags"
        @sceneChanged="updateVideoTime($event)"
        @pauseVideo="$refs.videoControls.pauseVideo($event)"
        style="max-height: 500px; overflow: auto;
}"
    ></scene-table>
  </div>
</template>

<script>
import 'bootstrap/js/dist/dropdown';
import VideoOverlay from "./VideoOverlay";
import VideoControls from "./VideoControls";
import DrawingControls from "./DrawingControls";
import AnnotationsContainer from "../Annotation/AnnotationsContainer"
import VideoDataAccessor from "./../../storage/video-accessor.jsx";
import toast from './../../utils/toast';
import {cordovaUtils} from "@/utils/cordovaUtils";
import TagFilterContainer from "@/components/Scenes/TagFilterContainer";
import SceneTable from "@/components/Scenes/SceneTable";
import TaggingContainer from "@/components/Scenes/TaggingContainer";
import store from "@/utils/store";
import customSortCategories from "@/utils/customSort";

export default {
  name: 'VideoElement',
  components: {
    AnnotationsContainer,
    VideoOverlay,
    VideoControls,
    DrawingControls,
    TagFilterContainer,
    SceneTable,
    TaggingContainer
  },
  data: function () {
    return {
      videoData: {
        absolute_path: null,
        created_at: null,
        deleted_at: null,
        id: null,
        label: null,
        path: null,
        sport: null,
        updated_at: null,
        video_overlays: null,
        videoable: null,
        videoable_id: null,
        videoable_type: null,
      },
      currentSceneIndex: null,
      currentVideoTime: 0,
      showDrawingTools: false,
      is_corodva: !!window.cordova,
      isPersistent: this.$store.state.downloaded_videos.indexOf(parseInt(this.$route.params.id)) >= 0,
      activeAnnotations: [],
      currentOverlay: null,
      videoPlayerType: null,
      cordovaIsUpdating: false,
      cordovaUpdates: false,
      showControls: true,
      activeContainer: '',
      tagOptions: [],
      activeScene: null,
      activeRecordingStart: null,
      activeRecordingEnd: null,
    }
  },
  computed: {
    store() {
      return store
    },
    filteredScenes() {
      if (this.$store.state.selectedTags.length) {
        let foundScenes = []
        // Group selected tags by category to get the some filter (as in A or B)
        let groupedTags = this.groupBy(this.$store.state.selectedTags, 'category_id')
        Object.values(groupedTags).forEach((tags) => {
          let filter = this.videoData.scenes?.filter((scene) => {
            const sceneTags = scene.tags.map((tag) => tag.id)
            const selectedTagIds = tags.map((tag) => tag.id)
            return selectedTagIds.some((tag) => sceneTags.includes(tag))
          })
          foundScenes.push(filter)
        })
        // Reduce all found scenes to only those that exist in all the some filters ((A or B) and +)
        return foundScenes.reduce((a, b) => a.filter(c => b.includes(c)))
      }
      return this.videoData.scenes
    },
    existingTagsCount() {
      if (!this.filteredScenes)
        return []
      let tags = []
      this.filteredScenes.forEach((scene) => {
        tags.push(...scene.tags)
      })
      let groupedTags = this.groupBy(tags, 'id')
      Object.keys(groupedTags).forEach(key => groupedTags[key] = groupedTags[key].length)
      return groupedTags
    },
    currentGame() {
      return this.$store.getters.getCurrentGame;
    },
    selectedCustomTags() {
      return this.$store.getters.getSelectedTags.filter((tag) => this.isCustomTag(tag));
    }
  },
  mounted: function () {
    if (window.cordova) {
      window.screen.orientation.lock('landscape');
    }

    let video = document.getElementById("video");
    video.controls = false;

    this.$refs.videoControls.videoIsEmbedded(video);
    video.addEventListener('loadeddata', function () {
      this.$refs.videoOverlay?.videoLoaded();
    }.bind(this));

    this.getVideo();

    this.getTags();

    if (this.$store.state.game === 'badminton') {
      this.activeContainer = 'scenes';
    } else {
      this.activeContainer = 'annotations';
    }
  },
  created() {
    let self = this;
    this.$vueEventBus.$on('custom-update', function () {
      self.getVideo();
    });
    this.$emit('activeAnnotations', self.annotations);
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'analysis') {
      // If navigating away from 'analysis' to a different route, clear the tags
      this.$store.state.selectedTags = [];
    }
    next();
  },
  beforeDestroy() {
    this.$vueEventBus.$off('custom-update');
    this.$emit('activeAnnotations', null);
  },
  methods: {
    getVideo: function () {
      let self = this;
      if (this.is_corodva && this.isPersistent) {
        let vid = new VideoDataAccessor(parseInt(self.$route.params.id));
        vid.getVideo().then(function (video) {

          self.videoData = video;
          vid.getVideoFile().then(function (file_url) {
            self.videoData.absolute_path = file_url;
          });
        });

      } else {
        this.$api.getVideoDetail({
          segments: {id: parseInt(this.$route.params.id)}
        }).then(response => {
          if (response.status === 200) {
            self.videoData = response.data;
          } else {
            toast.error('Bitte verbinde dich mit dem Internet')
          }
        });
      }
    },
    getTags() {
      this.$api.fetchVideoTags({segments: {videoId: this.$route.params.id}}).then(res => {
        this.tagOptions = customSortCategories(res.data)
      })
    },
    getDevicePlatform: function () {
      return cordovaUtils.getDevicePlatform();
    },
    videoTimeChanged(currentVideoTime) {
      this.currentVideoTime = currentVideoTime;
    },
    switchControls() {
      this.showDrawingTools = !this.showDrawingTools;
    },
    addOverlayToVideo(overlay) {
      let newVideo_overlays = this.videoData.video_overlays;
      newVideo_overlays.push(overlay);
      this.$set(this.videoData, 'video_overlays', newVideo_overlays);
      this.editOverlay(overlay);
    },
    editOverlay(overlay) {
      this.currentOverlay = overlay;
    },
    overlayUpdated(overlay) {
      let self = this;
      this.videoData.video_overlays.forEach(
          function (currentValue, currentIndex, listObj) {
            if (currentValue.id === overlay.id) {
              self.$set(listObj, currentIndex, overlay);
            }
          }
      );
      //TODO:Save video if offline
    },
    overlayDeleted() {
      this.videoData.video_overlays.pop();
    },
    activateOverlays(overlays) {
      if (overlays) {
        this.currentOverlay = overlays.sort((a, b) => (a.start < b.start) ? 1 : -1)[0];
      } else {
        this.currentOverlay = null;
      }
    },
    deleteNewMarkers(data) {
      let updatedOverlay = this.videoData.video_overlays.find(function (overlay) {
        return overlay.id === data.overlay.id
      });
      for (let i = 1; i <= data.newMarkers; i++) {
        updatedOverlay.video_markers.pop();
      }
      this.$refs.videoOverlay?.videoLoaded();
      this.$refs.videoOverlay?.redraw();
    },
    switchMobileControls() {
      if (window.innerWidth <= 855) {
        this.showControls = !this.showControls;
      }
    },
    addScene(scene) {
      this.videoData.scenes.push(scene)
      this.videoData.scenes.sort((a, b) => a.start > b.start)
    },
    updateVideoTime(payload) {
      this.$refs.videoControls.pauseVideo(payload.videoTime)
      this.activeScene = payload.scene
      this.$refs.videoControls.toggleVideoIsPlaying()
    },
    groupBy(array, key) {
      const result = {}
      array.forEach(item => {
        if (!result[item[key]]) {
          result[item[key]] = []
        }
        result[item[key]].push(item)
      })
      return result
    },
    addImportedScenes(scenes) {
      this.getTags()
      this.videoData.scenes.push(...scenes)
    },
    isCustomTag(tag) {
      return this.tagOptions.filter(item => item.title === 'Custom').find((category) => {
        return category.id === tag.category_id
      })
    }
  },
  watch: {
    currentOverlay() {
      this.$nextTick()
          .then(function () {
            this.$refs.videoOverlay?.videoLoaded();
            this.$refs.videoOverlay?.redraw();
          }.bind(this));
    },
    videoData: {
      handler(newVideoData) {
        if (this.isPersistent) {
          let self = this;
          self.cordovaMakeUpdate = true;

          // eslint-disable-next-line no-inner-declarations
          function Sleep(milliseconds) {
            return new Promise(resolve => setTimeout(resolve, milliseconds));
          }

          // eslint-disable-next-line no-inner-declarations
          async function update() {
            if (self.cordovaMakeUpdate) {
              if (!self.cordovaUpdates) {
                self.cordovaUpdates = true;
                self.cordovaMakeUpdate = false;
                await Sleep(1000);
                let vid = new VideoDataAccessor(parseInt(self.$route.params.id));
                vid.updateVideo(newVideoData).then(function () {
                  self.cordovaUpdates = false;
                  if (self.cordovaMakeUpdate) {
                    update()
                  }
                });
              }
            }
          }

          update()
        }
      }
      ,
      deep: true
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/sass/video';

.fullscreen {
  position: fixed;
  object-fit: fill;
}
</style>
