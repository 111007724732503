<template>
  <div>
    <div v-if="player">
      <div class="card player-card mb-2">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-2 mb-3 mb-lg-0">
              <div :style="{backgroundImage: 'url(' + profileImage(player.absolute_path) + ')'}"
                   class="profile-image"></div>
            </div>
            <div class="col-lg-10">
              <div class="player-name mb-3">{{ player.surname + ", " + player.forename }}</div>
              <div>
                <table class="player-attributes mr-4 float-left">
                  <tr>
                    <td><i class="fas fa-flag"></i></td>
                    <td>{{ player.nation }} - {{ getCountryNameByCode(player.nation) }}</td>
                  </tr>
                  <tr>
                    <td><i class="fas fa-hand-paper"></i></td>
                    <td>{{ handenessLabel(player.handedness) }}</td>
                  </tr>
                  <tr v-if="player.playing_system">
                    <td v-if="this.$store.state.game === 'table tennis'"><i class="fas fa-table-tennis"></i></td>
                    <td v-else><img src="../../assets/img/badminton-icon.png" alt="badminton racket"></td>
                    <td>{{ player.playing_system }}</td>
                  </tr>
                </table>
                <div v-if="partners.length" class="float-left">
                  <div class="float-left mr-3"><i style="color: #495057;" class="fas fa-users"></i></div>
                  <div class="float-left">
                                    <span class="pr-2 partner-link d-block" :key="team.id" v-for="team in partners">
                                        <span @click="redirectToPlayer(team.player2_id)"
                                              v-if="team.player1_id === player.id && team.player2_id !== null">
                                          {{ team.player2.surname }}, {{ team.player2.forename }}
                                            <span v-if="team.type === 'doubles'" class="text-muted team-type-field">(Doppel)</span><span
                                            v-else class="text-muted team-type-field">(Mixed)</span>
                                        </span>
                                        <span @click="redirectToPlayer(team.player1_id)"
                                              v-else-if="team.player2_id === player.id">
                                                {{ team.player1.surname }}, {{ team.player1.forename }}
                                             <span v-if="team.type === 'doubles'" class="text-muted team-type-field">(Doppel)</span><span
                                            v-else class="text-muted team-type-field">(Mixed)</span>
                                        </span>
                                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 mb-4 mx-auto px-0 row">
        <button :class="navigationClasses(navKey)" class="col py-2" @click="changeNavigation(navKey)" type="button"
                v-bind:key="navKey" v-for="(navLabel, navKey) in navigationEntries">
          {{ navLabel }}
        </button>
      </div>
      <component :is="activeNavigation"
                 :player="player"
                 @updatePlayerData="updatePlayerData"
                 @teamAdded="addTeam($event)"
                 @teamDeleted="deleteTeam($event)"
                 @updateSkill="updateSkill"
                 @deleteSkill="deleteSkill"
                 @addVideoToSkill="addVideoToSkill"
      ></component>
    </div>
  </div>
</template>

<script>
import PlayerOpenings from "./PlayerOpenings";
import PlayerSkills from "./PlayerSkills";
import PlayerNotes from "./PlayerNotes";
import PlayerGames from "./PlayerGames";
import PlayerTeam from "./PlayerTeam"
import PlayerEdit from "./PlayerEdit";
import PlayerProfile from "./PlayerProfile";
import PlayerTraining from "./PlayerTraining";


import PlayerDataAccessor from "./../../storage/player-accessor.jsx";
import countryCodes from "../../utils/countryCode";
import DrawingControls from "../Video/DrawingControls";


    export default {
      // eslint-disable-next-line vue/multi-word-component-names
        name: 'Player',
        components: {
            DrawingControls,
            PlayerOpenings,
            PlayerSkills,
            PlayerNotes,
            PlayerGames,
            PlayerTeam,
            PlayerEdit,
            PlayerProfile,
            PlayerTraining
        },
        data: function () {
            if (this.$store.state.game === 'badminton' && this.$store.state.user.role_name === 'admin') {
                return {
                    player: null,
                    navigationEntries: {
                        PlayerGames: 'Spiele',
                        PlayerSkills: 'Fähigkeiten',
                        PlayerNotes: 'Anmerkungen',
                        PlayerTraining: 'Lehre',
                        PlayerTeam: 'Partner',
                        PlayerEdit: 'Stammdaten',
                    },
                    activeNavigation: 'PlayerGames',
                    is_corodva: !!window.cordova,
                };
            } else if (this.$store.state.game === 'badminton' && this.$store.state.user.role_name === 'trainer') {
                return {
                    player: null,
                    navigationEntries: {
                        PlayerGames: 'Spiele',
                        PlayerSkills: 'Fähigkeiten',
                        PlayerNotes: 'Anmerkungen',
                        PlayerTraining: 'Lehre',
                        PlayerTeam: 'Partner',
                        PlayerEdit: 'Stammdaten',
                    },
                    activeNavigation: 'PlayerGames',
                    is_corodva: !!window.cordova,
                };
            } else if (this.$store.state.game === 'badminton' && this.$store.state.user.role_name === 'player') {
                return {
                    player: null,
                    navigationEntries: {
                        PlayerGames: 'Spiele',
                        PlayerSkills: 'Fähigkeiten',
                        PlayerNotes: 'Anmerkungen',
                        PlayerTraining: 'Lehre',
                    },
                    activeNavigation: 'PlayerGames',
                    is_corodva: !!window.cordova,
                };
            } else if (this.$store.state.game === 'table tennis' && this.$store.state.user.role_name === 'admin') {
                return {
                    player: null,
                    navigationEntries: {
                        PlayerGames: 'Spiele',
                        PlayerProfile: 'Profil',
                        PlayerNotes: 'Anmerkungen',
                        PlayerTraining: 'Lehre',
                        PlayerTeam: 'Partner',
                        PlayerEdit: 'Stammdaten',
                    },
                    activeNavigation: 'PlayerGames',
                    is_corodva: !!window.cordova,
                };
            } else if (this.$store.state.game === 'table tennis' && this.$store.state.user.role_name === 'trainer') {
                return {
                    player: null,
                    navigationEntries: {
                        PlayerGames: 'Spiele',
                        PlayerProfile: 'Profil',
                        PlayerNotes: 'Anmerkungen',
                        PlayerTraining: 'Lehre',
                        PlayerTeam: 'Partner',
                        PlayerEdit: 'Stammdaten',
                    },
                    activeNavigation: 'PlayerGames',
                    is_corodva: !!window.cordova,
                };
            } else {
                return {
                    player: null,
                    navigationEntries: {
                        PlayerGames: 'Spiele',
                        PlayerProfile: 'Profil',
                        PlayerNotes: 'Anmerkungen',
                        PlayerTraining: 'Lehre',
                    },
                    activeNavigation: 'PlayerGames',
                    is_corodva: !!window.cordova,
                };
            }
        },
        watch: {
            '$route.params.tab': function () {
                this.updateTap();
            },
            player: {
                handler(newplayerData) {
                    // isPersistent
                    if (this.$store.state.downloaded_players.indexOf(parseInt(newplayerData.id)) >= 0) {
                        let pfa = new PlayerDataAccessor(newplayerData.id);
                        return pfa.updatePlayer(newplayerData);
                    }
                },
                deep: true
            },
        },
        mounted: function () {
            let self = this;
            this.getPlayer().then(function () {
                //ensures the correct player type
                if (self.player.sport !== self.$store.state.game) {
                    self.$router.push({path: '/'})
                }
            });
            this.updateTap();
        },
        created() {
            let self = this;
            this.$vueEventBus.$on('custom-update', function () {
                self.getPlayer();
            })
        },
        beforeDestroy() {
            this.$vueEventBus.$off('custom-update')
        },
        methods: {
            updateTap() {
              // eslint-disable-next-line no-prototype-builtins
      if (this.navigationEntries.hasOwnProperty(this.$route.params.tab)) {
        this.changeNavigation(this.$route.params.tab, this.$route.params.subTab);
      }
    },
    getPlayer() {
      return this.$api.getPlayerDetail({
        segments: {id: parseInt(this.$route.params.id)}
      }).then(response => {
        if (response.status === 200) {
          this.player = response.data;
        } else if (!this.player && this.is_corodva) {
          return this.loadOfflinePlayerData(this.$route.params.id);
        }
      });
    },
    profileImage(imagePath) {
      if (imagePath) return imagePath;
      return require('../../assets/img/profile.png')
    },
    handenessLabel(handeness) {
      switch (handeness) {
        case 'left':
          return 'Links';
        case 'right':
          return 'Rechts';
      }
      return handeness;
    },
    changeNavigation(tab, subTab) {
      if (!subTab) {
        subTab = 0;
      }
      if (this.$route.path !== `/player/detail/${this.$route.params.id}/${tab}/${subTab}`) {
        this.$router.push({path: `/player/detail/${this.$route.params.id}/${tab}/${subTab}`})
      }
      this.activeNavigation = tab;
    },
    navigationClasses(entry) {
      let classes = ['btn', 'btn-primary', 'border', 'nav-button'];
      if (entry === this.activeNavigation) {
        classes.push('active');
      }
      return classes;
    },
    updatePlayerData(prop, value) {
      this.$set(this.player, prop, value);
    },
    loadOfflinePlayerData(player_id) {
      let self = this;
      let pfa = new PlayerDataAccessor(player_id);
      return pfa.getPlayer().then(function (data) {
        self.player = data;
        pfa.getImage().then(function (imagePath) {
          self.player.absolute_path = imagePath
        });
      })
    },
    addTeam(team) {
      this.player.teams.push(team);
      this.$store.commit('updatePlayerTeam', [this.player, team]);
    },
    deleteTeam(player_id) {
      let teamIndex = this.player.teams.findIndex(function (team) {
        return player_id === team.player2_id;
      });
      this.player.teams.splice(teamIndex, 1);
    },
    getCountryNameByCode(code) {
      return countryCodes.getNameByCode(code);
    },
    redirectToPlayer(playerID) {
      this.$router.push({path: `/player/detail/${playerID}`});
      this.getPlayer();
    },
    updateSkill(updatedSkill) {
      for (let i in this.player.skills) {
        if (this.player.skills[i].id === updatedSkill.id) {
          this.$set(this.player.skills, i, updatedSkill);
          return 0;
        }
      }
    },
    deleteSkill(deletedSkill) {
      for (let i in this.player.skills) {
        if (this.player.skills[i].id === deletedSkill.id) {
          this.player.skills.splice(i, 1);
          return 0;
        }
      }
    },
    addVideoToSkill(updatedSkillID, video) {
      let self = this;
      this.player.skills.forEach(function (skill, index) {
        if (skill.id === updatedSkillID) {
          self.$set(self.player.skills, index, self.player.skills[index].videos.push(video));
        }
      });
    }
  },
  computed: {
    partners() {
      let partners = this.player.teams.filter(team => team.player2_id !== null);
      return partners;
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/sass/player';
</style>
