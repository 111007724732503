export default class FileAccessor {

    constructor(fileName, dirName) {
        this.fileName = fileName.toString() + (this.config.niceJSON ? '.txt' : null);
        this.dirName =  dirName;

    }

    get config() {
        return {
            niceJSON: true
        }
    }

    get location() {

        /*global device*/
        /*global cordova*/
        /*eslint no-undef: "error"*/
        if (device.platform === "iOS") {
            return cordova.file.dataDirectory;
        } else if (device.platform === "Android") {
            return cordova.file.externalDataDirectory;
        } else {
            return null;
        }
    }

    getLocation() {
        return this.location
    }


    clearDirectory() {
        let self = this;
        return new Promise(function (resolve, reject) {
            if (!window.LocalFileSystem) {
                reject("LocalFileSystem is not defined");
            }
            window.resolveLocalFileSystemURL(self.location, function (rootDirEntry) {
                rootDirEntry.getDirectory('data', {create: true}, function (dirEntry) {
                    dirEntry.removeRecursively(function () {
                            resolve();
                        },
                        function (e) {
                            reject(e)
                        });
                })
            })
        });
    }


    removeSubDir(dir, subDir) {
        let self = this;
        return new Promise(function (resolve, reject) {
            window.resolveLocalFileSystemURL(self.location, function (rootDirEntry) {
                rootDirEntry.getDirectory(dir, {create: false}, function (gamesDirEntry) {
                    gamesDirEntry.getDirectory(subDir.toString(), {create: false}, function (dirEntry) {
                        resolve(dirEntry.removeRecursively());
                    }, function (e) {
                        reject(e);
                    });
                })
            }, function (e) {
                reject(e);
            });
        })
    }

    savaZipToDir(uri, folder, subFolder) {
        let self = this;
        window.resolveLocalFileSystemURL(self.location, function (dirEntry) {
            dirEntry.getFile("temp.zip", {create: true, exclusive: true}, function (fileEntry) {

                /*global FileTransfer*/
                /*eslint no-undef: "error"*/
                var fileTransfer = new FileTransfer();
                fileTransfer.download(
                    encodeURI(uri),
                    fileEntry.toURL(),
                    function (entry) {
                        // unzip
                        var ZipPath = entry.toURL();
                        var ZipExtractDirectory = self.location + "/data/" + folder + "/" + subFolder.toString();
                        var StatusCallback = function (status) {
                            if (status == 0) {
                                entry.remove();
                            }
                        };
                        window.zip.unzip(ZipPath, ZipExtractDirectory, StatusCallback, null);
                    }
                );
            });
        });
    }

    saveLargeFile(uri) {
        return this._accessFile().then(function (fileEntry) {
            return new Promise(function (resolve, reject) {
                var fileTransfer = new FileTransfer();
                fileTransfer.download(
                    encodeURI(uri),
                    fileEntry.toURL(),
                    function (entry) {
                        resolve(entry)
                    },
                    function (error) {
                        reject(error)
                    },
                    false,
                );
            })
        })
    }


    getFileURL() {
        return this._accessFile().then(function (fileEntry) {
            return fileEntry.toURL();
        })
    }

    saveJSONData(data) {
        let self = this;
        return this._accessFile().then(function (fileEntry) {
            return new Promise(function (resolve, reject) {
                fileEntry.createWriter(function (fileWriter) {
                    fileWriter.onwriteend = resolve;
                    fileWriter.write(JSON.stringify(data, null, (self.config.niceJSON ? '\t' : null)));
                }, reject);
            })
        });
    }

    readJSONData() {
        return this._accessFile().then(function (fileEntry) {
            return new Promise(function (resolve, reject) {
                fileEntry.file(function (file) {
                    let reader = new FileReader();
                    reader.onloadend = function (evt) {
                        if (evt.target.result) {
                            resolve(JSON.parse(evt.target.result));
                        } else {
                            reject("Cannot read file")
                        }
                    };
                    reader.readAsText(file);
                }, function () {
                    reject("Cannot get file");
                });
            });
        });
    }

    removeFile() {
        return this._accessFile().then(function (fileEntry) {
            return new Promise(function (resolve, reject) {
                fileEntry.remove(function () {
                    resolve();
                }, function () {
                    reject();
                });
            }, function () {
                return Promise.reject("Couldn't access file");
            });
        });
    }

    _accessFile() {
        let self = this;
        return new Promise(function (resolve, reject) {
            if (!window.LocalFileSystem) {
                reject("LocalFileSystem is not defined");
            }
            window.resolveLocalFileSystemURL(self.location, function (rootDirEntry) {
                rootDirEntry.getDirectory('data', {create: true}, function (mainDirEntry) {
                    mainDirEntry.getDirectory(self.dirName, {create: true}, function (dirEntry) {
                        dirEntry.getFile(self.fileName, {create: true, exclusive: false}, function (fileEntry) {
                            resolve(fileEntry);
                        }, function (e) {
                            reject(e);
                        });
                    })
                })
            }, function () {
                reject("Cannot load filesystem");
            });
        });
    }
}


