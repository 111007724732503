<template>
  <div>
    <div class="team-nav mx-auto mt-2 mb-4">
      <button v-if="isSingle" class="btn btn-primary border nav-button" :class="showSkill === 'single' ? 'active' : ''"
              @click="showSkill = 'single'; $store.state.selectedTeamType = 'single'">Einzel
      </button>
      <button v-else-if="isDouble" class="btn btn-primary border nav-button"
              :class="showSkill === 'single' ? 'active' : ''"
              @click="showSkill = 'single'; $store.state.selectedTeamType = 'single'">Individuell
      </button>
      <button v-if="isDouble" class="btn btn-primary border nav-button" :class="showSkill === 'double' ? 'active' : ''"
              @click="showSkill = 'double'; $store.state.selectedTeamType = 'double'">Doppel
      </button>
      <button v-if="isDouble" class="btn btn-primary border nav-button" :class="showSkill === 'mixed' ? 'active' : ''"
              @click="showSkill = 'mixed'; $store.state.selectedTeamType = 'mixed'">Mixed
      </button>
    </div>
    <div class="row">
      <div class="col-lg-6 mb-4">
        <ul class="list-group">
          <li class="list-group-item py-3 bg-light text-success"><a data-toggle="collapse" href="#strengthCollapse"
                                                                    aria-expanded="false"
                                                                    aria-controls="strengthCollapse" role="button">Stärken
            <i v-if="filterPlayerSkills(player, 'strength').length" class="fas fa-caret-down ml-2"></i></a>
            <button v-if="$store.state.user.role_name === 'admin' || $store.state.user.role_name === 'trainer'"
                    class="add-skill-btn btn" v-on:click='showAddStrength()'>
              <i class="fas fa-plus px-1"></i>
            </button>
          </li>
          <span class="strength-collapse collapse" id="strengthCollapse">

                        <li v-for="skill in filterPlayerSkills(player, 'strength')" v-bind:key="skill.id"
                            class="list-group-item d-flex">
                            <skill-item
                                :skill="skill"
                                :player="player"
                                @updateSkill="updateSkill"
                            ></skill-item>
                        </li>
                    </span>
        </ul>
      </div>
      <div class="col-lg-6 mb-4">
        <ul class="list-group">
          <li class="list-group-item py-3 bg-light text-danger"><a data-toggle="collapse" href="#weaknessCollapse"
                                                                   aria-expanded="false"
                                                                   aria-controls="weaknessCollapse" role="button">Schwächen
            <i v-if="filterPlayerSkills(player, 'weakness').length" class="fas fa-caret-down ml-2"></i></a>
            <button v-if="$store.state.user.role_name === 'admin' || $store.state.user.role_name === 'trainer'"
                    class="add-skill-btn btn" v-on:click='showAddWeakness()'>
              <i class="fas fa-plus px-1"></i>
            </button>
          </li>
          <span class="weakness-collapse collapse" id="weaknessCollapse">
                           <li v-for="skill in filterPlayerSkills(player, 'weakness')" v-bind:key="skill.id"
                               class="list-group-item d-flex">
                        <skill-item
                            :skill="skill"
                            :player="player"
                            @updateSkill="updateSkill"
                        ></skill-item>
                    </li>
                    </span>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 mb-4">
        <ul class="list-group">
          <li class="list-group-item py-3 bg-light text-success"><a data-toggle="collapse" href="#miscCollapse"
                                                                    aria-expanded="false" aria-controls="miscCollapse"
                                                                    role="button">Zusätzliche Information
            <i v-if="filterPlayerSkills(player, 'misc').length" class="fas fa-caret-down ml-2"></i></a>
            <button v-if="$store.state.user.role_name === 'admin' || $store.state.user.role_name === 'trainer'"
                    class="add-skill-btn btn" v-on:click='showAddMisc()'>
              <i class="fas fa-plus px-1"></i>
            </button>
          </li>
          <span class="misc-collapse collapse" id="miscCollapse">
                           <li v-for="skill in filterPlayerSkills(player, 'misc')" v-bind:key="skill.id"
                               class="list-group-item d-flex">
                        <skill-item
                            :skill="skill"
                            :player="player"
                            @updateSkill="updateSkill"
                        ></skill-item>
                    </li>
                    </span>
        </ul>
      </div>
    </div>
    <modal name="add-strength" height="auto" :clickToClose="false" :adaptive="true">
      <div style="padding: 1rem" id="addStrengthForm">
        <div class="close-btn">
          <button @click="hide()">
            ❌
          </button>
        </div>
        <div class="form-group">
          <label>Stärke hinzufügen</label>
        </div>
        <div id="strengthComponent" ref="strengthComponent">
          <div class="row mb-2">
            <div class="col-lg-12">
                            <textarea type="text" class="form-control strengthComponentElement"
                                      placeholder="Stärke eintragen (max. 500 Zeichen)" maxlength="500"></textarea>
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-outline-danger w-100 mb-1"
                v-on:click="removeComponent($refs.strengthComponent)">-
        </button>
        <button type="button" class="btn btn-outline-primary w-100 mb-1"
                v-on:click="addComponent($refs.strengthComponent)">+
        </button>
        <div v-if="showSkill !== 'single'" class="selectAdditionalTeammates">
          Doppelpartner hinzufügen
          <ul v-for="team in player.teams" :key="team" class="list-group list-group-flush">
            <li v-if="team.player1_id !== player.id" class="list-group-item">
              <label>
                <input type="checkbox" v-model="teammates"
                       v-bind:value="team.player1_id">
                {{ team.player1.forename }} {{ team.player1.surname }}
              </label>
            </li>
            <li v-else-if="team.player2_id !== null" class="list-group-item">
              <label>
                <input type="checkbox" v-model="teammates"
                       v-bind:value="team.player2_id">
                {{ team.player2.forename }} {{ team.player2.surname }}
              </label>
            </li>
          </ul>
        </div>
        <button class="btn btn-primary add-skill-submit-btn" v-on:click="addSkill('strength')">
          Hinzufügen
        </button>
      </div>
    </modal>
    <modal name="add-weakness" height="auto" :clickToClose="false" :adaptive="true">
      <div style="padding: 1rem" id="addWeaknessForm">
        <div class="close-btn">
          <button @click="hide()">
            ❌
          </button>
        </div>
        <div class="form-group">
          <label>Schwäche hinzufügen</label>
        </div>
        <div id="weaknessComponent" ref="weaknessComponent">
          <div class="row mb-2">
            <div class="col-lg-12">
                            <textarea type="text" class="form-control weaknessComponentElement"
                                      placeholder="Schwäche eintragen (max. 500 Zeichen)" maxlength="500"></textarea>
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-outline-danger w-100 mb-1"
                v-on:click="removeComponent($refs.weaknessComponent)">-
        </button>
        <button type="button" class="btn btn-outline-primary w-100 mb-1"
                v-on:click="addComponent($refs.weaknessComponent)">+
        </button>
        <div v-if="showSkill !== 'single'" class="selectAdditionalTeammates">
          Doppelpartner hinzufügen
          <ul v-for="team in player.teams" :key="team" class="list-group list-group-flush">
            <li v-if="team.player1_id !== player.id" class="list-group-item">
              <label>
                <input type="checkbox" v-model="teammates"
                       v-bind:value="team.player1_id">
                {{ team.player1.forename }} {{ team.player1.surname }}
              </label>
            </li>
            <li v-else-if="team.player2_id !== null" class="list-group-item">
              <label>
                <input type="checkbox" v-model="teammates"
                       v-bind:value="team.player2_id">
                {{ team.player2.forename }} {{ team.player2.surname }}
              </label>
            </li>
          </ul>
        </div>
        <button class="btn btn-primary add-skill-submit-btn" v-on:click="addSkill('weakness')">Hinzufügen
        </button>
      </div>
    </modal>
    <modal name="add-misc" height="auto" :clickToClose="false" :adaptive="true">
      <div style="padding: 1rem" id="addMiscForm">
        <div class="close-btn">
          <button @click="hide()">
            ❌
          </button>
        </div>
        <div class="form-group">
          <label>Zusätzliche Information hinzufügen</label>
        </div>
        <div id="miscComponent" ref="miscComponent">
          <div class="row mb-2">
            <div class="col-lg-12">
                             <textarea type="text" class="form-control miscComponentElement"
                                       placeholder="Zusätzliche Information eintragen (max. 500 Zeichen)"
                                       maxlength="500"></textarea>
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-outline-danger w-100 mb-1"
                v-on:click="removeComponent($refs.miscComponent)">-
        </button>
        <button type="button" class="btn btn-outline-primary w-100 mb-1"
                v-on:click="addComponent($refs.miscComponent)">+
        </button>
        <div v-if="showSkill !== 'single'" class="selectAdditionalTeammates">
          Doppelpartner hinzufügen
          <ul v-for="team in player.teams" :key="team" class="list-group list-group-flush">
            <li v-if="team.player1_id !== player.id" class="list-group-item">
              <label>
                <input type="checkbox" v-model="teammates"
                       v-bind:value="team.player1_id">
                {{ team.player1.forename }} {{ team.player1.surname }}
              </label>
            </li>
            <li v-else-if="team.player2_id !== null" class="list-group-item">
              <label>
                <input type="checkbox" v-model="teammates"
                       v-bind:value="team.player2_id">
                {{ team.player2.forename }} {{ team.player2.surname }}
              </label>
            </li>
          </ul>
        </div>
        <button class="btn btn-primary add-skill-submit-btn" v-on:click="addSkill('misc')">
          Hinzufügen
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import {cordovaUtils} from "../../utils/cordovaUtils";
import SkillItem from "./SkillItem";
import toast from "../../utils/toast";


export default {
  name: 'PlayerSkills',
  components: {SkillItem},
  props: ['player'],
  data: function () {
    return {
      skillToAddVideoID: null,
      teammates: [],
      showSkill: this.$store.state.selectedTeamType,
    }
  },
  methods: {
    filterPlayerSkills(player, type) {
      if (this.showSkill === 'single') {
        let skills = this.singleSkills.filter(skill => skill.type === type);
        for (let key in skills) {
          try {
            let json = JSON.parse(skills[key].text);
            skills[key]["subskills"] = json.length > 1 ? json : null;
          } catch {
            skills[key]["subskills"] = null;
          }
        }
        return skills.reverse();
      } else if (this.showSkill === 'double') {
        let skills = this.doubleSkills.filter(skill => skill.type === type);
        this.groupSkillsByTeammate(skills);
        for (let key in skills) {
          try {
            let json = JSON.parse(skills[key].text);
            skills[key]["subskills"] = json.length > 1 ? json : null;
          } catch {
            skills[key]["subskills"] = null;
          }
        }
        return skills;
      } else {
        let skills = this.mixedSkills.filter(skill => skill.type === type);
        this.groupSkillsByTeammate(skills);
        for (let key in skills) {
          try {
            let json = JSON.parse(skills[key].text);
            skills[key]["subskills"] = json.length > 1 ? json : null;
          } catch {
            skills[key]["subskills"] = null;
          }
        }
        return skills;
      }
    },
    addVideoToSkill(skillID, video) {
      let self = this;
      this.player.skills.forEach(function (skill, index) {
        if (skill.id === skillID) {
          self.player.skills[index].videos.push(video)
        }
      });
    },
    addComponent(component) {
      let input = document.createElement("textarea");
      input.setAttribute("type", "text");
      input.setAttribute("placeholder", "Eintragen");
      input.setAttribute("maxlength", "500");
      input.classList.add(component.id + 'Element');
      input.classList.add(component.id + 'Element');
      input.classList.add("form-control");
      let col = document.createElement("div");
      col.classList.add("col-lg-12");
      col.appendChild(input);
      let row = document.createElement("div");
      row.classList.add("row");
      row.classList.add("mb-2");
      row.appendChild(col);
      component.appendChild(row);
    },
    removeComponent(component) {
      if (component.children.length > 1) {
        component.lastChild.remove()
      }
    },
    getVideoableType(player) {
      return player.class;
    },
    showAddStrength() {
      if (cordovaUtils.isOfflineCancel()) {
        return 0;
      }
      this.$modal.show('add-strength', {});
    },
    showAddWeakness() {
      if (cordovaUtils.isOfflineCancel()) {
        return 0;
      }
      this.$modal.show('add-weakness', {});
    },
    showAddMisc() {
      if (cordovaUtils.isOfflineCancel()) {
        return 0;
      }
      this.$modal.show('add-misc', {});
    },
    hide() {
      this.$modal.hide('add-strength');
      this.$modal.hide('add-weakness');
      this.$modal.hide('add-misc');
    },
    addSkill(type) {
      let text = '';
      if (this.showSkill !== 'single' && !this.teammates.length) {
        toast.error('Bitte Partner auswählen');
        return;
      }
      switch (type) {
        case 'strength':
          // eslint-disable-next-line no-case-declarations
          let strengthComponents = document.getElementById('addStrengthForm').getElementsByClassName('strengthComponentElement');
          // eslint-disable-next-line no-case-declarations
          let strengthComponentsArray = [];

          for (let i = 0; i < strengthComponents.length; i++) {
            let component = strengthComponents[i].value.trim();
            if (component && component.length <= 500) {
              strengthComponentsArray.push(component);
            } else {
              toast.error('Titel maximal 500 Zeichen');
              return;
            }
          }

          if (strengthComponentsArray.length > 0) {
            if (strengthComponentsArray.length === 1) {
              text = strengthComponentsArray[0];
            } else {
              text = JSON.stringify(strengthComponentsArray);
            }
          }
          break;
        case 'weakness':
          // eslint-disable-next-line no-case-declarations
          let weaknessComponents = document.getElementById('addWeaknessForm').getElementsByClassName('weaknessComponentElement');
          // eslint-disable-next-line no-case-declarations
          let weaknessComponentsArray = [];

          for (let i = 0; i < weaknessComponents.length; i++) {
            let component = weaknessComponents[i].value.trim();
            if (component && component.length <= 500) {
              weaknessComponentsArray.push(component);
            } else {
              toast.error('Titel maximal 500 Zeichen');
              return;
            }
          }

          if (weaknessComponentsArray.length > 0) {
            if (weaknessComponentsArray.length === 1) {
              text = weaknessComponentsArray[0];
            } else {
              text = JSON.stringify(weaknessComponentsArray);
            }
          }
          break;
        case 'misc':
          // eslint-disable-next-line no-case-declarations
          let miscComponents = document.getElementById('addMiscForm').getElementsByClassName('miscComponentElement');
          // eslint-disable-next-line no-case-declarations
          let miscComponentsArray = [];

          for (let i = 0; i < miscComponents.length; i++) {
            let component = miscComponents[i].value.trim();
            if (component && component.length <= 500) {
              miscComponentsArray.push(component);
            } else {
              toast.error('Titel maximal 500 Zeichen');
              return;
            }
          }

          if (miscComponentsArray.length > 0) {
            if (miscComponentsArray.length === 1) {
              text = miscComponentsArray[0];
            } else {
              text = JSON.stringify(miscComponentsArray);
            }
          }
          break;
        default:
          break;
      }
      this.$api.createPlayerSkill({
        segments: {
          id: this.player.id,
        },
        data: {
          type: type,
          text: text,
          teammates: this.teammates
        }
      }).then(response => {
            response.data['videos'] = [],
                // eslint-disable-next-line vue/no-mutating-props
                this.player.skills.push(response.data),
                this.hide()
          },
          this.teammates = []
      );
    },
    destroySkill(skill) {
      if (cordovaUtils.isOfflineCancel()) {
        return 0;
      }
      const result = confirm("Möchten sie wirklich den Skill \"" + skill.text + "\" aus der Datenbank löschen?");
      if (result) {
        this.$api.destroySkill({
          segments: {
            pid: this.player.id,
            sid: skill.id
          }
        }).then(function () {
          let index = -1;
          for (let i = 0; i < this.player.skills.length; i++) {
            if (this.player.skills[i].id === skill.id) {
              index = i;
              break;
            }
          }
          // eslint-disable-next-line vue/no-mutating-props
          this.player.skills.splice(index, 1);
        }.bind(this, skill));
      }
    },
    getSkillPartner(skill) {
      let self = this;
      let skillPartner = skill.players.find(function (player) {
        return player.id !== self.player.id;
      });
      return skillPartner.forename + ' ' + skillPartner.surname;
    },
    updateSkill(updatedSkill) {
      this.$emit('updateSkill', updatedSkill)
    },
    groupSkillsByTeammate(skills) {
      let self = this;
      skills.sort(function (a, b) {
        if (a.players[0].id === self.player.id) {
          if (b.players[0].id === self.player.id) {
            if (a.players[1].surname < b.players[1].surname) {
              return -1;
            } else {
              return 1;
            }
          } else {
            if (a.players[1].surname < b.players[0].surname) {
              return -1;
            } else {
              return 1;
            }
          }
        } else {
          if (b.players[0].id === self.player.id) {
            if (a.players[0].surname < b.players[1].surname) {
              return -1;
            } else {
              return 1;
            }
          } else {
            if (a.players[0].surname < b.players[0].surname) {
              return -1;
            } else {
              return 1;
            }
          }
        }
      });
    },
  },
  computed: {
    singleSkills() {
      let singleSkills = [];
      this.player.skills.forEach(function (skill) {
        if (skill.players.length === 1) {
          singleSkills.push(skill);
        }
      });
      return singleSkills;
    },
    doubleSkills() {
      let doubleSkills = [];
      let self = this;
      this.player.skills.forEach(function (skill) {
        if (skill.players[0].id === self.player.id) {
          if (skill.players.length > 1 && skill.players[1].gender === self.player.gender) {
            doubleSkills.push(skill);
          }
        } else {
          if (skill.players.length > 1 && skill.players[0].gender === self.player.gender) {
            doubleSkills.push(skill);
          }
        }
      });
      return doubleSkills;
    },
    mixedSkills() {
      let mixedSkills = [];
      let self = this;
      this.player.skills.forEach(function (skill) {
        if (skill.players[0].id === self.player.id) {
          if (skill.players.length > 1 && skill.players[1].gender !== self.player.gender) {
            mixedSkills.push(skill);
          }
        } else {
          if (skill.players.length > 1 && skill.players[0].gender !== self.player.gender) {
            mixedSkills.push(skill);
          }
        }
      });
      return mixedSkills;
    },
    isDouble() {
      let isDouble = false;
      let self = this;
      this.player.teams.forEach(function (team) {
        if (team.player1_id === self.player.id && team.player2_id !== null) {
          isDouble = true;
        } else if (team.player2_id === self.player.id) {
          isDouble = true;
        }

      });
      return isDouble;
    },
    isSingle() {
      let isSingle = false;
      this.player.teams.forEach(function (team) {
        if (team.player2_id === null) {
          isSingle = true;
        }
      });
      return isSingle;
    }
  }
}
</script>

<style>
.add-skill-btn {
  float: right;
}

.add-skill-submit-btn {
  float: right;
  margin-bottom: 1em;
}
</style>
