import FileAccessor from './file-accessor.jsx';

const STATE_FILE_NAME = 'state';

class StateRepository {
    constructor() {
        this.fileAccessor = new FileAccessor(STATE_FILE_NAME, 'storage');
    }

    saveState(state) {
        return this.fileAccessor.saveJSONData(state);
    }

    getState() {
        return this.fileAccessor.readJSONData();
    }
}


var stateRepository = new StateRepository();

export {
    stateRepository,
}