<template>
  <div class="bg-light">
    <div class="w-100 d-flex justify-content-center flex-wrap divider-box mt-3">
      <button class="btn w-50 py-3 mx-auto" @click="finishTagging"
              :class="isRecording && end ? 'btn-danger' : isRecording && !end ? 'btn-success' : 'btn-danger'"
              :disabled="isEndBeforeStart || end"
              v-tooltip.top-center="'(Strg + Backspace)'"
      >
        {{ isRecording ? 'Stop' : 'Start' }}
      </button>
    </div>
    <div :class="isRecording ? '' : 'disabledContent'">
      <div class="divider-box">
        <tagging-selection
            v-for="option in options" v-bind:key="option.id"
            :title="option.title"
            :options="option.tags"
            @optionSelected="updateTags($event)"
            ref="tagSelect"
        />
      </div>
      <div class="divider-box">
        <input
            v-model="name"
            class="form-control w-75 mx-auto"
            type="text"
            placeholder="Szenen Name"
            v-on:input="inputError = false"
        >
        <div v-if="inputError" class="w-100 text-center text-danger">
          Bitte vergeben Sie einen Namen!
        </div>
      </div>
      <div class="d-flex justify-content-center flex-wrap">
        <div class="w-100 text-center">
          Speichern
        </div>
        <button class="btn-default btn w-25 mx-auto mb-1" @click="resetWindow" v-tooltip.top-center="'(x)'">
          <i class="fas fa-2x fa-times-circle text-danger"></i>
        </button>
        <button class="btn-default btn w-25 mx-auto mb-1" @click="saveScene" :disabled="!saveSceneEnabled" v-tooltip.top-center="'(c)'">
          <i class="fas fa-2x fa-check-circle text-success"></i>
        </button>
      </div>
    </div>
    <modal name="uploadScenesModal" height="auto" :adaptive="true">
      <scene-upload-modal
          :video-id="videoId"
          @scenesImported="addScenes($event)"
      />
    </modal>
  </div>
</template>

<script>
import TaggingSelection from "@/components/Scenes/TaggingSelection";
import toast from "@/utils/toast";
import SceneUploadModal from "@/components/Scenes/SceneUploadModal";

export default {
  name: "TaggingContainer",
  components: {SceneUploadModal, TaggingSelection},
  props: ['videoTime', 'videoId', 'options'],
  data() {
    return this.initialState()
  },
  computed: {
    isEndBeforeStart() {
      if (this.start) {
        return parseFloat(this.start) >= (this.videoTime.toFixed(2) * 1000)
      }
      return false
    }
  },
  mounted() {
    document.addEventListener("keydown", (event) => {
      if (!['INPUT'].includes(document.activeElement.tagName)) {
        this.pressedKeys[event.key] = true;
        if (this.pressedKeys['Control'] && event.key === 'Backspace') {
          this.finishTagging()
        }
        if (event.key === 'x') {
          this.resetWindow()
        }
        if (event.key === 'c') {
          if (this.end) {
            this.saveScene()
          }
        }
      }
    })
    document.addEventListener('keyup', (event) => {
      delete this.pressedKeys[event.key];
    });
  },
  watch: {
    start(value) {
      this.$emit('taggingStarted', value)
    },
    end(value) {
      this.$emit('taggingStopped', value)
    }
  },
  beforeDestroy() {
    this.$emit('taggingStarted', null)
    this.$emit('taggingStopped', null)
  },
  methods: {
    initialState(name) {
      return {
        isRecording: false,
        saveSceneEnabled: false,
        inputError: false,
        start: null,
        end: null,
        selectedTags: [],
        name: name ? name : 'Ballwechsel',
        pressedKeys: {}
      }
    },
    resetWindow: function () {
      Object.assign(this.$data, this.initialState(this.name));
      this.$refs.tagSelect.forEach(select => select.resetSelection())
    },
    finishTagging() {
      if (!this.isRecording) {
        this.isRecording = true
        this.start = this.videoTime.toFixed(2) * 1000
      } else {
        if(this.start / 1000 < this.videoTime) {
          this.end = this.videoTime.toFixed(2) * 1000
          this.saveSceneEnabled = true
        }
      }
    },
    saveScene() {
      if (this.name.length) {
        const formData = {
          start: this.start,
          end: this.end,
          title: this.name,
          video_id: this.videoId,
          tags: this.selectedTags
        }

        this.$api.storeScene({
          data: formData
        }).then((res) => {
          toast.success('Scene successfully created')
          this.$emit('sceneAdded', res.data)
          this.resetWindow()
        })
      } else {
        this.inputError = true
      }
    },
    addScenes(scenes) {
      this.$emit('scenesImported', scenes)
    },
    updateTags(newTag) {
      const tagTypeExists = this.selectedTags.some((tag) => {
        return tag.type === newTag.type
      })
      const tagExists = this.selectedTags.some((tag) => {
        return tag.id === newTag.id
      })
      if (tagTypeExists) {
        let tagIndex = this.selectedTags.findIndex(function (tag) {
          return newTag.type === tag.type;
        });
        this.selectedTags.splice(tagIndex, 1);
      }
      if (!tagExists) {
        this.selectedTags.push(newTag)
      }
    },
    showModal() {
      this.$modal.show('uploadScenesModal')
    },
    convertSecToTime(seconds) {
      return new Date(seconds * 1000).toISOString().substring(11, 22)
    },
  }
}
</script>

<style scoped>
.divider-box {
  border-bottom: 1px solid;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.disabledContent {
  pointer-events: none;
  opacity: 0.4;
}
</style>