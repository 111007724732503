<template>
    <div class="row">
        <div class="col-lg-12">
            <h1>Openings</h1>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PlayerOpenings',
        props: ['player']
    }
</script>