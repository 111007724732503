<template>
  <div class="card">
    <div class="card-header" v-if="player">Spieler bearbeiten
      <button v-if="$store.state.user.role_name === 'admin'" @click="destroyPlayer()" class="btn btn-danger float-right"
              type="button">
        Löschen
      </button>
    </div>
    <div class="card-header" v-else>Neuen Spieler erstellen</div>

    <div class="card-body">
      <div class="container">
        <form v-on:submit.prevent="processForm()">
          <div class="form-group row">
            <label class="col-lg-3 col-form-label" for="inputForename">Vorname</label>
            <div class="col-lg-9">
              <input class="form-control" id="inputForename" placeholder="Vorname" required="required"
                     type="text" v-model="playerData.forename">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label" for="inputSurname">Nachname</label>
            <div class="col-lg-9">
              <input class="form-control" id="inputSurname" placeholder="Nachname" required="required"
                     type="text"
                     v-model="playerData.surname">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">Nationalität</label>
            <div class="col-lg-9">
              <select class="form-control" v-model="playerData.nation">
                <option v-for="country in NOC_Codes"
                        v-bind:key="country[0]"
                        v-bind:value="country[0]"
                >
                  {{ country[0] }} - {{ country[1] }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label" for="inputPlayingSystem">Spielsystem -
              <i>opt.</i></label>
            <div class="col-lg-9">
              <input class="form-control" id="inputPlayingSystem" placeholder="Spielsystem"
                     type="text"
                     v-model="playerData.playingSystem">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">Geschlecht</label>
            <div class="col-lg-9">
              <input type="radio" id="male" value="male" v-model="playerData.gender">
              <label for="male" class="ml-2">Männlich</label>
              <input type="radio" id="female" class="ml-4" value="female" v-model="playerData.gender">
              <label for="female" class="ml-2">Weiblich</label>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">Händigkeit</label>
            <div class="col-lg-9">
              <input type="radio" id="right" value="right" v-model="playerData.handedness">
              <label for="right" class="ml-2">Rechts</label>
              <input type="radio" id="left" class="ml-4" value="left" v-model="playerData.handedness">
              <label for="left" class="ml-2">Links</label>
            </div>
          </div>
          <div v-if="$store.state.game === 'table tennis' && !player" class="form-group row">
            <label class="col-lg-3 col-form-label" for="singleCheck">Einzelspieler</label>
            <input class="ml-3" type="checkbox" name="singleCheck"
                   id="singleCheck" v-model="createSingle"
            >
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label" for="file">Bild - <i>opt.</i></label>
            <div class="col-lg-9">
                            <span class="btn btn-secondary btn-file">
                             <input id="file" ref="file" type="file" accept="image/*"/>
                            </span>
              <button @click="deleteImage = true, processForm()"
                      :class="deleteImage ? 'btn btn-danger ml-1' : 'btn image-btn ml-1'" type="button"
                      v-if="player">
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
          <br>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">
              <button class="btn btn-primary" type="submit" v-if="player">Änderungen speichern</button>
              <button class="btn btn-primary" type="submit" v-else>Spieler erstellen</button>
            </label>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import toast from '../../utils/toast';
import {cordovaUtils} from './../../utils/cordovaUtils.jsx';
import countryCodes from "../../utils/countryCode";

export default {
  name: 'PlayerEdit',
  props: ['player', 'playerProposal'],
  data: function () {
    return {
      playerData: {
        forename: '',
        surname: '',
        gender: '',
        nation: '',
        handedness: '',
        playingSystem: '',
        // file: '',
        // id: ''
      },
      NOC_Codes: [],
      deleteImage: false,
      createSingle: false
    };
  },

  mounted: function () {
    this.NOC_Codes = countryCodes.getSortedCodes();

    if (this.player) {
      this.playerData.forename = this.player.forename;
      this.playerData.surname = this.player.surname;
      this.playerData.gender = this.player.gender;
      this.playerData.nation = this.player.nation;
      this.playerData.handedness = this.player.handedness;
      this.playerData.playingSystem = this.player.playing_system;

    } else if (this.playerProposal) {
      let capitalizing = function (word) {
        return word.toLowerCase()
            .replace(/(^|[^a-z0-9äöüß])([a-z])/g,
                function (m, m1, m2) {
                  return m1 + m2.toUpperCase();
                });
      };

      this.playerData.forename = capitalizing(this.playerProposal.forename);
      this.playerData.surname = capitalizing(this.playerProposal.surname);
      this.playerData.nation = this.playerProposal.nation;
    }
  },
  watch: {
    player() {
      this.playerData.forename = this.player.forename;
      this.playerData.surname = this.player.surname;
      this.playerData.gender = this.player.gender;
      this.playerData.nation = this.player.nation;
      this.playerData.handedness = this.player.handedness;
      this.playerData.playingSystem = this.player.playing_system;
    }
  },
  methods: {
    processForm: function () {

      if (cordovaUtils.isOfflineCancel()) {
        return 0;
      }
      // formData für den file upload
      const formData = new FormData();
      if (this.deleteImage) {
        const result = confirm('Möchten Sie das Profilbild wirklich löschen?');
        formData.append('deleteFile', this.deleteImage);
        if (!result) {
          this.deleteImage = false;
          return;
        }
      } else {
        formData.append('file', this.$refs.file.files[0]);
        formData.append('sport', this.$store.state.game);
        formData.append('forename', this.playerData.forename);
        formData.append('surname', this.playerData.surname);
        formData.append('gender', this.playerData.gender);
        formData.append('nation', this.playerData.nation);
        formData.append('handedness', this.playerData.handedness);
        formData.append('playing_system', this.playerData.playingSystem);
      }
      if (this.player) {
        formData.append('_method', 'patch');
        this.$api.updatePlayer({
          segments: {id: this.player.id},
          data: formData
        }).then(function (response) {
          const player = response['data'];
          if (!('error' in player)) {
            toast.success('Spieler "' + player.forename + ' ' + player.surname + '" wurde erfolgreich bearbeitet.');
            this.$store.commit('updatePlayer', this.playerData);
            this.updatePlayerData(player);
            document.getElementById('file').value = null;
            this.deleteImage = false;
          } else {
            this.showErrors(player, toast);
          }
        }.bind(this))
      } else {
        this.$api.storePlayer(
            {
              data: formData
            }
        ).then(function (response) {
          const player = response['data'];
          if (this.createSingle) {
            this.createSingleTeam(player);
          }
          if (!('error' in player)) {
            this.playerData.id = player.id;
            this.playerData.teamTypes = [];
            this.playerData.teams = [];
            //JSON muss kopriert werden
            this.$store.commit('addPlayer', JSON.parse(JSON.stringify(this.playerData)));
            toast.success('Spieler "' + player.forename + ' ' + player.surname + '" wurde erfolgreich erstellt.');
            if (!this.playerProposal) {
              this.$router.push({path: `/player/detail/${this.playerData.id}`})
            }
            this.$emit('storePlayerData', response);
            document.getElementById('file').value = null;
          } else {
            this.showErrors(player, toast);
          }
        }.bind(this))
      }
    },
    createSingleTeam(player) {
      this.$api.createTeam({
        segments: {id: player.id},
        data: {
          type: 'single',
          player2_id: null
        }
      })
    },
    updatePlayerData(player) {
      let playerIndex = this.$store.state.players.findIndex(function (storedPlayer) {
        return storedPlayer.id === player.id;
      });

      for (let key in player) {
        this.$set(this.player, key, player[key]);
        this.$set(this.$store.state.players[playerIndex], key, player[key]);
      }
    },
    showErrors(errors, toast) {
      if ('error' in errors) {
        errors = errors["error"];
        for (let e in errors) {
          toast.error(errors[e]);
        }
      } else {
        toast.error('Ein Fehler beim Speichern des neuen Spielers ist aufgetreten.');
      }
    },
    destroyPlayer() {
      if (cordovaUtils.isOfflineCancel()) {
        return 0;
      }
      const result = confirm("Möchten sie wirklich \"" + this.player.surname + ", " + this.player.forename + "\" aus der Datenbank löschen?");
      if (result) {
        this.$api.destroyPlayer({
          segments: {id: this.player.id}
        }).then(function (response) {
          if (response['data']['success']) {
            this.$store.commit('destroyPlayer', this.player);
            window.location.href = '#/player/list';
          }
        }.bind(this))
      }
    }
  }
}
</script>
