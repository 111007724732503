<template>
  <div class="flex gap-5">
    <div>
      <div v-if="positiveImpulsePlacementData.length">
        <div class="grid-container">
          <div v-for="zone in positiveImpulsePlacementData" :key="zone.zone">
            <div class="zone" :style="{ background: getColorGradient(zone.counts, 'positive')}"
                 @click="emitFilter($event, 'positive', 'placement', zone.zone)">
              {{ zone.counts }}
            </div>
          </div>
        </div>
      </div>
      <div class="border border-dark"></div>
      <div v-if="positiveImpulseZoneData.length">
        <div class="grid-container">
          <div v-for="zone in positiveImpulseZoneData" :key="zone.zone">
            <div class="zone" :style="{ background: getColorGradient(zone.counts, 'positive')}"
                 @click="emitFilter($event, 'positive', 'zone', zone.zone)">
              {{ zone.counts }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pitch-label">
      <div>Schlag-platzierung</div>
      <div>Schlagzone</div>
    </div>

    <div>
      <div v-if="negativeImpulsePlacementData.length">
        <div class="grid-container">
          <div v-for="zone in negativeImpulsePlacementData" :key="zone.zone">
            <div class="zone" :style="{ background: getColorGradient(zone.counts, 'negative')}"
                 @click="emitFilter($event, 'negative', 'placement', zone.zone)">
              {{ zone.counts }}
            </div>
          </div>
        </div>
      </div>
      <div class="border border-dark"></div>
      <div v-if="negativeImpulseZoneData.length">
        <div class="grid-container">
          <div v-for="zone in negativeImpulseZoneData" :key="zone.zone">
            <div class="zone" :style="{ background: getColorGradient(zone.counts, 'negative')}"
                 @click="emitFilter($event, 'negative', 'zone', zone.zone)">
              {{ zone.counts }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {onBeforeMount, onMounted, ref, watch} from "vue";

export default {
  name: 'FilterTable',
  props: {
    impulseZoneData: {
      type: Array,
      required: true
    },
    impulsePlacementData: {
      type: Array,
      required: true
    }
  },
  methods: {
    emitFilter(event, pitchImpulse, pitchHalf, pitchZone) {
      let filter = {
        pitchImpulse: pitchImpulse,
        pitchHalf: pitchHalf,
        pitchZone: pitchZone,
        ctrlKey: event.ctrlKey
      }
      this.$emit('updateFilter', filter)
    }
  },
  setup(props) {
    const positiveImpulseZoneData = ref([])
    const positiveImpulsePlacementData = ref([])
    const negativeImpulseZoneData = ref([])
    const negativeImpulsePlacementData = ref([])
    const zoneOrder = ['links vorne', 'mitte vorne', 'rechts vorne', 'links mitte', 'mitte mitte', 'rechts mitte', 'links hinten', 'mitte hinten', 'rechts hinten']
    const placementOrder = ['links hinten', 'mitte hinten', 'rechts hinten', 'links mitte', 'mitte mitte', 'rechts mitte', 'links vorne', 'mitte vorne', 'rechts vorne']

    const setData = (inputData, pitchImpulse) => {
      // split zones in negative and positive
      let positiveZones = []
      let negativeZones = []
      inputData.forEach((data) => {
        if (data.label === 'Positiver Impuls') {
          positiveZones.push(data)
        }
        if (data.label === 'Negativer Impuls') {
          negativeZones.push(data)
        }
      })

      // add missing zones
      zoneOrder.forEach((zone) => {
        if (!positiveZones[0].data.find((data) => data.zone === zone)) {
          positiveZones[0].data.push({zone: zone, counts: 0})
        }
        if (!negativeZones[0].data.find((data) => data.zone === zone)) {
          negativeZones[0].data.push({zone: zone, counts: 0})
        }
      })

      // sort zones
      if (pitchImpulse === 'zone') {
        positiveImpulseZoneData.value = positiveZones[0].data.sort((a, b) => zoneOrder.indexOf(a.zone) - zoneOrder.indexOf(b.zone))
        negativeImpulseZoneData.value = negativeZones[0].data.sort((a, b) => zoneOrder.indexOf(a.zone) - zoneOrder.indexOf(b.zone))
      } else if (pitchImpulse === 'placement') {
        positiveImpulsePlacementData.value = positiveZones[0].data.sort((a, b) => placementOrder.indexOf(a.zone) - placementOrder.indexOf(b.zone))
        negativeImpulsePlacementData.value = negativeZones[0].data.sort((a, b) => placementOrder.indexOf(a.zone) - placementOrder.indexOf(b.zone))
      }
    }

    let positiveRange, negativeRange

    const setDataRanges = () => {
      const positiveZoneMax = Math.max(...props.impulseZoneData[1].data.map((data) => data.counts))
      const negativeZoneMax = Math.max(...props.impulseZoneData[0].data.map((data) => data.counts))
      const positivePlacementMax = Math.max(...props.impulsePlacementData[1].data.map((data) => data.counts))
      const negativePlacementMax = Math.max(...props.impulsePlacementData[0].data.map((data) => data.counts))
      positiveRange = [0, Math.max(positiveZoneMax, positivePlacementMax)]
      negativeRange = [0, Math.max(negativeZoneMax, negativePlacementMax)]
    }

    const getColorGradient = (value, pitchImpulse) => {
      let range = []
      if (pitchImpulse === 'positive')
        range = positiveRange
      else if (pitchImpulse === 'negative')
        range = negativeRange

      const percentage = (value - range[0]) / (range[1] - range[0]);
      const colorShade = (percentage === 0 || isNaN(percentage)) ? 0.1 : percentage

      return pitchImpulse === 'positive' ?
          `rgb(20, 200, 50, ${colorShade})` :
          `rgb(255, 0, 0, ${colorShade})`;
    }

    onBeforeMount(() => {
      setDataRanges()
    })

    onMounted(() => {
      setData(props.impulseZoneData, 'zone')
      setData(props.impulsePlacementData, 'placement')
    })

    watch(() => props.impulseZoneData, () => {
      setDataRanges()
      setData(props.impulseZoneData, 'zone')
      setData(props.impulsePlacementData, 'placement')
    })

    watch(() => props.impulsePlacementData, () => {
      setDataRanges()
      setData(props.impulseZoneData, 'zone')
      setData(props.impulsePlacementData, 'placement')
    })

    return {
      positiveImpulseZoneData,
      negativeImpulseZoneData,
      positiveImpulsePlacementData,
      negativeImpulsePlacementData,
      getColorGradient
    }
  }
}
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns:  repeat(3, minmax(0, 1fr));
  width: 150px;
}

.zone {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.pitch-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  word-break: normal;
  font-size: 14px;
  padding-bottom: 20px;
  gap: 75px;
  width: 70px;
}
</style>
