import FileAccessor from "./file-accessor";

export default class PlayerDataAccessor {

    constructor(player_id) {
        this.fileAccessor = new FileAccessor(player_id, 'player');
        this.imageAccessor = new FileAccessor(player_id, 'playerImages');
    }

    savePlayer(player) {
        let self = this;
        return this.fileAccessor.saveJSONData(player).then(function () {
            if (typeof player.absolute_path === 'undefined' || player.absolute_path===null) {
                return null;
            } else {
                return self.imageAccessor.saveLargeFile(player.absolute_path)
            }

        });
    }

    updatePlayer(player){
        return this.fileAccessor.saveJSONData(player)
    }

    getImage() {
        return this.imageAccessor.getFileURL();
    }

    getPlayer() {
        return this.fileAccessor.readJSONData();
    }

    removePlayer() {
        let self = this;
        return this.fileAccessor.removeFile().then(function () {
            return self.imageAccessor.removeFile();
        });
    }
}