import Vue from "vue";
import VueRouter from 'vue-router'
import Home from '../components/Home'
import Player from '../components/Player/Player'
import PlayerList from '../components/Player/PlayerList'
import PlayerEdit from '../components/Player/PlayerEdit'
import Video from '../components/Video/Video'
import VideoEdit from '../components/Video/VideoEdit'
import GameImporterOverview from '../components/Game/GameImporterOverview'
import Game from '../components/Game/Game'
import GameDetail from "@/components/Game/GameDetail";

import UserRegistration from '../components/UserRegistration'
import Impressum from "../components/Impressum";
import VideoFullScreen from "@/components/Video/VideoFullScreen.vue";
import AnalysisMainPage from "@/components/Analysis/AnalysisMainPage.vue";

Vue.use(VueRouter);

const routes = [
    {path: '/', component: Home},
    {path: '/player/list/:gender(male|female)?/:type(single|doubles|mixed)?', component: PlayerList},
    {path: '/player/edit/:id(\\d+)?', component: PlayerEdit},
    {path: '/player/detail/:id(\\d+)', component: Player},
    {path: '/player/detail/:id(\\d+)/:tab/:subTab', component: Player},
    {path: '/:type(video)/:id(\\d+)', component: Video},
    {path: '/:type(video)/:id(\\d+)/analysis', component: AnalysisMainPage, name: 'analysis'},
    {path: '/:type(video)/:id(\\d+)/fullscreen', component: VideoFullScreen},
    {path: '/video/edit/:vid(\\d+)/:vt/:id(\\d+)?', component: VideoEdit},
    {path: '/game/import', component: GameImporterOverview},
    {path: '/game/files/:id(\\d+)', component: GameDetail},
    {path: '/user/registration', component: UserRegistration},
    {path: '/game/detail/:id(\\d+)', component: Game},
    {path: '/:type(game)/video/:id(\\d+)/:scenes', component: Video},
    {path: '/impressum', component: Impressum}
];

const router = new VueRouter({routes});

export default router;
