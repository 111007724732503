<template>
    <span>
        <div class="row">
            <div class="col-12 text-right">
                <button @click="importGame()" class="btn btn-warning" v-if="importableGames && importableGames.length">
                    <i class="fas fa-tasks"></i> Starte alle Imports
                </button>
            </div>
            <div class="col-12">
                <table class="table bg-white mt-2" v-if="importableGames">
                    <tr v-bind:key="importID" v-for="(game, importID) in importableGames">
                        <td>{{ game }}</td>
                        <td>
                            <button @click="importGame(game)" class="btn btn-info">
                                <i class="fas fa-download"></i> Starte Einzelimport
                            </button>
                        </td>
                    </tr>
                </table>
                <div class="card bg-warning mt-3" v-if="importableGames !== null && importableGames.length === 0">
                    <div class="card-body">
                        Aktuell sind keine importierbare Spiele offen.
                    </div>
                </div>
            </div>
        </div>

        <modal name="add-player" :width="600" height="auto" class="overflow-auto" :clickToClose="false">
            <div class="close-btn" style="z-index: 1; top: 10px">
                    <button @click="$modal.hide('add-player')">
                        ❌
                    </button>
                </div>
            <template v-if="playerProposal">
                <player-edit
                        :playerProposal="playerProposal"
                        @storePlayerData="playerAdded"
                >
                </player-edit>
            </template>
        </modal>

    </span>
</template>

<script>
    import toast from '../../utils/toast';
    import PlayerEdit from "./../Player/PlayerEdit";

    export default {
        name: 'GameImporterOverview',
        components: {PlayerEdit},
        data: function () {
            return {
                importableGames: null,
                activeImport: false,
                playerProposal: null
            };
        },
        mounted: function () {
            this.$api.getImportableGames({
                segments: {sport: this.$store.state.game}
            }).then(response => {
                this.importableGames = response.data;
            });
        },
        methods: {
            importGame(name) {
                const games = typeof name !== 'undefined' ? [name] : this.importableGames;

                this.$api.importGame({
                    data: {
                        sport: this.$store.state.game,
                        games: games
                    }
                }).then(response => {
                    if (response.data.error) {
                        if(response.data.importedGames.length){
                            toast.success(response.data.importedGames.length === 1
                                ? 'Spiel importiert'
                                : response.data.importedGames.length + ' Spiele importiert'
                            );
                        }

                        response.data.importedGames.forEach((game) => {
                            this.importableGames = this.importableGames.filter(importable => importable !== game.tournament)
                        });

                        switch (response.data.error){
                            case 'no_player':
                                this.showAddPlayerModal(response.data);
                                break;
                            case 'no_video':
                                toast.error("Kein Video gefunden in " + response.data.game);
                                break;
                            case 'no_statistics':
                                toast.error("Datei statistics.htm nicht gefunden in " + response.data.game);
                                break;
                            case 'missing_player':
                                toast.error("Es konnten nicht alle Spieler identifiziert werden. statistics.htm überprüfen");
                                break;
                            default:
                                toast.error("Spiel konnte nicht importiert werden");
                        }

                    } else {
                        if(response.data.length){
                            toast.success(response.data.length === 1
                                ? 'Spiel importiert'
                                : response.data.length + ' Spiele importiert'
                            );
                        }

                        games.forEach((gameName) => {
                            this.importableGames = this.importableGames.filter(importable => importable !== gameName)
                        });
                    }
                });

            },
            showAddPlayerModal: function (playerProposal) {
                toast.error(playerProposal.game + ' wurde festgestellt das ein Spieler noch nicht angelegt wurde.')
                this.playerProposal= playerProposal;
                this.$modal.show('add-player');
            },
            playerAdded: function () {
                this.$modal.hide('add-player');
            }
        }
    }
</script>
