<template>
  <div v-if="possibleTags.includes(title)">
    <div class="d-flex justify-content-between mb-1 mx-4">
      <button style="border: 1px solid"
              class="btn w-25 py-2 mx-3"
              :class="checkSelected(options[0]) ? 'btn-primary' : 'btn-default'"
              @click="selectOption(options[0])"
              v-tooltip.top-center="showTooltip(options[0])"
      >
        <span class="font-weight-bold">{{ options[0].title }}</span>
      </button>
      <div class="pt-2">
        {{ title }}
      </div>
      <button style="border: 1px solid"
              class="btn w-25 py-2 mx-3"
              :class="checkSelected(options[1]) ? 'btn-primary' : 'btn-default'"
              @click="selectOption(options[1])"
              v-tooltip.top-center="showTooltip(options[1])"
      >
        <span class="font-weight-bold">{{ options[1].title }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaggingSelection",
  props: ['title', 'options'],
  data() {
    return {
      selectedOption: null,
      possibleTags: ['Aufschlag', 'Punkt', 'Qualität'],
      tooltip: {
        serveA: '(a)',
        serveB: '(s)',
        pointA: '(w)',
        pointB: '(e)',
        qualityPlus: '(+)',
        qualityMinus: '(-)',
      },
    }
  },
  mounted() {
    document.addEventListener("keydown", (event) => {
      if (!['INPUT'].includes(document.activeElement.tagName)) {
        if (this.possibleTags.includes(this.title)) {
          switch (event.key) {
            case 'a': {
              let option = this.options.find((option) => {
                return this.title === 'Aufschlag' && option.title === 'A'
              })
              if (option) {
                this.selectOption(option)
              }
              break
            }
            case 's': {
              let option = this.options.find((option) => {
                return this.title === 'Aufschlag' && option.title === 'B'
              })
              if (option) {
                this.selectOption(option)
              }
              break
            }
            case 'w': {
              let option = this.options.find((option) => {
                return this.title === 'Punkt' && option.title === 'A'
              })
              if (option) {
                this.selectOption(option)
              }
              break
            }
            case 'e': {
              let option = this.options.find((option) => {
                return this.title === 'Punkt' && option.title === 'B'
              })
              if (option) {
                this.selectOption(option)
              }
              break
            }
            case '+': {
              let option = this.options.find((option) => {
                return this.title === 'Qualität' && option.title === '+'
              })
              if (option) {
                this.selectOption(option)
              }
              break
            }
            case '-': {
              let option = this.options.find((option) => {
                return this.title === 'Qualität' && option.title === '-'
              })
              if (option) {
                this.selectOption(option)
              }
              break
            }
          }
        }
      }
    })
  },
  methods: {
    selectOption(option) {
      if (this.checkSelected(option)) {
        this.selectedOption = null;
      } else {
        this.selectedOption = option.id;
      }
      const tagObject = {
        type: this.title,
        id: option.id
      }
      this.$emit('optionSelected', tagObject);
    },
    checkSelected(option) {
      return option.id === this.selectedOption;
    },
    showTooltip(option) {
      if(this.title === 'Aufschlag') {
        return option.title === 'A' ? this.tooltip.serveA : this.tooltip.serveB
      } else if (this.title === 'Punkt') {
        return option.title === 'A' ? this.tooltip.pointA : this.tooltip.pointB
      } else if (this.title === 'Qualität') {
        return option.title === '+' ? this.tooltip.qualityPlus : this.tooltip.qualityMinus
      }
    },
    resetSelection() {
      this.selectedOption = null
    }
  },
  beforeDestroy() {
    document.removeEventListener('keyup', () => {
      return true
    })
    document.removeEventListener('keydown', () => {
      return true
    })
  }
}
</script>

<style scoped>

</style>