<template>
    <div id="annotationsContainer" v-if="annotatableId" class="bg-light">
        <annotation-submission
                v-if="!selectedAnnotation"
                :annotationType="annotationType"
                :annotatableObject="annotatableObject"
                :currentTime="currentTime"
        ></annotation-submission>
        <div v-bind:key="annotation.id" v-for="(annotation, index) in getAnnotations">
            <div :class="['annotation', 'card', 'm-2', 'p-2', 'border-info', annotation.id === selectedAnnotation ? 'bg-info' : 'bg-default',
             annotation.timestamp === currentTime ? 'highlighted' : '']">
                <annotation-item :annotation="annotation"
                                 :selectable="annotation.id !== selectedAnnotation"
                                 @selectAnnotation="function() {
                                   selectAnnotation(selectedAnnotation, annotation.id)
                                 }"
                                 @deleteAnnotation="deleteAnnotation(index)"
                                 @updateParent="updateAnnotation"
                                 @jumpToTimestamp="jumpToTimestamp"
                ></annotation-item>
            </div>
            <div v-if="annotatableId && annotation.id === selectedAnnotation">
                <annotation-submission
                        :annotationType="'App\\Annotation'"
                        :annotatableObject="annotation"
                        @openAnswerDropdown="showAnswer($event)"
                ></annotation-submission>
            </div>
            <div v-if="annotation.annotations.length">
                <div class="px-2 mx-2 show-answer-btn" data-toggle="collapse"
                     :href="'#subAnnotationCollapse' + annotation.id" role="button" aria-expanded="false"
                     :aria-controls="'subAnnotationCollapse' + annotation.id">
                    <span @click="showAnswer(annotation.id)" v-if="!showAnswers.includes(annotation.id)">Antworten anzeigen<i
                            class="fas fa-caret-down ml-2"></i></span>
                    <span @click="hideAnswer(annotation.id)" v-else>Antworten ausblenden<i
                            class="fas fa-caret-up ml-2"></i></span>
                </div>
            </div>
            <div :id="'subAnnotationCollapse' + annotation.id" class="subannotation-collapse collapse">
                <div class="annotation card m-2 ml-5 p-2 border-info bg-default" v-bind:key="sub_annotation.id"
                     v-for="(sub_annotation, subIndex) in annotation.annotations">
                    <annotation-item :annotation="sub_annotation"
                                     @deleteAnnotation="deleteAnnotation(index, subIndex)"
                                     @updateParent="updateAnnotation"
                    ></annotation-item>
                </div>
            </div>
        </div>
    </div>
    <div v-else id="invisibleAnnotationsContainer">
        <annotation-submission class="invisible-element"></annotation-submission>
    </div>
</template>

<script>

    import AnnotationSubmission from "./AnnotationSubmission";
    import AnnotationItem from "./AnnotationItem";

    export default {
        name: "AnnotationsContainer",
        components: {AnnotationItem, AnnotationSubmission},
        props: ['annotations', 'annotatableId', 'annotatableObject', 'annotationType', 'currentTime'],
        data: function () {
            return {
                selectedAnnotation: null,
                showAnswers: [],
            }
        },
        watch: {
            annotatableId: function () {
                this.selectedAnnotation = null
            }
        },
        methods: {
            selectAnnotation: function (selectedAnnotation, annotationId) {
                if (selectedAnnotation !== annotationId) {
                    this.selectedAnnotation = annotationId;
                } else {
                    this.selectedAnnotation = null
                }
            },
            deleteAnnotation: function (annotationIndex, subAnnotationIndex) {
                if (subAnnotationIndex >= 0) {
                  // eslint-disable-next-line vue/no-mutating-props
                    this.annotations[annotationIndex].annotations.splice(subAnnotationIndex, 1)
                } else {
                  // eslint-disable-next-line vue/no-mutating-props
                    this.annotations.splice(annotationIndex, 1)
                }
            },
            updateAnnotation: function (updatedAnnotation) {
                for (let i in this.annotations) {
                    if (this.annotations[i].id === updatedAnnotation.id) {
                        this.$set(this.annotations, i, updatedAnnotation);
                        return 0;
                    }
                    if (this.annotations[i].annotations) {
                        for (let j in this.annotations[i].annotations) {
                            if (this.annotations[i].annotations[j].id === updatedAnnotation.id) {
                                this.$set(this.annotations[i].annotations, j, updatedAnnotation);
                                return 0;
                            }
                        }
                    }
                }
            },
            jumpToTimestamp: function (timestamp) {
                if(this.$route.params.scenes) {
                    this.$root.$emit('jumpToTimestamp', timestamp)
                } else {
                  this.$emit('commentSelected', timestamp)
                }
            },
            showAnswer: function (annotationId) {
                this.showAnswers.push(annotationId);
            },
            hideAnswer: function (annotationId) {
                this.showAnswers = this.showAnswers.filter(annotation => annotation !== annotationId);
            }
        },
        computed: {
            getAnnotations: function () {
              let self = this;
                return self.annotations.sort(function (a, b) {
                  return a.timestamp > b.timestamp;
                });
            }
        }
    }
</script>

<style lang="scss">
    @import '../../assets/sass/annotation';
</style>
