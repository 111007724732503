<template>
    <div class="row m-0 p-2" id="drawing-controls">
        <div class="row col-12">
            <button :class="getShapeButtonClasses('square')" @click="setShape('square')" type="button">
                <i class="fas fa-square"></i>
            </button>
            <button :class="getShapeButtonClasses('circle')" @click="setShape('circle')" type="button">
                <i class="fas fa-circle"></i>
            </button>
            <button :class="getShapeButtonClasses('arrow')" @click="setShape('arrow')" type="button">
                <i class="fas fa-long-arrow-alt-right"></i>
            </button>
            <button class="btn video-btn btn-secondary col-2" @click="undoMarker()" type="button">
                <i class="fas fa-undo"></i>
            </button>
            <div class="dropup ml-2">
                <button aria-expanded="false" aria-haspopup="true"
                        class="btn btn-secondary video-btn dropdown-toggle" data-toggle="dropdown" id="markerColorDropdown"
                        type="button">
                    <i :style="{color: this.markerColor}" class="fas fa-paint-brush"></i>
                </button>

                <div aria-labelledby="markerColorDropdown" class="dropdown-menu">
                    <button @click="setMarkerColor('red')" class="dropdown-item">Rot</button>
                    <button @click="setMarkerColor('blue')" class="dropdown-item">Blau</button>
                    <button @click="setMarkerColor('green')" class="dropdown-item">Grün</button>
                    <button @click="setMarkerColor('yellow')" class="dropdown-item">Gelb</button>
                </div>
            </div>
        </div>
        <div class="row col-12">
            <div class="col-4 mt-1">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Dauer</span>
                    </div>
                    <input class="form-control" min="0" type="number" v-model.number="markerDuration">
                    <div class="input-group-append">
                        <span class="input-group-text">s</span>
                    </div>
                </div>
            </div>
            <div class="mt-1">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <input :false-value="0" :true-value="1" id="pauseVideo" type="checkbox" v-model="pauseVideo">
                        </div>
                    </div>
                    <label class="form-control" for="pauseVideo">Pausiert Video</label>
                </div>
            </div>
            <button @click="cancelEditing()" class="btn btn-secondary video-btn col-1 ml-auto" type="button">
                <i class="fas fa-times"></i>
            </button>
            <button @click="finishOverlay()" class="btn btn-secondary video-btn col-1" type="button">
                <i class="fas fa-check"></i>
            </button>
        </div>
    </div>
</template>

<script>
    import 'bootstrap/js/dist/dropdown';
    import toast from '../../utils/toast';

    export default {
        name: 'DrawingControls',
        data: function () {
            return {
                canvas: null,
                ctx: null,
                shape: null,
                canvasX: null,
                canvasY: null,
                lastMouseX: null,
                lastMouseY: null,
                markerWidth: null,
                markerHeight: null,
                markerColor: 'red',
                markerDuration: null,
                pauseVideo: null,
                isMouseDown: false,
                videoOverlay: {},
                newMarkers: 0,
            };
        },
        props: ['showDrawingTools', 'currentOverlay'],
        watch: {
            showDrawingTools() {
                if (this.showDrawingTools) {
                    this.videoOverlay = this.currentOverlay;
                    this.pauseVideo = this.currentOverlay.does_pause;
                    this.markerDuration = this.currentOverlay.end - this.currentOverlay.start;
                } else {
                    [this.videoOverlay, this.pauseVideo, this.markerDuration] = [null];

                }
            },
        },
        methods: {
            setShape: function (shape) {
                this.shape = (this.shape !== shape) ? shape : null;
            },
            getShapeButtonClasses: function (shape) {
                return [
                    'btn',
                    'btn-' + (shape === this.shape ? 'primary' : 'secondary'),
                    'video-btn',
                    'col-2'
                ]
            },
            setMarkerColor: function (markerColor) {
                this.markerColor = markerColor;
            },
            canvasMounted: function (canvas) {
                this.canvas = canvas;
                this.canvasX = this.canvas.getBoundingClientRect().left;
                this.canvasY = this.canvas.getBoundingClientRect().top;
                this.ctx = this.canvas.getContext('2d');
                this.canvas.addEventListener('mousedown', this.mouseDown);
                this.canvas.addEventListener('mouseup', this.mouseUp);
                this.canvas.addEventListener('mousemove', this.mouseMove);
                this.canvas.addEventListener('touchstart', this.touchstart);
                this.canvas.addEventListener('touchend', this.touchend);
                this.canvas.addEventListener('touchmove', this.touchmove);
            },
            touchstart: function (event) {
                this.startDrawing(event.touches[0]);
            },
            touchend: function () {
                this.isMouseDown = false;
            },
            touchmove: function (event) {
                this.drawingMove(event.touches[0]);
            },
            mouseDown: function (event) {
                this.startDrawing(event);
            },
            mouseUp: function () {
                this.isMouseDown = false;
            },
            mouseMove: function (event) {
                this.drawingMove(event);
            },
            startDrawing: function (e) {
                this.lastMouseX = (e.clientX - this.canvasX);
                this.lastMouseY = (e.clientY - this.canvasY);
                this.newMarkers += 1;
                if (this.shape !== null) {
                    this.isMouseDown = true;

                    this.videoOverlay.video_markers.push({
                        color: this.markerColor,
                        x_position: this.lastMouseX / this.canvas.width,
                        y_position: this.lastMouseY / this.canvas.height,
                        height: 0,
                        width: 0,
                        shape: this.shape,
                    });
                }
            },
            drawingMove: function (e) {
                let mouseX = parseInt(e.clientX - this.canvasX);
                let mouseY = parseInt(e.clientY - this.canvasY);
                if (this.isMouseDown) {
                    this.markerWidth = mouseX - this.lastMouseX;
                    this.markerHeight = mouseY - this.lastMouseY;

                    this.videoOverlay.video_markers[this.videoOverlay.video_markers.length - 1].height = this.markerHeight / this.canvas.height;
                    this.videoOverlay.video_markers[this.videoOverlay.video_markers.length - 1].width = this.markerWidth / this.canvas.width;
                    this.$emit('overlayUpdated', this.videoOverlay);
                }
            },
            cancelEditing: function () {
                this.$emit('canceledEditing', {overlay: this.videoOverlay, newMarkers: this.newMarkers});
                this.newMarkers = 0;
                this.$emit('controlsSwitched');
            },
            undoMarker: function () {
                this.videoOverlay.video_markers.pop();
                this.$emit('overlayUpdated', this.videoOverlay);
            },
            finishOverlay: function () {
                this.videoOverlay.end = this.videoOverlay.start + this.markerDuration;
                this.videoOverlay.does_pause = this.pauseVideo;
                let self = this;
                this.$api.updateOverlay({
                    segments: {
                        vId: self.$route.params.id,
                        oId: self.currentOverlay.id
                    },
                    data: {
                        _method: 'patch',
                        start: self.videoOverlay.start.toString(),
                        end: (self.videoOverlay.start + self.markerDuration).toString(),
                        does_pause: self.pauseVideo.toString(),
                    }
                }).then(() => {
                        self.$api.createNewMarker({
                            segments: {
                                vId: self.$route.params.id,
                                oId: self.currentOverlay.id
                            },
                            data: {
                                video_markers: self.videoOverlay.video_markers
                            }
                        }).then(
                            toast.success('Overlay geändert')
                        );
                        self.shape = null;
                        self.newMarkers = 0;
                        self.$emit('controlsSwitched');
                    }
                );
            }
        
        }
    }
</script>