<template>
  <ul class="list-unstyled components">
    <li @click="emitTeamType('undo')">
      <router-link to="/player/list"><i class="fas fa-users pr-2"></i>Alle</router-link>
    </li>
    <li>
            <span @click="emitTeamType('undo')">
                <router-link to="/player/list/female"><i class="fas fa-venus pr-3"></i>Damen</router-link>
            </span>
      <ul v-if="$store.state.game === 'badminton'" class="collapse list-unstyled sidebar-submenu show">
        <li @click="emitTeamType('Einzel')">
          <router-link to="/player/list/female/single">Einzel</router-link>
        </li>
        <li @click="emitTeamType('Doppel')">
          <router-link to="/player/list/female/doubles">Doppel</router-link>
        </li>
        <li @click="emitTeamType('Mixed')">
          <router-link to="/player/list/female/mixed">Mixed</router-link>
        </li>
      </ul>
    </li>
    <li>
            <span @click="emitTeamType('undo')">
                <router-link to="/player/list/male"><i class="fas fa-mars pr-3"></i>Herren</router-link>
            </span>
      <ul v-if="$store.state.game === 'badminton'" class="collapse list-unstyled sidebar-submenu show">
        <li @click="emitTeamType('Einzel')">
          <router-link to="/player/list/male/single">Einzel</router-link>
        </li>
        <li @click="emitTeamType('Doppel')">
          <router-link to="/player/list/male/doubles">Doppel</router-link>
        </li>
        <li @click="emitTeamType('Mixed')">
          <router-link to="/player/list/male/mixed">Mixed</router-link>
        </li>
      </ul>
    </li>
    <li v-if="$store.state.user.role_name !== 'player'">
      <router-link to="/player/edit/"><i class="fas fa-user-plus pr-2"></i>Neuer Spieler</router-link>
    </li>
    <li v-if="$store.state.user.role_name === 'admin'">
      <router-link to="/game/import/"><i class="fas fa-server pr-2"></i>Spiele importieren</router-link>
    </li>
    <li v-if="$store.state.user.role_name === 'admin'">
      <router-link to="/user/registration/"><i class="fas fa-user-edit pr-2"></i>Nutzerverwaltung</router-link>
    </li>
    <li class="mb-3">
      <router-link to="/impressum/"><i class="fas fa-info-circle pr-2"></i>Impressum</router-link>
    </li>

    <!--        <li v-if="$store.state.user.role_name === 'admin'">-->
    <!--            <router-link to="/user/registration/">Nutzer anlegen</router-link>-->
    <!--        </li>-->
  </ul>
</template>

<script>
    export default {
      // eslint-disable-next-line vue/multi-word-component-names
        name: 'Sidebar',
        methods: {
            syncOfflineData: function () {
                this.$emit('syncOfflineData');
            },
            emitTeamType(type) {
                switch (type) {
                    case 'Einzel' : this.$store.state.selectedTeamType = 'single';
                    break;
                    case 'Doppel' : this.$store.state.selectedTeamType = 'double';
                    break;
                    case 'Mixed' : this.$store.state.selectedTeamType = 'mixed';
                    break;
                    default: this.$store.state.selectedTeamType = 'single';
                }
            }
        }
    }
</script>

<style lang="scss">
@import '../assets/sass/sidebar';

#sign-out {
  left: 1rem;
  bottom: 1rem;
  position: fixed;

  .fa-sign-out-alt {
    font-size: 30px;
  }
}


</style>
