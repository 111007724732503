<template>
  <div style="padding: 1rem">
    <div class="close-btn">
      <button @click="hide()">
        ❌
      </button>
    </div>
    <div class="form-group">
      <div class="row">
        <label class="file-label col-8">Dartclip Datei importieren</label>
        <div class="col-12 mb-4" @drop.prevent="addDroppedFile" @dragover.prevent>
          <input v-if="!dartclip"
                 id="dartclip-upload" type="file" ref="dartclip"
                 v-on:change="addDartClipFile"
                 class="form-control"
                 accept=".dartclip"/>
          <div v-if="!dartclip" class="form-control py-5">
            <label for="file"><strong>Drag & Drop</strong></label>
          </div>
          <ul class="list-group" v-if="dartclip">
            <li class="list-group-item">
              {{ dartclip.name }}
              <button @click="dartclip = null" class="btn skill-btn float-right ml-1">
                <i class="fas fa-trash-alt"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <button class="btn btn-primary add-skill-submit-btn" v-on:click="uploadScenes()" :disabled="isDisabled || !dartclip">
      Szenen importieren
    </button>
  </div>
</template>

<script>
export default {
  name: "TagUploadModal",
  props: ['videoId'],
  data() {
    return {
      isDisabled: false,
      dartclip: null
    }
  },
  methods: {
    uploadScenes() {
      this.isDisabled = true
      let formData = new FormData()
      formData.append('file', this.dartclip)

      this.$api.uploadScenes({
        segments: {
          vId: this.videoId,
        },
        data: formData,
      }).then((res) => {
        this.$emit('scenesImported', res.data)
        this.$api.uploadFile({
          data: formData
        }).then(response => {
          this.$api.storeFile({
            data: {
              fileable_id: this.videoId,
              fileable_type: "App\\Video",
              label: 'Dartfish',
              type: 'dartclip',
              path: response.data.replace("public", "storage")
            }
          }).then((res) => {
            this.$emit('updateParent', 1, res.data);
            this.hide()
          })
        })
      })
    },
    hide() {
      this.$modal.hide('uploadScenesModal');
    },
    addDroppedFile(e) {
      let droppedFiles = e.dataTransfer.files;
      ([...droppedFiles]).forEach(f => {
        if (f['name'].includes('.dartclip')) {
          this.dartclip = f
        }
      });
    },
    addDartClipFile(e) {
      let files = e.target.files;
      if (!files) return;
      this.dartclip = files[0]
    },
  }
}
</script>

<style scoped>

</style>