import FileAccessor from "./file-accessor";

export default class VideoDataAccessor {

    constructor(video_id) {
        this.dataAccessor = new FileAccessor(video_id, 'videoData');
        this.fileAccessor = new FileAccessor(video_id, 'videoFiles');
    }

    saveVideo(video) {
        let self = this;
        return this.dataAccessor.saveJSONData(video).then(function () {
            return self.fileAccessor.saveLargeFile(video.absolute_path)
        });
    }

    updateVideo(video){
        return this.dataAccessor.saveJSONData(video)
    }

    getVideo() {
        return this.dataAccessor.readJSONData();
    }

    getVideoFile() {
        return this.fileAccessor.getFileURL();
    }

    removeVideo() {
        let self = this;
        return this.fileAccessor.removeFile().then(function () {
            return self.dataAccessor.removeFile()
        })
    }

}