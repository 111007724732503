<template>
  <div id="skillEdit">
    <div class="container">
      <div class="row">
        <div class="close-btn">
          <button @click="close">
            ❌
          </button>
        </div>
        <label class="file-label">Bearbeiten</label>
        <textarea v-if="!skill.subskills" aria-label="annotation" class="form-control mb-2" :placeholder="skill.text"
                  type="text"
                  v-model="newSkillText" maxlength="500"></textarea>
        <textarea v-else v-for="(subSkill, index) in skill.subskills" v-bind:key="subSkill.id" aria-label="annotation"
                  class="form-control mb-2" :placeholder="subSkill" type="text"
                  v-model="newSubSkillText[index]" maxlength="500"></textarea>
        <button v-if="skill.subskills" type="button" class="btn btn-outline-danger w-100 mb-1"
                v-on:click="removeSubSkill">-
        </button>
        <button v-if="skill.subskills" type="button" class="btn btn-outline-primary w-100 mb-1"
                v-on:click="addSubSkill">+
        </button>
      </div>
      <div class="row float-right">
        <button class="mb-3 btn btn-primary" v-on:click="updateSkill">Ändern</button>
      </div>
    </div>
  </div>
</template>

<script>
import {cordovaUtils} from '../../utils/cordovaUtils.jsx';
import toast from '../../utils/toast';

export default {
  name: 'SkillEdit',
  props: ['skill', 'player'],
  data: function () {
    return {
      newSkillText: '',
      newSubSkillText: [],
    };
  },
  mounted() {
    if (this.skill.subskills) {
      this.newSubSkillText = this.skill.subskills;
    } else {
      this.newSkillText = this.skill.text;
    }
  },
  methods: {
    updateSkill: function () {
      if (cordovaUtils.isOfflineCancel()) {
        return 0;
      }
      let self = this;
      this.newSubSkillText = this.cleanSubskills(this.newSubSkillText);
      if (this.newSubSkillText.length === 1) {
        this.newSkillText = this.newSubSkillText[0];
      }
      this.$api.updatePlayerSkill({
        segments: {
          pid: this.player.id,
          sid: this.skill.id
        },
        data: {
          _method: 'patch',
          text: self.newSkillText ? self.newSkillText : JSON.stringify(this.newSubSkillText),
        }
      }).then(response => {
            if (response.status === 200) {
              self.$emit('updateParent', response.data);
              self.close();
            } else {
              toast.error("Requestfehler: " + response)
            }

          },
      );
    },
    close: function () {
      this.$modal.hide('edit-skill' + this.skill.id);
      this.$modal.hide('edit-skill');
    },
    cleanSubskills: function (subskill) {
      for (let i = 0; i < subskill.length; i++) {
        if (subskill[i] === '') {
          subskill.splice(i, 1);
          i--;
        }
      }
      return subskill;
    },
    addSubSkill: function () {
      if (this.skill.subskills) {
        // eslint-disable-next-line vue/no-mutating-props
        this.skill.subskills.push('');
      }
    },
    removeSubSkill: function () {
      if (this.skill.subskills) {
        // eslint-disable-next-line vue/no-mutating-props
        this.skill.subskills.pop();
      }
    }
  }
}
</script>
