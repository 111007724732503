import Vue from 'vue'
import App from './App.vue'
import router from './utils/router'
import store from './utils/store'
import api from './utils/api'

Vue.config.productionTip = false;

api.initialize(store);

import VTooltip from 'v-tooltip';
Vue.use(VTooltip, {
    defaultDelay: 500
});

import vmodal from 'vue-js-modal';
Vue.use(vmodal);


Vue.use(require('vue-cookies'))

Vue.prototype.$vueEventBus = new Vue();

new Vue({
    render: h => h(App),
    router: router,
    store: store
}).$mount('#app');

