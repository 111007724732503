<template>
  <div class="p-5">
    <div class="close-btn">
      <button @click="hide()">
        ❌
      </button>
    </div>
    <div class="form-group">
      <label>{{ this.scenes.length }} Szenen zu Wiedergabeliste hinzufügen</label>
    </div>
    <tag-filter-container
        :categories="categories"
        @selectedTagsUpdated="updateTags($event)"
        :hide-nav="true"
        :update-tags-in-store="false"
    ></tag-filter-container>
    <button class="btn btn-primary float-right mt-1" @click="addSceneToTags" :disabled="!selectedTags.length">
      Hinzufügen
    </button>
  </div>
</template>

<script>
import TagFilterContainer from "@/components/Scenes/TagFilterContainer";

export default {
  name: "AddSceneToTagModal",
  components: {TagFilterContainer},
  props: ['scenes', 'categories'],
  data() {
    return {
      selectedTags: [],
      scenesUpdated: 0,
      initialScenes: this.scenes.length
    }
  },
  computed: {
    isComplete() {
      return this.initialScenes === this.scenesUpdated
    }
  },
  methods: {
    addSceneToTags() {
      this.scenes.forEach((scene) => {
        const tags = this.addNewToExistingTags(scene)
        const formData = {
          tags: tags.map((tag) => {
            return {
              id: tag
            }
          })
        }
        this.$api.updateScene({
          segments: {id: scene.id},
          data: formData
        }).then(
            (res) => {
              this.scenesUpdated += 1
              this.$emit('sceneAddedToTags', {scene: res.data, complete: this.isComplete})
            }
        )
      })
    },
    addNewToExistingTags(scene) {
      const tags = scene.tags.map((tag) => {
        return tag.id
      })
      this.selectedTags.forEach((selectedTag) => {
        const index = tags.findIndex(tag => tag === selectedTag.id)
        if (index === -1) {
          tags.push(selectedTag.id)
        }
      })
      return tags
    },
    hide() {
      this.$modal.hide('addSceneToTag');
    },
    updateTags(tag) {
      let tagIndex = this.selectedTags.findIndex((item) => {
        return item.id === tag.id
      })
      if (tagIndex > -1) { // only splice array when item is found
        this.selectedTags.splice(tagIndex, 1)
      } else {
        this.selectedTags.push(tag)
      }
    }
  }
}
</script>

<style scoped>

</style>
