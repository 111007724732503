import FileAccessor from "./file-accessor";
import serverURL from "../utils/serverURL";

export default class GameDataAccessor {

    constructor(game_id) {
        this.id = game_id;
        this.dataAccessor = new FileAccessor(game_id, 'gameData');
    }

    saveGame(game) {
        let self = this;
        return this.dataAccessor.saveJSONData(game).then(function () {
            self.saveZipFile(game)
        });
    }

    updateGame(game){
        return this.dataAccessor.saveJSONData(game)
    }

    getGame() {
        return this.dataAccessor.readJSONData();
    }

    getStatisticsSrc() {
        return this.dataAccessor.getLocation() + "data/game/" + this.id + "/statistics.htm";
    }

    saveZipFile(game) {
        let uri = serverURL + "storage/game-imported/" + game.sport + "/" + this.id + ".zip";
        let folder = "game";
        let subFolder = this.id;
        this.dataAccessor.savaZipToDir(uri, folder, subFolder);

    }


    removeGame() {
        let dir = 'game';
        let subDir = this.id.toString();
        this.dataAccessor.removeSubDir(dir, subDir);
        return this.dataAccessor.removeFile();
    }

}