<template>
  <div>
    <div class="team-nav mx-auto mt-2 mb-4">
      <button class="btn btn-primary border nav-button" :class="showGames === 'all' ? 'active' : ''"
              @click="showGames = 'all'; $store.state.selectedTeamType = 'single'">Alle
      </button>
      <button class="btn btn-primary border nav-button" :class="showGames === 'single' ? 'active' : ''"
              @click="showGames = 'single'; $store.state.selectedTeamType = 'single'">Einzel
      </button>
      <button class="btn btn-primary border nav-button" :class="showGames === 'double' ? 'active' : ''"
              @click="showGames = 'double'; $store.state.selectedTeamType = 'double'">Doppel
      </button>
      <button class="btn btn-primary border nav-button" :class="showGames === 'mixed' ? 'active' : ''"
              @click="showGames = 'mixed'; $store.state.selectedTeamType = 'mixed'">Mixed
      </button>
    </div>
    <div class="card player-games-card">
      <div class="row card-body">
        <button v-if="($store.state.user.role_name === 'admin' || $store.state.user.role_name === 'trainer')"
                class="btn btn-sm btn-secondary mb-4 ml-3 float-left" v-on:click='showAddGame()'>
          <i class="fas fa-plus p-2"></i>
        </button>
        <input class="form-control btn-sm  ml-2 mb-2 col-lg-3" placeholder="Suche nach Gegnern..."
               v-model="filterGames">
        <div class="col-lg-12">
          <table class="table games-table" v-if="player.games" id="accordion">
            <tr>
              <th v-if="is_cordova"></th>
              <th></th>
              <th v-if="showGames !== 'single'">Partner</th>
              <th>Gegner</th>
              <th>Nation</th>
              <th>Turnier</th>
              <th>Jahr</th>
              <th v-if="$store.state.user.role_name === 'admin' || $store.state.user.role_name === 'trainer'">Aktionen
              </th>
              <th v-else></th>
            </tr>
            <tr :key="game.id" v-for="game in games" class="game-table-row">
              <!-- Favorit -->
              <td v-if="is_cordova">
                <button @click="saveGameOffline(game)"
                        class="btn btn-sm btn-secondary"
                        v-if="!isPersistent(game.id)">
                  <img src="../../assets/img/star-empty.svg" alt="Kiwi standing on oval" width="14.4"
                       height="12.8">
                </button>
                <button @click="deleteGameOffline(game)" v-if="isPersistent(game.id)"
                        class="btn btn-sm btn-secondary">
                  <i class="fas fa-star"></i>
                </button>
              </td>
              <!--Ergebnis-->
              <td v-if="belongsToTeamOne(game) && game.team1_id === game.winner" style="text-align: center">
                <div class="result-circle win mx-auto">W</div>
                <span
                    v-if="game.result">{{ game.result }}</span></td>
              <td v-else-if="belongsToTeamOne(game) && game.team2_id === game.winner" style="text-align: center">
                <div class="result-circle loss mx-auto">L</div>
                <span
                    v-if="game.result">{{ game.result }}</span></td>
              <td v-else-if="!belongsToTeamOne(game) && game.team2_id === game.winner" style="text-align: center">
                <div class="result-circle win mx-auto">W</div>
                <span
                    v-if="game.result">{{ game.result }}</span></td>
              <td v-else style="text-align: center">
                <div class="result-circle loss mx-auto">L</div>
                <span v-if="game.result">{{ game.result }}</span>
              </td>
              <!--Partner-->
              <td @click="showGameDetail(game)" v-if="showGames !== 'single'">
                <template v-if="belongsToTeamOne(game)">
                  <div :key="partner.id" v-for="partner in game.team1players">
                  <span
                      v-if="player.id !== partner.id">{{ partner.surname.toUpperCase() }}, {{ partner.forename }}</span>
                  </div>
                </template>
                <template v-else>
                  <div :key="partner.id" v-for="partner in game.team2players">
                  <span
                      v-if="player.id !== partner.id">{{ partner.surname.toUpperCase() }}, {{ partner.forename }}</span>
                  </div>
                </template>
              </td>
              <!--Gegner-->
              <td v-if="belongsToTeamOne(game)" @click="showGameDetail(game)">
                <div :key="player.id" v-for="player in game.team2players">
                  {{ player.surname.toUpperCase() }}, {{ player.forename }}
                </div>
              </td>
              <td v-else @click="showGameDetail(game)">
                <div :key="player.id" v-for="player in game.team1players">
                  {{ player.surname.toUpperCase() }}, {{ player.forename }}
                </div>
              </td>
              <!--Nation-->
              <td v-if="belongsToTeamOne(game)" @click="showGameDetail(game)">
                <div :key="player.id" v-for="player in game.team2players">
                  {{ player.nation }}
                </div>
              </td>
              <td v-else @click="showGameDetail(game)">
                <div :key="player.id" v-for="player in game.team1players">
                  {{ player.nation }}
                </div>
              </td>
              <!--Turnier-->
              <td @click="showGameDetail(game)">
                {{ game.tournament ? trimGameTournament(game.tournament) : '' }}
              </td>
              <!--Jahr-->
              <td @click="showGameDetail(game)">{{ game.year }}</td>
              <!--Aktionen-->
              <td>
                <button v-if="$store.state.user.role_name === 'admin' || $store.state.user.role_name === 'trainer'"
                        class="btn skill-btn ml-1" v-on:click='destroyGame(game)'><i
                    class="fas fa-trash-alt"></i>
                </button>
                <button v-if="$store.state.user.role_name === 'admin' || $store.state.user.role_name === 'trainer'"
                        class="btn skill-btn ml-1" v-on:click="showEditGame(game)">
                  <i class="fas fa-edit"></i>
                </button>
                <modal :name="'edit-game' + game.id" :width="600" height="auto" :clickToClose="false" :adaptive="true">
                  <game-edit
                      :game="editGame"
                      :players="players"
                      @updateGame="updateGame">
                  </game-edit>
                </modal>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <modal name="add-game" :width="600" height="auto" :clickToClose="false" :adaptive="true">
      <div style="padding: 1rem">
        <div class="form-group">
          <label>Gegner</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <div
                  :class="(filterGender === 'male') ? 'input-group-text search-filter active' : 'input-group-text search-filter'"
                  @click="filterGender = 'male'">
                <i class="fas fa-mars"></i>
              </div>
              <div
                  :class="(filterGender === 'female') ? 'input-group-text search-filter active' : 'input-group-text search-filter'"
                  @click="filterGender = 'female'">
                <i class="fas fa-venus"></i>
              </div>
            </div>
            <input class="form-control" placeholder="Suche nach Sportler..." v-model="filterName" autocomplete="off">
          </div>
          <div class="custom-checkbox custom-control opponents-box">
            <span v-for="player in players"
                  v-bind:key="player.id" class="ml-2">
                <input :id="player.id" type="checkbox" class="custom-control-input" :value="player"
                       v-model="opponents">
                <label :for="player.id"
                       class="custom-control-label">
                  {{player.surname }}, {{ player.forename }} - ({{ player.nation }})
                </label>
                <br>
            </span>
          </div>
        </div>
        <div class="form-group">
          <label>Partner</label>
          <select class="form-control" v-model="partnerTeamID">
            <option v-for="team in player.teams" :value="team.id"
                    v-bind:key="team.id">
              <span v-if="team.player1_id !== player.id">{{ team.player1.surname }}, {{ team.player1.forename }}</span>
              <span v-else-if="team.player2_id !== null">{{ team.player2.surname }}, {{ team.player2.forename }}</span>
              <span v-else>Kein Partner</span>
            </option>
          </select>
        </div>
        <div class="form-group">
          <label>Turnier</label>
          <input type="text" class="form-control" v-model="tournament" placeholder="Turnier eintragen">
        </div>
        <div class="form-group">
          <label>Jahr</label>
          <input type="text" class="form-control" v-model="year" placeholder="Jahr eintragen">
        </div>
        <div class="form-group">
          <label>Ergebnis</label>
          <select class="form-control" v-model="winner">
            <option value="1">Gewonnen</option>
            <option value="0">Verloren</option>
          </select>
        </div>
        <div v-if="$store.state.game === 'table tennis'" class="form-group">
          <select class="form-control" v-model="result">
            <option :value="'3:0'">3:0</option>
            <option :value="'3:1'">3:1</option>
            <option :value="'3:2'">3:2</option>
            <option :value="'4:0'">4:0</option>
            <option :value="'4:1'">4:1</option>
            <option :value="'4:2'">4:2</option>
            <option :value="'4:3'">4:3</option>
          </select>
        </div>
        <button v-on:click='hide("game")' class="btn btn-primary">Abbrechen</button>
        <button class="btn btn-primary add-skill-submit-btn" v-on:click="addGameManually()">Hinzufügen</button>
      </div>
    </modal>
    <modal id="game-detail-modal" :name="'game-detail'" height="auto" :clickToClose="false" :adaptive="true"
           :width="1000">
      <game-detail :game="gameToOpen"
                   @modalClosed="$store.state.selectedGame = null"
      ></game-detail>
    </modal>
  </div>
</template>

<script>
import 'bootstrap/js/dist/collapse';
import GameDataAccessor from "./../../storage/game-accessor.jsx";
import toast from '../../utils/toast';
import {cordovaUtils} from "../../utils/cordovaUtils";
import serverURL from '../../utils/serverURL';
import GameDetail from "@/components/Game/GameDetail";
import GameEdit from "@/components/Game/GameEdit";

// eslint-disable-next-line no-unused-vars
let $ = require('jquery');

export default {
  name: 'PlayerGames',
  components: {GameEdit, GameDetail},
  props: ['player'],
  data: function () {
    return {
      serverURL: serverURL,
      overlays: null,
      is_cordova: !!window.cordova,
      opponents: [],
      partnerTeamID: null,
      tournament: null,
      year: null,
      winner: 1,
      result: null,
      privat: false,
      filterName: '',
      filterGender: 'male',
      filterGames: '',
      showGames: 'all',
      gameToOpen: null,
      editGame: null,
    }
  },
  mounted() {
    if (this.$store.state.selectedGame) {
      this.showGameDetail(this.$store.state.selectedGame);
    }
  },
  methods: {
    showAddGame() {
      if (cordovaUtils.isOfflineCancel()) {
        return 0;
      }
      this.$modal.show('add-game', {});
    },
    showEditGame(game) {
      if (cordovaUtils.isOfflineCancel()) {
        return 0;
      }
      this.editGame = game;
      this.$modal.show('edit-game' + game.id, {});
    },
    saveGameOffline: function (game) {
      if (cordovaUtils.isOfflineCancel()) {
        return 0;
      }
      toast.success('Spiel wird geladen: ' + game.tournament)
      this.$api.getGameDetail({
        segments: {id: parseInt(game.id)}
      }).then(gameResponse => {
        this.$store.dispatch('saveOfflineGameDate', gameResponse.data);

      });

    },
    deleteGameOffline: function (game) {
      let self = this;
      let gfa = new GameDataAccessor(game.id);
      gfa.getGame().then(function (data) {
        self.$store.dispatch('deleteOfflineGameDate', data);
      });

    },
    isPersistent(game_id) {
      return this.$store.state.downloaded_games.indexOf(parseInt(game_id)) >= 0;
    },
    belongsToTeamOne(game) {
      return this.player.id === game.team1.player1_id || this.player.id === game.team1.player2_id;
    },
    addGameManually() {
      let opponentTeam = null;
      let isDouble = 0;
      let self = this;

      if (this.opponents.length === 2) {
        opponentTeam = this.opponents[0].teams.find(function (team) {
          return self.opponents[1].id === team.player1_id || self.opponents[1].id === team.player2_id
        });
        isDouble = 1;
      } else if (this.opponents.length === 1) {
        opponentTeam = this.opponents[0].teams.find(function (team) {
          return team.player2_id === null;
        });
      }

      if (!this.partnerTeamID) {
        try {
          this.partnerTeamID = this.player.teams.find(function (team) {
            return team.player2_id === null;
          }).id;
        } catch (e) {
          toast.error(this.playerName(this.player) + ' ist kein Einzelspieler');
        }
      }
      if (!opponentTeam) {
        toast.error('Fehler! Überprüfen Sie die ausgewählten Gegner');
      } else if (this.partnerTeamID) {
        this.$api.createNewGame({
          data: {
            sport: this.$store.state.user.sport,
            is_double: isDouble,
            team1_id: this.partnerTeamID,
            team2_id: opponentTeam.id,
            tournament: this.tournament,
            year: this.year,
            winner: this.winner === 1 ? this.partnerTeamID : opponentTeam.id,
            result: this.result,
            private: this.privat
          }
        }).then(response => {
              toast.success('Spiel hinzugefügt');
              // eslint-disable-next-line vue/no-mutating-props
              this.player.games.push(response.data);
              this.year = null;
              this.tournament = null;
              this.partnerTeamID = null;
              this.opponents = [];
              this.result = null;
              this.hide('game')
            }
        );
      }
    },
    hide(modal) {
      this.$modal.hide('add-' + modal);
    },
    showGameDetail(game) {
      this.gameToOpen = game;
      this.$store.state.selectedGame = game;
      this.$modal.show('game-detail');
    },
    playerName(player) {
      return player.surname + " " + player.forename;
    },
    destroyGame(game) {
      if (cordovaUtils.isOfflineCancel()) {
        return 0;
      }
      const result = confirm("Möchten sie das Spiel wirklich aus der Datenbank löschen?");
      if (result) {
        this.$api.deleteGame({
          segments: {
            id: game.id,
          }
        }).then(function () {
          let index = -1;
          for (let i = 0; i < this.player.games.length; i++) {
            if (this.player.games[i].id === game.id) {
              index = i;
              break;
            }
          }
          toast.success('Spiel entfernt!');
          // eslint-disable-next-line vue/no-mutating-props
          this.player.games.splice(index, 1);
        }.bind(this, game));
      }
    },
    updateGame(updatedGame) {
      let updatedData = updatedGame[0];
      let authenticatedUsers = updatedGame[1]
      let game = this.player.games.find(game => game.id === updatedData.id);
      game.tournament = updatedData.tournament;
      game.year = updatedData.year;
      game.result = updatedData.result;
      game.private = updatedData.private;
      game.users = authenticatedUsers;
    },
    trimGameTournament(tournament) {
      let regex = /([^_]*)$/;
      return tournament.match(regex)[0] ? tournament.match(regex)[0].replace(/\d{4}/g, '') : tournament;
    },
    forenameSurname(player) {
      return player.forename + " " + player.surname;
    },
  },
  computed: {
    players() {
      let players = this.$store.state.players;
      players = players.filter(player =>
          player.gender === this.filterGender
      );
      if (this.filterName) {
        players = players.filter(player => {
              let nameWithoutComma = this.playerName(player).replace(',', '').toLowerCase().trim();
              return nameWithoutComma.toLowerCase().indexOf(this.filterName.toLowerCase().trim()) > -1 ||
                  this.forenameSurname(player).toLowerCase().indexOf(this.filterName.toLowerCase().trim()) > -1
            }
        );
      }
      return players;
    },
    games() {
      // eslint-disable-next-line vue/no-mutating-props,vue/no-side-effects-in-computed-properties
      let games = this.player.games.sort((a, b) => {
        // First, compare by year in descending order
        if (a.year !== b.year) {
          return b.year - a.year;
        }
        // If both year and id are equal, compare by name in ascending order
        return a.tournament.localeCompare(b.tournament);
      });

      let self = this;
      // Show only games that user has authentication for
      games = games.filter(function (game) {
        if (game.private && game.users.length && self.$store.state.user.role_name !== 'admin') {
          return game.users.includes(self.$store.state.user.user_id);
        }
        return true;
      })

      // Sort teams by gender (female, male)
      games.forEach(function (game) {
        game.team1players = game.team1players.sort((a, b) => a.gender < b.gender);
        game.team2players = game.team2players.sort((a, b) => a.gender < b.gender);
      })

      if (this.showGames === 'single') {
        games = games.filter(function (game) {
          return game.is_double !== 1;
        })
      } else if (this.showGames === 'double') {
        games = games.filter(function (game) {
          return game.is_double === 1 && game.is_mixed === false;
        })
      } else if (this.showGames === 'mixed') {
        games = games.filter(function (game) {
          return game.is_double === 1 && game.is_mixed === true;
        })
      }

      if (this.filterGames) {
        games = games.filter(function (game) {
              if (self.belongsToTeamOne(game)) {
                if (game.team2players.length > 1) {
                  return game.team2players[0].surname.toLowerCase().indexOf(self.filterGames.toLowerCase().trim()) > -1 ||
                      game.team2players[1].surname.toLowerCase().indexOf(self.filterGames.toLowerCase().trim()) > -1
                } else {
                  return game.team2players[0].surname.toLowerCase().indexOf(self.filterGames.toLowerCase().trim()) > -1
                }
              } else {
                if (game.team1players.length > 1) {
                  return game.team1players[0].surname.toLowerCase().indexOf(self.filterGames.toLowerCase().trim()) > -1 ||
                      game.team1players[1].surname.toLowerCase().indexOf(self.filterGames.toLowerCase().trim()) > -1
                } else {
                  return game.team1players[0].surname.toLowerCase().indexOf(self.filterGames.toLowerCase().trim()) > -1
                }
              }
            }
        );
      }
      return games;
    }
  },
}
</script>
