const customSortCategories = (categories) => {
    let sorted = categories
    let ordering = {}, // map for efficient lookup of sortIndex
        sortOrder = [
            "Aufschlag",
            "Punkt",
            "Qualität",
            "Rally",
            "Game",
            "Rally Timeline",
            "Indo",
            "Point",
            "Win with",
            "Rally Description",
            "Rally Finish",
            "First Initiative Player",
            "First Initiative Team",
            "Point Shots",
            "Server_Position",
            "Field Position",
            "#3",
            "Team 1 Server",
            "Team 1 Return",
            "Team 2 Server",
            "Team 2 Return",
            "Return_Placement",
            "NOTE!",
            "Practise",
            "XD_Player",
            "XD_Position",
            "XD_Racquet_Frontcourt",
            "XD_Situation",
            "XD_Shot",
            "Koordinaten",
            "Backcourt_Player",
            "Backcourt_Position",
            "Backcourt_Placement",
            "Backcourt_Shot",
            "TEAM 1 Players",
            "TEAM 2 Players",
            "DATE",
            "Location",
            "Analyst",
            "Custom"
        ]
    for (let i = 0; i < sortOrder.length; i++)
        ordering[sortOrder[i]] = i;

    sorted.sort(function (a, b) {
        return ordering[a.title] - ordering[b.title];
    });

    return sorted
}

export default customSortCategories;