import Vue from 'vue'
import Vuex from 'vuex'
import {stateRepository} from "./../storage/repository.jsx";
import PlayerDataAccessor from "./../storage/player-accessor.jsx";
import VideoDataAccessor from "./../storage/video-accessor.jsx";
import GameDataAccessor from "./../storage/game-accessor.jsx";
import AnnotationDataAccessor from "./../storage/annotation-accessor.jsx";
//import PlayerList from './../components/Player/PlayerList'
import serverURL from './../utils/serverURL';

import toast from './toast'
import {cordovaUtils} from "./cordovaUtils";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        token: false,
        game: (window.location.hostname.indexOf('badminton') !== -1) ? 'badminton' : 'table tennis',
        // game: 'badminton',
        sidebarVisible: window.innerWidth >= 1000,
        players: [],
        downloaded_games: [],
        downloaded_players: [],
        downloaded_videos: [],
        downloaded_annotations: [],
        networkActiveTasks: 0,
        uploadPercentage: 100,
        lastRoute: '#/',
        user: null,
        selectedTeamType: 'single',
        selectedGame: null,
        selectedTags: [],
        isFullscreen: false,
        dashboardStyle: 'basic'
    },
    getters: {
        getCurrentGame(state) {
            return state.game;
        },
        getSelectedTags(state) {
            return state.selectedTags
        }
    },
    mutations: {
        toggleSidebar(state, toValue) {
            state.sidebarVisible = typeof toValue !== 'undefined' ? toValue : !state.sidebarVisible;
        },
        addActiveNetworkTask(state, value) {
            state.networkActiveTasks += value;
        },
        setUploadPercentage(state, value) {
            state.uploadPercentage = value;
        },
        setPlayers(state, players) {
            state.players = players;
        },
        setState(state, stateData) {
            state.players = stateData.players;
            state.downloaded_players = stateData.downloaded_players;
            state.downloaded_videos = stateData.downloaded_videos;
            state.downloaded_games = stateData.downloaded_games;
            state.downloaded_annotations = stateData.downloaded_annotations;
            state.token = stateData.token;
            state.user = stateData.user;
            state.lastRoute = stateData.lastRoute;
        },
        addDownloadedGame(state, game) {
            state.downloaded_games.push(game.id);
            saveStateToFS(state)
        },
        rmDownloadedGame(state, game) {
            while (state.downloaded_games.indexOf(game.id) > -1) {
                state.downloaded_games.splice(state.downloaded_games.indexOf(game.id), 1);
            }
        },
        addDownloadedPlayer(state, player) {
            state.downloaded_players.push(player.id);
        },
        rmDownloadedPlayer(state, player) {
            while (state.downloaded_players.indexOf(player.id) > -1) {
                state.downloaded_players.splice(state.downloaded_players.indexOf(player.id), 1);
            }
        },
        addDownloadedVideo(state, video) {
            state.downloaded_videos.push(video.id);
        },
        rmDownloadedVideo(state, video) {
            while (state.downloaded_videos.indexOf(video.id) > -1) {
                state.downloaded_videos.splice(state.downloaded_videos.indexOf(video.id), 1);
            }
        },
        addDownloadedAnnotation(state, annotation) {
            state.downloaded_annotations.push(annotation.id);
        },
        rmDownloadedAnnotation(state, annotation) {
            while (state.downloaded_annotations.indexOf(annotation.id) > -1) {
                state.downloaded_annotations.splice(state.downloaded_annotations.indexOf(annotation.id), 1);
            }
        },
        addPlayer(state, player) {
            state.players.push(player);
        },
        addTag(state, tag) {
            state.selectedTags.push(tag)
        },
        removeTag(state, tag) {
            while (state.selectedTags.indexOf(tag) > -1) {
                state.selectedTags.splice(state.selectedTags.indexOf(tag), 1);
            }
        },
        resetTags(state) {
            while(state.selectedTags.length > 0) {
                state.selectedTags.pop();
            }
        },
        destroyPlayer(state, player) {
            let index = -1;
            for (let i = 0; i < state.players.length; i++) {
                if (state.players[i].id === player.id) {
                    index = i;
                    break;
                }
            }
            if (index > -1) {
                state.players.splice(index, 1);
            }
        },
        updatePlayer(state, player) {
            for (let i = 0; i < state.players.length; i++) {
                if (state.players[i].id === player.id) {
                    state.players[i] = player;
                    break;
                }
            }
        },
        updatePlayerTeam(state, [player, team]) {
            for (let i = 0; i < state.players.length; i++) {
                if (state.players[i].id === player.id) {
                    state.players[i].teams.push(team);
                    break;
                }
            }
        },
        setLastRoute(state, route) {
            state.lastRoute = route;
        },
        setFullscreenValue(state, value) {
            state.isFullscreen = value
        }
    },
    actions: {
        loadStateFromFS({commit}) {
            return stateRepository.getState().then(function (data) {
                commit('setState', data)
            })
        },
        saveState({state}) {
            return saveStateToFS(state)
        },
        saveOfflinePlayerData({commit, state}, player) {
            let playerAnnotation = extractPlayerFileAnnotation(player);
            commit('addActiveNetworkTask', 1);
            let downloadedAnnotation = 0;
            if (playerAnnotation.length) {
                playerAnnotation.forEach(function (file_annotation) {
                    let ada = new AnnotationDataAccessor(file_annotation.id);
                    ada.saveAnnotation(file_annotation).then(function () {
                        downloadedAnnotation++;
                        //commit('addDownloadedAnnotation', file_annotation);
                        if (downloadedAnnotation === playerAnnotation.length) {
                            commit('addActiveNetworkTask', -1);
                        }
                    });
                });
            } else {
                commit('addActiveNetworkTask', -1);
            }
            let playerFiles = extractPlayerFiles(player);
            commit('addActiveNetworkTask', 1);
            let downloadedFiles = 0;
            if (playerFiles.length) {
                playerFiles.forEach(function (file) {
                    let fileURL = serverURL + file.path;
                    cordovaUtils.downloadFile(fileURL, file.label + '.' + file.path.split('.').pop()).then(function () {
                        downloadedFiles++;
                        //commit('addDownloadedFile', file);
                        if (downloadedFiles === playerFiles.length) {
                            commit('addActiveNetworkTask', -1);
                        }
                    })
                })
            } else {
                commit('addActiveNetworkTask', -1);
            }

            commit('addActiveNetworkTask', 1);
            let pfa = new PlayerDataAccessor(player.id);
            pfa.savePlayer(player).then(function () {
                let playerVideos = getPlayerVideos(player);
                let downloadedVideos = 0;
                if (playerVideos.length) {
                    playerVideos.forEach(function (video) {
                        let vda = new VideoDataAccessor(video.id);
                        vda.saveVideo(video).then(function () {
                            downloadedVideos++;
                            commit('addDownloadedVideo', video);
                            if (downloadedVideos === playerVideos.length) {
                                commit('addDownloadedPlayer', player);
                                saveStateToFS(state).then(function () {
                                    commit('addActiveNetworkTask', -1);
                                    toast.success("Saved player (" + player.surname + ") and videos (" + playerVideos.length + ")");
                                    //PlayerList.methods.hide();
                                })
                            }
                        });
                    })
                } else {
                    commit('addDownloadedPlayer', player);
                    saveStateToFS(state).then(function () {
                        commit('addActiveNetworkTask', -1);
                        toast.success("Saved player (" + player.surname + ") and videos (none)");
                        // PlayerList.methods.hide();
                    })
                }
            });
        },
        updateOfflinePlayerData({commit, state}, player) {
            let playerAnnotation = extractPlayerFileAnnotation(player);
            commit('addActiveNetworkTask', 1);
            let downloadedAnnotation = 0;
            if (playerAnnotation.length) {
                playerAnnotation.forEach(function (file_annotation) {
                    if (state.downloaded_annotations.includes(file_annotation.id)) {
                        downloadedAnnotation++;
                    } else {
                        let ada = new AnnotationDataAccessor(file_annotation.id);
                        ada.saveAnnotation(file_annotation).then(function () {
                            downloadedAnnotation++;
                            //commit('addDownloadedAnnotation', file_annotation);
                        });
                    }
                    if (downloadedAnnotation === playerAnnotation.length) {
                        commit('addActiveNetworkTask', -1);
                    }
                });
            } else {
                commit('addActiveNetworkTask', -1);
            }

            let playerFiles = extractPlayerFiles(player);
            commit('addActiveNetworkTask', 1);
            let downloadedFiles = 0;
            if (playerFiles.length) {
                playerFiles.forEach(function (file) {
                    let fileURL = serverURL + file.path;
                    cordovaUtils.downloadFile(fileURL, file.label + '.' + file.path.split('.').pop()).then(function () {
                        downloadedFiles++;
                        //commit('addDownloadedFile', file);
                        if (downloadedFiles === playerFiles.length) {
                            commit('addActiveNetworkTask', -1);
                        }
                    })
                })
            } else {
                commit('addActiveNetworkTask', -1);
            }

            commit('addActiveNetworkTask', 1);
            let pfa = new PlayerDataAccessor(player.id);
            pfa.savePlayer(player).then(function () {
                let playerVideos = getPlayerVideos(player);
                let downloadedVideos = 0;
                if (playerVideos.length) {
                    playerVideos.forEach(function (video) {
                        if (state.downloaded_videos.includes(video.id)) {
                            downloadedVideos++;
                        } else {
                            let vda = new VideoDataAccessor(video.id);
                            vda.saveVideo(video).then(function () {
                                downloadedVideos++;
                                commit('addDownloadedVideo', video);
                            });
                        }
                        if (downloadedVideos === playerVideos.length) {
                            saveStateToFS(state).then(function () {
                                commit('addActiveNetworkTask', -1);
                                toast.success("Updated player (" + player.surname + ") and videos (" + playerVideos.length + ")");
                            })
                        }
                    })
                } else {
                    saveStateToFS(state).then(function () {
                        commit('addActiveNetworkTask', -1);
                        toast.success("Updated player (" + player.surname + ") and videos (none)");
                    })
                }
            });
        },
        saveOfflineGameDate({commit, state}, game) {
            commit('addActiveNetworkTask', 1);
            let gfa = new GameDataAccessor(game.id);
            gfa.saveGame(game).then(function () {
                let video = game.videos[0];
                let vda = new VideoDataAccessor(video.id);
                vda.saveVideo(video).then(function () {
                    commit('addDownloadedVideo', video);
                    commit('addDownloadedGame', game);
                    saveStateToFS(state).then(function () {
                        commit('addActiveNetworkTask', -1);
                        toast.success("Saved game ()");
                    })
                });
            });
        },
        deleteOfflineGameDate({commit, state}, game) {
            commit('addActiveNetworkTask', 1);
            let gfa = new GameDataAccessor(game.id);
            gfa.removeGame(game).then(function () {
                let vda = new VideoDataAccessor(game.videos[0].id);
                vda.removeVideo(game.videos[0]).then(function () {
                    commit('rmDownloadedVideo', game.videos[0]);
                    commit('rmDownloadedGame', game);
                    saveStateToFS(state).then(function () {
                        commit('addActiveNetworkTask', -1);
                        toast.success("Removed game");
                    })

                });
            });
        },
        deleteOfflinePlayerData({commit, state}, player) {
            commit('addActiveNetworkTask', 1);

            let playerAnnotation = extractPlayerFileAnnotation(player);
            if (playerAnnotation.length) {
                playerAnnotation.forEach(function (file_annotation) {
                    let ada = new AnnotationDataAccessor(file_annotation.id);
                    ada.removeAnnotation(file_annotation).then(function () {
                        commit('rmDownloadedAnnotation', file_annotation);
                    });
                });
            }

            let pfa = new PlayerDataAccessor(player.id);
            pfa.removePlayer().then(function () {
                let playerVideos = getPlayerVideos(player);
                let removedVideos = 0;
                if (playerVideos.length) {
                    playerVideos.forEach(function (video) {
                        let vda = new VideoDataAccessor(video.id);
                        vda.removeVideo().then(function () {
                            removedVideos++;
                            commit('rmDownloadedVideo', video);
                            if (removedVideos === playerVideos.length) {
                                commit('rmDownloadedPlayer', player);
                                saveStateToFS(state).then(function () {
                                    commit('addActiveNetworkTask', -1);
                                })
                            }
                        });
                    });
                } else {
                    commit('rmDownloadedPlayer', player);
                    saveStateToFS(state).then(function () {
                        commit('addActiveNetworkTask', -1);
                    })
                }
            });
        },
        updateFullscreenValue({commit}, newValue) {
            commit('setFullscreenValue', newValue);
        },
    }
});

function saveStateToFS(state) {
    return stateRepository.saveState(state)
}

function getPlayerVideos(player) {
    let v = [];
    player.videos.forEach(function (video) {
        v.push(video)
    });
    player.skills.forEach(function (skill) {
        skill.videos.forEach(function (video) {
            v.push(video)
        })
    });
    return v;
}

//get File Annotation
function extractPlayerFileAnnotation(player) {
    let v = [];

    player.annotations.forEach(function (annotation) {
        if (annotation.type === "file") {
            v.push(annotation);
        }
        annotation.annotations.forEach(function (subannotation) {
            if (subannotation.type === "file") {
                v.push(subannotation);
            }
        })
    });

    player.skills.forEach(function (skill) {
        skill.videos.forEach(function (video) {
            video.annotations.forEach(function (annotation) {
                if (annotation.type === "file") {
                    v.push(annotation);
                }
                annotation.annotations.forEach(function (subannotation) {
                    if (subannotation.type === "file") {
                        v.push(subannotation);
                    }
                })
            })
        })
    });

    // player.videos.forEach(function (video) {
    //
    // })

    return v;

}

// get skill and game files
function extractPlayerFiles(player) {
    let v = [];

    player.skills.forEach(function (skill) {
        if (skill.files.length) {
            v.push(skill.files[0]);
        }
    });

    player.games.forEach(function (game) {
        if (game.files.length) {
            v.push(game.files[0]);
        }
    });

    return v;
}


export default store;
