const sceneEndpoints = {
    storeScene: {
        method: 'POST',
        url: '/api/scene',
    },
    updateScene: {
        method: 'PATCH',
        url: '/api/scene/:id'
    },
    deleteScenes: {
        method: 'POST',
        url: '/api/scene/delete'
    },
    fetchVideoTags: {
        method: 'GET',
        url: '/api/tag-category?videoId=:videoId',
    },
    addTag: {
        method: 'POST',
        url: '/api/tag'
    },
    updateTag: {
      method: 'PATCH',
      url:   '/api/tag/:id'
    },
    deleteTag: {
        method: 'DELETE',
        url: '/api/tag/:id'
    },
    deleteTagFromScene: {
        method: 'POST',
        url: '/api/scene/:sceneId/detach-tag'
    },
    uploadScenes: {
        method: 'POST',
        url: '/api/video/:vId/import-scenes'
    }
}

export default sceneEndpoints