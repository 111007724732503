import FileAccessor from "./../storage/file-accessor";
import toast from './../utils/toast';
import store from './../utils/store';

class CordovaUtils {

    /*global device*/
    /*global cordova*/
    /*global Connection*/

    /*eslint no-undef: "error"*/

    isCordova() {
        return !!window.cordova;
    }

    isOfflineCancel() {
        if (this.isOffline()) {
            toast.error("Bitte gehe erst online");
            return true;
        } else {
            return false;
        }
    }

    isOnline() {
        if (this.isCordova()) {
            return navigator.connection.type !== Connection.NONE;
        } else {
            return window.navigator.onLine;
        }
    }

    isOffline() {
        return !this.isOnline();
    }

    getDevicePlatform() {
        return device.platform;
    }

    downloadFile(file_url, file_name, file_annotation) {

        var dlLocation = "";
        switch (this.getDevicePlatform()) {
            case "Android":
                dlLocation = cordova.file.externalRootDirectory;
                break;
            case "iOS":
                dlLocation = cordova.file.documentsDirectory;
                break;

        }

        return new Promise(function (resolve) {
            let fileAccessor = new FileAccessor(Date.now(), 'temp');
            fileAccessor.saveLargeFile(file_url).then(function () {
                fileAccessor._accessFile().then(function (file) {

                    window.resolveLocalFileSystemURL(dlLocation,
                        function (dirEntry) {
                            dirEntry.getDirectory('/Download', {create: true}, function (dirEntry) {
                                file.moveTo(
                                    dirEntry,
                                    file_name,
                                    function () {
                                        //success
                                        if(file_annotation) {
                                            store.commit('addDownloadedAnnotation', file_annotation);
                                        }
                                        fileAccessor.removeFile();
                                        resolve();
                                    },
                                    function () {
                                        //fail
                                        toast.error('Fehler');
                                        fileAccessor.removeFile();
                                        resolve();
                                    }
                                )

                            })

                        });

                })
            })
        });

    }

    rmDownloadedFile(file_url, file_name) {

        var dlLocation = "";
        switch (this.getDevicePlatform()) {
            case "Android":
                dlLocation = cordova.file.externalRootDirectory;
                break;
            case "iOS":
                dlLocation = cordova.file.documentsDirectory;
                break;

        }

        return new Promise(function (resolve) {
            let fileAccessor = new FileAccessor(Date.now(), 'temp');
            fileAccessor.saveLargeFile(file_url).then(function () {
                fileAccessor._accessFile().then(function () {

                    window.resolveLocalFileSystemURL(dlLocation,
                        function (dirEntry) {
                            dirEntry.getDirectory('/Download', {create: true}, function (dirEntry) {
                                dirEntry.getFile(file_name, {create: false}, function (fileEntry) {
                                    fileEntry.remove(function () {
                                            //success
                                            fileAccessor.removeFile();
                                            resolve();
                                        },
                                        function () {
                                            //fail
                                            toast.error('Fehler');
                                            fileAccessor.removeFile();
                                            resolve();
                                        });
                                })
                            })

                        });

                })
            })
        });

    }

    getCurrentDateTime() {
        let today = new Date();
        let date = today.getFullYear()
            + '-'
            + ((today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1))
            + '-'
            + (today.getDate() < 10 ? '0' + today.getDate() : today.getDate());

        let time = (today.getHours() < 10 ? '0' + today.getHours() : today.getHours())
            + ":"
            + (today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes())
            + ":"
            + (today.getSeconds() < 10 ? '0' + today.getSeconds() : today.getSeconds());
        return date + ' ' + time;
    }

    viewDocument(file_url, mimeType, options, onShow, onClose, onMissingApp, onError) {
        var dlLocation = "";
        switch (this.getDevicePlatform()) {
            case "Android":
                dlLocation = cordova.file.externalRootDirectory;
                break;
            case "iOS":
                dlLocation = cordova.file.documentsDirectory;
                break;

        }

        window.resolveLocalFileSystemURL(dlLocation,
            function (dirEntry) {
                dirEntry.getDirectory('/Download', {create: true}, function (dirEntry) {
                    cordova.plugins.SitewaertsDocumentViewer.viewDocument(
                        dirEntry.nativeURL + file_url, mimeType, options, onShow, onClose, onMissingApp, onError);
                })
            });
    }
}


var cordovaUtils = new CordovaUtils();

export {
    cordovaUtils,
}

